import "../../assets/css/navbar/navbar.css";
import logo from "../../assets/images/logo.svg";
import profile from "../../assets/images/profileExample.jpg";
import NavbarSearch from "./navbarSearch";
import { customAlert, loadingAlert } from "../../utils/customAlert";
import { PasswordInput } from "../navigation/navigationMenu.jsx";

import Axios from "axios";
import { Modal } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/auth-context";
import { useCookies } from "react-cookie";

function NavBar(props) {
    const [_cookies, _setCookies, removeCookie] = useCookies(['token'])
    // const [activeUser, setActiveUser] = useState(null)
    const [showUserOption, setShowUserOptions] = useState(false);
    const [showSearchNav, setShowSearchNav] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [passwords, setPasswords] = useState({ 
		current: "", 
		new: "", 
		confirm: "" 
	});
	const [showPasswords, setShowPasswords] = useState([]);

	const passwordInputs = {
		current: "current",
		new: "new",
		confirm: "confirm"
	}

    const { user, updateUser } = useAuth()

    const profileRef = useRef(null)
    const fileInputRef = useRef(null)

    useEffect(() => {
        const closeUserOptions = (e) => {
            if (profileRef.current && !profileRef.current.contains(e.target) 
                && e.target.id !== "changePictureFile" && e.target.id !== "changePicture") {
                setShowUserOptions(false)
            }
        }

        document.body.addEventListener('click', closeUserOptions);

        return function cleanup() {
            document.body.removeEventListener('click', closeUserOptions);
        }
    }, []);

    const showUserOptions = () => {
        if (user) {
            setShowUserOptions(!showUserOption)
        }
    }

    const handleShowSearchNav = () => {
        setShowSearchNav(true)
        document.body.style.overflow = "hidden";
    }

    const handleChangePassword = () => {
		setChangePasswordModal(true)
	}

	const handleOnHideModal = () => {
		setChangePasswordModal(false)
		setPasswords({
			current: "",
			new: "",
			confirm: ""
		})
		setShowPasswords([])
	}

	const handleInputPassword = (e) => {
		const { id, value } = e.target
		setPasswords(prevState => {
			return {
				...prevState,
				[id]: value
			}
		})
	
	}

	const handleShowPassword = (input) => {
		setShowPasswords(prevState => {
			if (prevState.includes(input)) {
				return prevState.filter(prevInput => prevInput !== input)
			} else {
				return [...prevState, input]
			}
		})
	}

	const handleConfirmPassword = () => {
		loadingAlert()

		Axios.patch(process.env.REACT_APP_UPDATE_LOGIN_USER + user._id, passwords).then(res => {
			if (res.status >= 200 && res.status < 300) {
				customAlert(res.data.message, "success")
				setChangePasswordModal(false)
				setPasswords({
					current: "",
					new: "",
					confirm: ""
				})
				setShowPasswords([])
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

    const handleProfilePicture = async(e) => {
		const allowedTypes = ["image/jpeg", "image/png"];
		const selectedFile = e.target.files[0];

		if (selectedFile && !allowedTypes.includes(selectedFile?.type)) {
			await Swal.fire({
					icon: "error",
					title: "File not supported",
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
			});
			return;
		}

		const data = new FormData();
		data.append("photo", selectedFile);
		data.append("profileUser", user)

		Axios.patch(process.env.REACT_APP_UPDATE_PROFILE_USER + user._id, data).then(res => {
			if (!res.data.data) {
				customAlert(res.data.message)
				return;
			}
			
            updateUser(res.data.data)
            setShowUserOptions(false)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

    const capitalizedName = (name) => {
		return name.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
	}

    const handleLogout = () => {
        removeCookie('token')
        // window.location.reload()
    }

    const changePasswordConfirmButton = passwords.current !== "" && passwords.new !== "" && passwords.confirm !== "" && passwords.new === passwords.confirm;
	const samePassword = passwords.new !== passwords.confirm && passwords.confirm !== ""
    
    return (
        <nav className="homeNavbar d-lg-none">
            <ul className="navbar-list">
                <li>
                    <img className="navbar-logo" src={logo} alt="ProdensaAutomation" />
                </li>
                <li className="profile-search-container">
                    <button className={`search-navbar ${showSearchNav ? "hide" : ""}`} onClick={() => handleShowSearchNav()}/>
                    <img className="profile-navbar" ref={profileRef} onClick={showUserOptions}
                        src={user && user.hasOwnProperty("photo") ? 
                        process.env.REACT_APP_BACKEND_URL + user.photo.path : profile} 
                        alt="ProdensaAutomation" />
                </li>
            </ul>
            <NavbarSearch 
                showSearchNav={showSearchNav} 
                setShowSearchNav={setShowSearchNav} 
                allClients={props.allClients} 
                openReportLog={props.openReportLog}
                setshowClientId={props.setshowClientId}
            />

            {/* modal options for user */}
            <Modal className="profileOptions-navbar" centered show={showUserOption} onHide={() => setShowUserOptions(false)}>
                <Modal.Body>
                    <img className="img-profile-navbar"
                        src={user && user.hasOwnProperty("photo") ? 
                        process.env.REACT_APP_BACKEND_URL + user.photo.path : profile} 
                        alt="ProdensaAutomation" />
                    <h2>{user && capitalizedName(user.fullName)}</h2>
                    <div className="profile-actions-navbar">
                        <input type="file" id="changePictureFile" ref={fileInputRef} style={{display: "none"}} onChange={handleProfilePicture}/>
                        <h5 id="changePicture" onClick={(e) => {fileInputRef.current && fileInputRef.current.click(), console.log(e.target)}}>Change profile picture</h5>
                        <h5 onClick={handleChangePassword}>Change Password</h5>
                        <h5 onClick={handleLogout}>Logout</h5>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="mail-form-modal change-password" centered show={changePasswordModal} onHide={handleOnHideModal}>  {/*Modal for adding custom mail */}
				<Modal.Header closeButton>
					<Modal.Title>Change password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PasswordInput 
						id={passwordInputs.current}
						value={passwords.current}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.current)}
						handleShowPassword={handleShowPassword}
					/>

					<PasswordInput
						id={passwordInputs.new}
						value={passwords.new}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.new)}
						handleShowPassword={handleShowPassword}
					/>

					<PasswordInput
						id={passwordInputs.confirm}
						value={passwords.confirm}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.confirm)}
						handleShowPassword={handleShowPassword}
					/>

					<span className={`password-error ${samePassword ? "show" : ""}`}>Password do not match</span>
				</Modal.Body>
				<Modal.Footer>
					<button className="send-button password-button" onClick={handleConfirmPassword} disabled={!changePasswordConfirmButton}>
						Change password
					</button>
				</Modal.Footer>
			</Modal>
        </nav>
    )
}

export default NavBar;