import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const ManufacturingGrowth = ({ details, city_names, filtered, reportID, variableCosts }) => {
  const [years, setYears] = useState(null);

  
  useEffect(() => {
    if (variableCosts) {
      let yearsRef = []
      variableCosts.forEach(city => {
        const years = Object.keys(city?.dataVariables?.manufacturingGrowth ?? [])
        if (years.length > yearsRef.length) {
          yearsRef = years;
        }
      })
      setYears(yearsRef);
      
      setLoading(false);
    }
  }, [])

  const [loading, setLoading] = useState(true);

  return (
    <Container className="generic-wrapper" >
      {/*First table*/}
      {loading ? (
        <div>Loading...</div>
      ) : (variableCosts ? (
        <>
          <Row className="no-gutters d-none d-lg-flex">
            <Col lg={3} className="fixed-container">
              <h5 className="first-spacer">Concept</h5>
              {years.map((year,index) =>
                <h5 className="side-text" key={index}>{year}</h5>
              )}
              <h5 className="side-text last">Growth of IMMEX Establishment Plants Last 5 Years(Units)</h5>
            </Col>
            <Col lg={9} className="table-container">
              <div className="table-header">
                {city_names.map((city, index) =>
                  filtered.includes(index) &&
                  <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                )}
              </div>
              {years.map((year) =>
                <div className="row-number">
                  {city_names.map((city, index) => {
                    let textToShow = "N/A";
                    let cityFind = variableCosts.find(cityFind => cityFind.cityName === city);
                    if (cityFind) {
                      let manufacturingGrowth = cityFind.dataVariables?.manufacturingGrowth?.[year];
                      if (manufacturingGrowth) {
                        textToShow = formatNumber(manufacturingGrowth);
                      }
                    }
                    return (
                      filtered.includes(index) &&
                      <h5 className="number" key={`${city}-row1`}>{textToShow}</h5>
                    )
                  }
                  )}
                </div>
              )}
              <div className="row-number">
                {city_names.map((city, index) => {
                  let cellClass = "";
                  let textToShow = "N/A";
                  const cityToShow = details.results.find(cityFind => city === cityFind.city || city.replace(" Metroplex", "") === cityFind.cityName)
                  if (cityToShow) {
                    if (cityToShow.valueResult?.bestOption) {
                      cellClass = "highest";
                      textToShow = "Highest value";
                    } else {
                      let accessed = cityToShow.valueResult?.totalCost;
                      if (accessed !== null) {
                        textToShow = formatNumber(accessed);
                      }
                    }
                  }
                  return filtered.includes(index) && <h5 className="number" key={`${city}-row2`}><span className={cellClass}>{textToShow}</span></h5>
                }
                )}
              </div>
            </Col>
          </Row>

          <section className="mobile-details-results-table d-lg-none">
            <table>
              <thead>
                <tr>
                  <th>Concept</th>
                  {city_names.map((city, index) =>
                    filtered.includes(index) &&
                    <th key={`${city}-table-header`}>{city}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {years.map((year, index) =>
                  <tr key={`${year}-table-row`}>
                    <td>{year}</td>
                    {city_names.map((city, index) => {
                      let textToShow = "N/A";
                      let cityFind = variableCosts.find(cityFind => cityFind.cityName === city);
                      if (cityFind) {
                        let manufacturingGrowth = cityFind.dataVariables?.manufacturingGrowth?.[year];
                        if (manufacturingGrowth) {
                          textToShow = formatNumber(manufacturingGrowth);
                        }
                      }
                      return (
                        filtered.includes(index) &&
                        <td key={`${city}-table-row1`}>{textToShow}</td>
                      )
                      })
                    }
                  </tr>
                )}
                <tr>
                  <td style={{whiteSpace: "pre-wrap"}}>Growth of IMMEX Establishment Plants Last 5 Years(Units)</td>
                  {city_names.map((city, index) => {
                    let cellClass = "";
                    let textToShow = "N/A";
                    const cityToShow = details.results.find(cityFind => city === cityFind.city || city.replace(" Metroplex", "") === cityFind.cityName)
                    if (cityToShow) {
                      if (cityToShow.valueResult?.bestOption) {
                        cellClass = "highest";
                        textToShow = "Highest value";
                      } else {
                        let accessed = cityToShow.valueResult?.totalCost;
                        if (accessed !== null) {
                          textToShow = formatNumber(accessed);
                        }
                      }
                    }
                    return filtered.includes(index) && <td key={`${city}-table-row2`}><span className={cellClass}>{textToShow}</span></td>
                  })
                  }
                </tr>
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div className="no-record-wrapper">
          <h3 className="no-record-title">There has been an issue retrieving data.</h3>
          <img src={infodanger} width="50px" height="50px" />
        </div>
      )
      )}
    </Container>
  )
}

export default ManufacturingGrowth;