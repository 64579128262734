import { Row, Col, Tab, Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const TotalPayroll = ({ details, city_names, filtered, reportID, totalPayroll, assumptions }) => {

  const [loading, setLoading] = useState(true);
  const [firstObject, setFirstObject] = useState(null);
  const [activeTab, setActiveTab] = useState("TotalMonthlyPerPerson");
  //Define an array as utility to render 3 pre-defined tabs
  const tabsArray = [
    {
      name: "Monthly-per employee",
      access: "TotalMonthlyPerPerson",
      hasTotals: 0
    },
    {
      name: "Annual-per employee",
      access: "TotalAnnualPerPerson",
      hasTotals: 0,

    },
    {
      name: "Annual-Total",
      access: "TotalAnnualPerRole",
      hasTotals: 1

    }
  ]
  //Temp exchange rate for the table conversion
  const exchangeRate = assumptions.exchangeRate ? assumptions.exchangeRate : 16.5;


  //Retrieval of the payroll data from the api
  useEffect(() => {
      if(totalPayroll){
        //Define a generic object as all cities should share the positions
        let keysOfFirstObject = Object?.keys(totalPayroll[0]);
        let firstKey = keysOfFirstObject?.[0];
        let valueOfFirstKey = totalPayroll?.[0]?.[firstKey];
        setFirstObject(valueOfFirstKey);
        setLoading(false)
      }

  }, [totalPayroll]);


  return (
    <>
      {loading ? (
        <div className="loader">Is loading</div>
      ) : (totalPayroll && totalPayroll !== "There is no payroll" ? (
        <Tab.Container id="table-selector" defaultActiveKey="0">
          <div className="selector-buttons">
            <Col style={{overflowX: "auto"}}>
              <Nav variant="tabs" className="d-flex">
                {tabsArray?.map((tab, index) => (
                  <Nav key={tab.name} onClick={() => setActiveTab(tab.access)}>
                    <Nav.Link eventKey={index} className="selector-tab">
                      <h5>Estimated fully burden payroll USD</h5>
                      <p>{tab.name}</p>
                    </Nav.Link>
                  </Nav>
                ))}
              </Nav>
            </Col>
          </div>
          <div className="d-none d-lg-block">
            <Tab.Content>
              {tabsArray.map((detail, tabIndex) => (
                <Tab.Pane eventKey={tabIndex} key={`${detail.name}-${detail.access}`}>
                  <Row className="no-gutters">
                    <Col lg={1} className="qty">
                      <h5 className="table-heading qty">Qty</h5>
                      {/* <div className="separator-row"></div> */}
                      {firstObject?.map((roleQty, index) => <h5 className="table-number qty" key={`${roleQty.position}-${index}-firstColumn`}>{roleQty.Qty}</h5>)}
                    </Col>
                    <Col lg={3} className="position">
                      <h5 className="table-heading">Position</h5>
                      {firstObject?.map((role, index) => <h5 className="table-text" key={`${role.position}-${index}-secondColumn`}>{role.Position}</h5>)}
                    </Col>
                    <Col lg={8} className="table-container">
                      <div className="table-p-header">
                        {city_names.map((city, index) => (
                          filtered.includes(index) &&
                          <div className="table-heading" key={`${city}-header`}>
                            {city}
                          </div>
                        ))}
                      </div>
                      {firstObject?.map((role, roleIndex) => (
                        <div className="score-row" key={`${role.position}-${roleIndex}-thirdColumn`}>
                          {city_names.map((cityName, index) => {
                            let textToShow = "N/A";
                            totalPayroll.forEach((city) => {
                              let objectKey = Object.keys(city);
                              if (objectKey[0] === cityName.replace(" Metroplex", "") || objectKey[0] === cityName) {
                                let objectValue = city[objectKey];
                                let propertyToAccess = tabsArray[tabIndex].access;
                                let priceToConvert = objectValue[roleIndex]?.[propertyToAccess];
                                if (propertyToAccess === "TotalMonthlyPerPerson") {
                                  priceToConvert /= exchangeRate;
                                }
                                textToShow = "$ " + formatNumber(priceToConvert);
                              }
                            })
                            return (
                              filtered.includes(index) && (
                                <h5 className="table-number" key={`${cityName}-column`}>
                                  {textToShow}
                                </h5>
                              )
                            );
                          })}
                        </div>
                      )
                      )}
                      {detail.hasTotals === 1 && (
                        <>
                          <div className="final-row">
                            {details.results
                              .map((city, index) => {
                                let cellClass = "table-number";
                                if (city.valueResult.bestOption) {
                                  cellClass += " lowest";
                                }
                                return (
                                  filtered.includes(index) &&
                                  <h5 className={cellClass} key={`${city._id}-details`}>
                                    <span>$ {city.valueResult.totalCost !== null ? formatNumber(city.valueResult.totalCost) : "N/A"}</span>
                                  </h5>
                                );
                              })}
                          </div>
                          <div className="extra-data">
                            {city_names.map((city, index) => {
                                let textToShow = "N/A";
                                let cityFind = details.results.find((cityResult) => cityResult.city === city || cityResult.city === city.replace(" Metroplex", ""));
                                if (cityFind) {
                                  if(cityFind.valueResult.bestOption){
                                    textToShow = "Lowest Cost";
                                  }else{
                                    let cityDifference = cityFind.valueResult.difference;
                                    if(cityDifference){
                                      let access = formatNumber(cityDifference)
                                      if(cityFind.valueResult.typeOfDifference && cityFind.valueResult.typeOfDifference === "Percentage"){
                                        textToShow = `${access} %`;
                                      }else{
                                        textToShow = `$ ${access}`;
                                      }
                                    }
                                  }
                                }
                                return (
                                  filtered.includes(index) &&
                                  <h5 className="table-number" key={`${city}-extra`}>
                                    <span>
                                      {textToShow} 
                                    </span>
                                  </h5>
                                );
                              })}
                          </div>
                        </>
                      )}

                    </Col>
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>

          <div className="payroll-table-section d-lg-none">
            <table>
              <thead>
                <tr>
                  <th>Qty</th>
                  <th>Position</th>
                  {city_names?.map((city, index) => (
                    filtered?.includes(index) &&
                    <th key={city}>{city}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                  {firstObject?.map((role, roleIndex) => (
                    <tr key={`${role?.position}-${roleIndex}`}>
                      <td>{role?.Qty}</td>
                      <td>{role?.Position}</td>
                      {city_names.map((cityName, index) => {
                        let textToShow = "N/A";
                        totalPayroll.forEach((city) => {
                          let objectKey = Object?.keys(city);
                          if (objectKey[0] === cityName.replace(" Metroplex", "") || objectKey[0] === cityName) {
                            let objectValue = city[objectKey];
                            let priceToConvert = objectValue[roleIndex]?.[activeTab];
                            if (activeTab === "TotalMonthlyPerPerson") {
                              priceToConvert /= exchangeRate;
                            }
                            textToShow = "$ " + formatNumber(priceToConvert);
                          }
                        })
                        return (
                          filtered?.includes(index) && (
                            <td key={`${cityName}-column`}>
                              {textToShow}
                            </td>
                          )
                        );
                      })}
                    </tr>
                  ))}
                  {activeTab === "TotalAnnualPerRole" && <tr>
                    <td></td>
                    <td></td>
                    {details?.results?.map((city, index) => {
                      let cellClass = "table-number";
                      if (city.valueResult.bestOption) {
                        cellClass += " lowest";
                      }
                      return (
                        filtered.includes(index) && (
                          <td className={cellClass} key={`${city._id}-details`}>
                            <span>$ {city?.valueResult?.totalCost !== null ? formatNumber(city.valueResult.totalCost) : "N/A"}</span>
                          </td>
                        )
                      );
                    })}
                  </tr>}
                  {activeTab === "TotalAnnualPerRole" && <tr>
                    <td></td>
                    <td></td>
                    {city_names.map((city, index) => {
                      let textToShow = "N/A";
                      let cityFind = details?.results?.find((cityResult) => cityResult.city === city || cityResult.city === city.replace(" Metroplex", ""));
                      if (cityFind) {
                        if(cityFind?.valueResult?.bestOption){
                          textToShow = "Lowest Cost";
                        }else{
                          let cityDifference = cityFind?.valueResult?.difference;
                          if(cityDifference){
                            let access = formatNumber(cityDifference)
                            if(cityFind?.valueResult?.typeOfDifference && cityFind?.valueResult?.typeOfDifference === "Percentage"){
                              textToShow = `${access} %`;
                            }else{
                              textToShow = `$ ${access}`;
                            }
                          }
                        }
                      }
                      return (
                        filtered.includes(index) && (
                          <td className="table-number" key={`${city}-extra`}>
                            <span>
                              {textToShow} 
                            </span>
                          </td>
                        )
                      );
                    })}
                  </tr>}
                </tbody>
            </table>
          </div>
        </Tab.Container>
      ) : (
        <div className="no-record-wrapper">
              <h3 className="no-record-title">There is no spreadsheet available. </h3>
              <img src={infodanger} width="50px" height="50px" />
            </div>
      )
      )}
    </>
  )
}

export default TotalPayroll;