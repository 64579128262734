export function createPdfObject(report, wantsMatrixRef, totalPayroll, borderDistances) {
    const folio = report.report.folio
    const reportName = report.report.reportName

    const report_assumptions = report.report.siteSelectionReport.assumption
    const report_musts = report.report.siteSelectionReport.must
    const report_wants = report.report.siteSelectionReport.want.wantsMatrix
    const results = report.results

    // #region Assumptions
    // Create Building Section
    const building = {
        Type: report_assumptions.building.buildingContract,
        Size: `${addCommas(+report_assumptions.building.sizeValue)} ${report_assumptions.building.sizeType.toUpperCase()}`,
        requirements: {
            options: report_assumptions.building.requirements.options,
            otherValue: report_assumptions.building.requirements.otherValue
        },
    }

    // Create Utilities Section
    const gasConsumption = report_assumptions.utilities.electricityInstalled.toLowerCase() === "monthly" ? +report_assumptions.utilities.gas : (report_assumptions.utilities.gas / 12)
    const waterConsumption = report_assumptions.utilities.waterType.toLowerCase() === "monthly" ? +report_assumptions.utilities.water : (report_assumptions.utilities.water / 12)

    const utilities = {
        "Electricity Consumption": `${addCommas(+report_assumptions.utilities.electricityConsumed)} KWH`,
        "KVA Installed": `${addCommas(+report_assumptions.utilities.electricityInstalled)}KVA`,
        "Natural Gas Consumption Monthly": `${addCommas(gasConsumption)} GJ`,
        "Water Consumption per month": `${waterConsumption} m³`,
    }

    // Create Logistics Section
    const logistics = {
        "Other Logistics Considerations": `${report_assumptions.logistics.otherLogisticConsiderations}`,
        "Handling Equipment - Forklift or other handling Equipment": `${report_assumptions.logistics.handlingEquipment}`,
        logisticsInbound: report_assumptions.logistics.logisticsInbound,
        "Outbound Logistics": report_assumptions.logistics.logisticsOutbound,
    }

    // Calculate Headcount
    const isTotalPayroll = Array.isArray(totalPayroll) ? totalPayroll[0] : null
    const headcount = isTotalPayroll ? Object.values(isTotalPayroll)[0].reduce((acc, curr) => acc + curr.Qty, 0) : 0

    // Create assumption table
    const assumptionsTable = {
        ...assumptionDescription,
        building,
        utilities,
        logistics,
        environmentalImpacts: report_assumptions.environmentalImpacts.options,
        Headcount: headcount,
        exchangeRate: `$${report_assumptions.exchangeRate} pesos per dollar`,
    }

    // #region Must
    // Get population values
    const minPopulation = report_musts.cities.reduce((acc, curr) => {
        if (curr.population < acc) {
            return curr.population
        }

        return acc
    }, Infinity)

    const minPopulationValue = Math.floor(minPopulation / 100000) * 100000

    // Get active population values
    const minEAP = report_musts.cities.reduce((acc, curr) => {
        if (curr.EAP < acc) {
            return curr.EAP
        }

        return acc
    }, Infinity)

    const minEAPValue = Math.floor(minEAP / 100000) * 100000

    const getMinBorderDistance = report_musts.cities.reduce((acc, curr) => {
        const minCityDistance = borderDistances?.find(cityBorder => cityBorder.datamexico_municipality_id === curr.datamexico_municipality_id)?.distances?.reduce((acc2, curr2) => {
            if (curr2.distance < acc2.distance) {
                return curr2
            }

            return acc2
        }, { distance: Infinity })

        if (minCityDistance.distance > acc.distance) {
            return minCityDistance
        }

        return acc
    }, { distance: 0 })


    const filtersSection = {
        "Population": `> ${addCommas(minPopulationValue)}`,
        "Economic Active Population": `> ${addCommas(minEAPValue)}`,
        // "At least 1 supplier of each process": "< N/A",
        "Time to closest border": `< ${addCommas(getMinBorderDistance.time)} Hrs`,
        "Distance to closest border": `< ${addCommas(getMinBorderDistance.distance)} Km`,
        // "Time to Altamira Port": "< N/A",
        // "TSU Skilled - Machining Industry": "+ N/A"
    }

    // Create must section
    const mustSection = {
        ...mustDescription,
        filters: filtersSection,
        cities: report_musts.cities
    }

    // #region Want
    const wantsMatrixPdf = report_wants.filter(cat => cat.weight !== 0).map(cat => {
        const categoryRef = wantsMatrixRef.find(catRef => catRef._id === cat.catIdRef)

        // get Category total per city
        const catTotals = report_musts.cities.map(city => {
            const variablesResults = results.wantsCategories.find(catRes => catRes.categoryIdRef === cat.catIdRef)?.variables

            const catResults = variablesResults?.reduce((acc, curr) => {
                const cityPoints = curr?.results?.find(cityRes => cityRes.city === city.municipality_name)?.valueResult?.wantsPoints ?? 0

                return acc + cityPoints
            }, 0)


            return {
                cityId: city.datamexico_municipality_id,
                cityName: city.municipality_name,
                points: catResults
            }
        })

        // get Variable properties
        const categoryVariables = cat.variables.filter(variable => variable.weight !== 0).map(variable => {
            const variableRef = categoryRef.variables.find(varRef => varRef._id === variable.varIdRef)
            const points = cat.weight * (variable.weight / 100)

            // get variable points per city
            const varTotals = report_musts.cities.map(city => {
                const variables = results.wantsCategories.find(catRes => catRes.categoryIdRef === cat.catIdRef)?.variables

                const variableResult = variables?.find(varResult => varResult.variableIdRef === variable.varIdRef)?.results?.find(varCityRes => varCityRes.city === city.municipality_name)?.valueResult?.points

                return {
                    cityId: city.datamexico_municipality_id,
                    cityName: city.municipality_name,
                    points: variableResult
                }
            })

            return {
                ...variable,
                //! quitar information al final
                information: variableRef.information,
                title: variableRef.title,
                position: variableRef.position,
                points: points,
                results: varTotals
            }
        })

        // return Category
        return {
            catIdRef: categoryRef._id,
            position: categoryRef.position,
            title: categoryRef.title,
            variables: categoryVariables,
            weight: cat.weight,
            totals: catTotals
        }
    })

    // Create want section
    const wantSection = {
        ...wantDescription,
        wantMatrix: wantsMatrixPdf
    }

    
    // #region total object
    // get city total
    const totalSection = report_musts.cities.map(city => {
        const cityTotal = wantsMatrixPdf.reduce((acc, curr) => {
            const catTotal = curr?.totals?.find(cat => cat.cityId === city.datamexico_municipality_id)?.points ?? 0
            
            return acc + catTotal
        }, 0)?.toFixed(2)
        
        return {
            cityId: city.datamexico_municipality_id,
            cityName: city.municipality_name,
            points: +cityTotal
        }
    })

    const maxPoints = Math.max(...totalSection.map(city => city.points));
    
    totalSection.forEach(city => {
        if (city.points === maxPoints) {
            city.bestOption = true

            return
        }

        city.bestOption = false
    })

    // #region Sources
    const sourcesSection = report_wants.filter(cat => cat.weight !== 0).flatMap(cat => {
        const categoryRef = wantsMatrixRef.find(catRef => catRef._id === cat.catIdRef)
        
        return cat.variables.filter(variable => variable.weight !== 0).map(variable => {
            const variableRef = categoryRef.variables.find(varRef => varRef._id === variable.varIdRef)
            return {
                variable: variableRef.title,
                source: variable.information.source
            }
        })
    })

    // #region final PDF object
    const pdf = {
        folio,
        reportName,
        assumptions: report_assumptions,
        report: {
            ...WHY_PRODENSA,
            assumption: assumptionsTable,
            must: mustSection,
            want: wantSection,
            summary: summarySection,
            total: totalSection,
            sources: sourcesSection
        }
    }

    return pdf
}

const WHY_PRODENSA = {
    objective: {
        description:
            "Poclain Hydraulics is currently in the search process for the ideal location to expand its manufacturing footprint in Mexico.",
        long: "As part of this endeavor, Poclain Hydraulics requires to evaluated the business environment in Mexico and has engaged the services of Prodensa, an advisory firm, to assess and gather information from up-to- date operating costs, labor market conditions, industry environment, security and government, infrastructure, and quality of life in each city.",
    },
    why_client: {
        description:
            "Prodensa is an advisory and project firm with more than 37 years of experience supporting manufacturing start-up projects through strategic advisory and support. in Mexico hands- on suppot.",
        long: "From shelter operations to advisory insights, market intelligence, and execution, Prodensa offers the right mix of team experts, know- how, and local skills to provide solutions to all the stages of your project.",
        expyears: "+20 years",
        clients: "+1000",
    },
}

const assumptionDescription = {
    description: `In our City and Site Selection Study, the "assumptions" section includes a crucial list of data provided by the client, offering valuable insights into their specific requirements operational needs.`,
    longtext: `This section typically includes comprehensive information about the client's organization, such as the desired size of the facility needed for their manufacturing operations. Additionally, it involves data regarding the inbound and outbound freight for raw materials and finished products, along with their respective destinations.
    `,
}

const mustDescription = {
    description: `In our City and Site Selection Study, the "musts" section refers to a crucial component that outlines the essential criteria and filters necessary for the client to evaluate potential cities for their manufacturing relocation project in Mexico.`,
    longtext:
        "These criteria typically include the city's population, economic activity, proximity to borders and ports, and the overall manufacturing environment. By defining these musts, Prodensa assists companies in identifying suitable locations to further analyze that align with their operational requirements, ensuring a comprehensive and informed decision-making process for their manufacturing footprint relocation.",
}

const wantDescription = {
    description: `In our City and Site Selection Study, the "assumptions" section
        includes a crucial list of data provided by the client, offering
        valuable insights into their specific requirements operational
        needs.`,

    longtext: `These “wants” represent specific characteristics or factors that
        are important to the client’s decision-making process and play a
        crucial role in evaluating and comparing different cities. Each
        “wants” is associated with a particular variable or metric, which
        the client gives a weight for each “wants” within each section in
        the form of “points” and a weight for each section in general in
        the form of a percentage, for the overall scoring system in the
        form. And based on the results obtained for each variable in each
        city, a score is assigned to facilitate the comparison`,
}

const summarySection = {
    title: `CITY AND SITE
    SELECTION STUDY
    RESULTS`,
    description:
        "The purpose of this report is to provide a concise overview of the findings and recommendations for the client considering relocation of manufacturing footprint.",
    longtext:
        "By examining the strengths, weaknesses, and suitability of each city, this summary aims to assist the client in making an informed and strategic decision regarding the optimal location for their manufacturing operations.",
}

function addCommas(value) {
    if (value?.toString()?.includes(".")) return value?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export async function imageToBase64(imageUrl) {
    const response = await fetch(imageUrl);
    if (!response.ok) {
        throw new Error('Failed to fetch image');
    }
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}