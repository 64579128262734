import { useEffect, useState } from "react";
import close from "../../../assets/images/close.svg"
import "../../../assets/css/siteSelection/client.css"
import { Col, Row } from "react-bootstrap";
import save from "../../../assets/images/save.svg";
import { Client } from "../../../models/client.ts";
import info from "../../../assets/images/info.svg";
import Axios from "axios";
import Swal from "sweetalert2";

const PlacesContainers = {
    Headquarters: Symbol("Headquarters"),
    None: Symbol("None"),
}

function ExistingClient(props) {
    const [focused, setFocused] = useState(false)
    const [showClients, setShowClients] = useState(false)
    const [placesFound, setPlacesFound] = useState([])
    const [showPlacesContainer, setShowPlacesContainer] = useState(PlacesContainers.None)
    const [showLogo, setShowLogo] = useState(props.client.logo && props.client.logo.filename !== "")
    const [search, setSearch] = useState("")
    const [filteredClients, setFilteredClients] = useState(props.clients ?? [])

    useEffect(() => {
        props.client.companyName !== "" && props.client.phone !== "" && props.client.industry !== "" &&
            props.client.contactName !== "" && props.client.mail !== "" && props.client.headquarters.fullName !== "" &&
            props.client.headquarters.latitude !== undefined && props.client.headquarters.longitude !== undefined
            ? props.setIsDisable(false) : props.setIsDisable(true);
    }, [props.client])

    useEffect(() => {
        fetchCoordenates();
    }, [props.client.headquarters])

    useEffect(() => {
        const filterClients = props.clients.filter(client => client.companyName.toLowerCase().includes(search.toLowerCase()))
        setFilteredClients(search === "" ? props.clients : filterClients)
    }, [search])

    const fetchCoordenates = async () => {
        if (!props?.client?.headquarters?.fullName) return
        
        try {
            const { data } = await Axios.get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.client.headquarters.fullName}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
            );
    
            setPlacesFound(data.features)
        } catch (error) {
            console.log(error)
        }
    };

    const selectClient = (client) => {
        setShowLogo(client.logo && client.logo.filename !== "");
        props.setClient(client);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 8 && props.client._id !== null && props.client._id !== undefined &&
            props.client._id !== "") {
            props.setClient(new Client())
        }
    }

    const selectLogo = async (e) => {
        const allowedTypes = ["image/jpeg", "image/png"];
        const selectedFile = e.target.files[0];

        if (selectedFile && !allowedTypes.includes(selectedFile?.type)) {
            await Swal.fire({
                icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
            return;
        }

        props.setSelectedFile(selectedFile)
    };

    const removeLogo = () => {
        setShowLogo(false);

        props.setClient(prevState => {
            return {
                ...prevState,
                logo: {
                    ...prevState.logo,
                    filename: "",
                }
            }
        })
    }

    const capitalizedName = (name) => {
		return name.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
	}

    return (
        <section className="existingClientSection">
            <Row>
                <Col lg={{ span: 8, offset: 1 }} xs={{ span: 11, offset: 1 }}>
                    <div className="searchContainer">
                        <input className="search" type="text" placeholder="Search client"
                            value={props.client._id !== null && props.client._id !== undefined &&
                                props.client._id !== "" ?
                                `${props.client.companyName} - ${props.client.contactName}` : search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={() => setShowClients(!showClients)}
                            onBlur={() => setShowClients(!showClients)}
                            onKeyDown={handleKeyDown} />
                        <div className={`searchClients ${showClients ? "show" : ""} d-none d-lg-block`}>
                            <div className="clients">
                                {filteredClients.map(client => (
                                    <div className="client" key={client._id} onClick={() => selectClient(client)}>
                                        <span className="companyName">{client.companyName}</span>
                                        <span className="contactName">{client.contactName}</span>
                                        <span className="mail">{client.mail}</span>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="viewAll">
                                <img src={save} alt="ProdensaAutomation"/>
                                <span>View all clients</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="client-mobile-list d-lg-none">
                    {props.client._id === "" && filteredClients.map(client => (
                        <div className="client-mobile" key={client._id} onClick={() => selectClient(client)}>
                            <h5 style={{fontWeight: "700"}} className="companyName-mobile">{capitalizedName(client.companyName)}</h5>
                            <div className="client-info-mobile">
                                <h5 style={{fontWeight: "700"}} className="contactName-mobile">{capitalizedName(client.contactName)}</h5>
                                <span className="mail-mobile">{client.mail}</span>
                            </div>
                        </div>
                    ))}
                    </div>
                </Col>
            </Row>
            {(props.client._id !== null && props.client._id !== undefined && props.client._id !== "") &&
                <Row>
                    <Col lg={{ span: 8, offset: 1 }} xs={{ span: 11, offset: 1 }}>
                        <div className="inputContainer">
                            <span className="abas">Company name</span>
                            <input value={props.client.companyName}
                                onChange={(e) => props.setClient({
                                    ...props.client,
                                    companyName: e.target.value
                                })}
                                type="text"
                                placeholder="Company name" />
                        </div>
                        <div className="inputContainer">
                            <span className="abas">Phone</span>
                            <input value={props.client.phone}
                                onChange={(e) => props.setClient({ ...props.client, phone: e.target.value })}
                                type="text"
                                placeholder="Phone" />
                        </div>
                        <div className="inputContainer">
                            <span className="abas">Industry</span>
                            <input value={props.client.industry}
                                onChange={(e) => props.setClient({
                                    ...props.client,
                                    industry: e.target.value
                                })}
                                type="text" placeholder="Industry" />
                        </div>
                        <div className="inputContainer">
                            <span className="abas">Contact name</span>
                            <input value={props.client.contactName}
                                onChange={(e) => props.setClient({
                                    ...props.client,
                                    contactName: e.target.value
                                })}
                                type="text"
                                placeholder="Contact name" />
                        </div>
                        <div className="inputContainer">
                            <span className="abas">Mail</span>
                            <input value={props.client.mail}
                                onChange={(e) => props.setClient({ ...props.client, mail: e.target.value })}
                                type="email"
                                placeholder="Mail"
                                onFocus={() => setFocused(!focused)} onBlur={() => setFocused(!focused)} />
                            <span className={`noteUser ${focused ? "show" : ""}`}>
                                <img src={info} alt="ProdensaAutomation" />
                                Enter your client's email address
                            </span>
                        </div>
                        <div className="inputContainer position-relative">
                            <span>Headquarters</span>
                            <input value={props.client.headquarters.fullName}
                                type="text"
                                placeholder="Headquarters"
                                onBlur={() => setShowPlacesContainer(PlacesContainers.None)}
                                onChange={(e) => {
                                    if (e.target.value !== "")
                                        setShowPlacesContainer(PlacesContainers.Headquarters)
                                    else
                                        setShowPlacesContainer(PlacesContainers.None)

                                    props.setClient(
                                        {
                                            ...props.client,
                                            headquarters: {
                                                ...props.client.headquarters,
                                                fullName: e.target.value,
                                                city: "",
                                                state: "",
                                                country: "",
                                                latitude: undefined,
                                                longitude: undefined,
                                            }
                                        })
                                }} />
                            <div
                                className={`placesFoundContainer ${showPlacesContainer === PlacesContainers.Headquarters ?
                                    "show" : ""}`}>
                                {placesFound.map((place, index) => {
                                    return (
                                        <span key={`place_${index}`} className="placeFound"
                                            onClick={() => {
                                                const state = place.context.find(x => x.id.includes("region"))?.text;
                                                const country = place.context.find(x => x.id.includes("country"))?.text;

                                                props.setClient({
                                                    ...props.client,
                                                    headquarters: {
                                                        ...props.client.headquarters,
                                                        fullName: place.place_name,
                                                        city: place.text,
                                                        state: state ?? "",
                                                        country: country ?? "",
                                                        latitude: place.geometry.coordinates[1],
                                                        longitude: place.geometry.coordinates[0],
                                                    }
                                                });
                                                setShowPlacesContainer(PlacesContainers.None);
                                            }}>
                                            {place.place_name}</span>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="inputContainer">
                            <span>Notes</span>
                            <input value={props.client.notes}
                                onChange={(e) => props.setClient({ ...props.client, notes: e.target.value })}
                                type="text"
                                placeholder="Notes" />
                        </div>
                        {showLogo &&
                            <>
                                <span>Logo</span>
                                <div className="inputFileContainer">
                                    <div className="selectFileContainer">
                                        <button className="secondaryButton">View attached file</button>
                                        <span>{props.client.logo.filename}</span>
                                    </div>
                                    <img src={close} alt="Prodensa automation" onClick={removeLogo} />
                                </div>
                            </>
                        }
                        {!showLogo &&
                            <div className="inputContainer">
                                <span>Logo</span>
                                <input type="file"
                                    accept=".jpg, .png"
                                    placeholder="Logo"
                                    onChange={(e) => selectLogo(e)} />
                            </div>
                        }
                    </Col>
                </Row>
            }
        </section>
    )
}

export default ExistingClient;
