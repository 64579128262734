import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState, useMemo } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { v4 as uuidv4 } from "uuid";


const StateElections = ({
  details,
  city_names,
  filtered,
  musts,
  assumptions,
  variableCosts,
}) => {
  
const BODY_ROWS_TITLE = useMemo(() => {
    return [
      {
        title: "State Elections",
        access: "yearStateElection",
        id: uuidv4(),
      },
      {
        title: "Party in Power",
        access: "partyPower",
        id: uuidv4(),
      },
      {
        title: "Party Elected",
        access: "partyElected",
        id: uuidv4(),
      },
      {
        title: "Federal Government Party",
        access: "federalGovernment",
        id: uuidv4(),
      },
    ];
  })
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (musts && assumptions && variableCosts) {
      setLoading(false);
    }
  }, []);

  function infoToShow(city, access) {
    let infoToShow = "N/A";
    const cityFind = variableCosts.find((cityFind) => city === cityFind.cityName);
    if (cityFind && cityFind.dataVariables && cityFind.dataVariables?.governmentLandscape?.[access]) {
      infoToShow = cityFind.dataVariables.governmentLandscape[access];
    }
    return infoToShow;
  }

  return (
    <Container className="generic-wrapper">
      {/*First table*/}
      {loading ? (
        <div>Loading...</div>
      ) : musts && assumptions && variableCosts ? (
        <>
          <Row className="no-gutters d-none d-lg-flex">
            <Col lg={3}>
              <h5 className="first-spacer">Concept</h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>State Elections</h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>
                Party in Power
              </h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>Party Elected</h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>Federal Government Party</h5>
            </Col>
            <Col lg={9} className="table-container">
              <div className="table-header">
                {city_names.map(
                  (city, index) =>
                    filtered.includes(index) && (
                      <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                    )
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (stateElections, index) => {
                    let infoToShow = "N/A"
                    let stateElectionsToRender = variableCosts.find(obj => obj.cityName === stateElections);
                    if (stateElectionsToRender) {
                      infoToShow = stateElectionsToRender.dataVariables?.governmentLandscape?.yearStateElection ? stateElectionsToRender.dataVariables.governmentLandscape.yearStateElection : "N/A";
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }} key={`${stateElections}-elections-year`}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (stateElections, index) => {
                    let infoToShow = "N/A"
                    let stateElectionsToRender = variableCosts?.find(obj => obj.cityName === stateElections);
                    if (stateElectionsToRender) {
                      infoToShow = stateElectionsToRender.dataVariables?.governmentLandscape?.partyPower ? stateElectionsToRender.dataVariables.governmentLandscape.partyPower : "N/A";
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }} key={`${stateElections}-party-in-power`}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (stateElections, index) => {
                    let infoToShow = "N/A"
                    let stateElectionsToRender = variableCosts?.find(obj => obj.cityName === stateElections);
                    if (stateElectionsToRender) {
                      infoToShow = stateElectionsToRender.dataVariables?.governmentLandscape?.partyElected ? stateElectionsToRender.dataVariables.governmentLandscape.partyElected : "N/A";
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }}  key={`${stateElections}-party-elected`}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (stateElections, index) => {
                    let infoToShow = "N/A"
                    let stateElectionsToRender = variableCosts?.find(obj => obj.cityName === stateElections);
                    if (stateElectionsToRender) {
                      infoToShow = stateElectionsToRender?.dataVariables?.governmentLandscape?.federalGovernment ? stateElectionsToRender.dataVariables.governmentLandscape.federalGovernment : "N/A";
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }}  key={`${stateElections}-federal-party`}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
            </Col>
          </Row>

          <section className="mobile-details-results-table d-lg-none">
            <table>
              <thead>
                <tr>
                  <th>Concept</th>
                  {city_names.map(
                    (city, index) =>
                      filtered.includes(index) && (
                        <th key={`${city}-mobile-header`}>{city}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {BODY_ROWS_TITLE.map((row) => {
                  return (
                    <tr key={row.id}>
                      <td>{row.title}</td>
                      {city_names.map(
                        (city, index) =>
                          filtered.includes(index) && (
                            <td key={`${row.id}-${city}-mobile-row`}>
                              {infoToShow(city, row.access)}
                            </td>
                          )
                      )}
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div className="no-record-wrapper">
          <h3 className="no-record-title">There has been an issue retrieving data.</h3>
          <img src={infodanger} width="50px" height="50px" />
        </div >
      )}
    </Container>
  );
};

export default StateElections;
