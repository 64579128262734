import "../../assets/css/reportResults/reportResults.css";

import axios from "axios";
import Swal from "sweetalert2";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useEffect, useState } from "react";
import 'slick-carousel/slick/slick-theme.css';
import { useParams, useNavigate } from "react-router-dom";

import Summary from "../reportResults/summary.jsx";
import PayrollDetails from "../payrolldetails.jsx";
import arrowDown from "../../assets/images/arrowDown.svg";
import VideoAndMap from "../reportResults/videoAndMap.jsx";
import Details from "../reportResults/wantsView/details.jsx";
import WantsView from "../reportResults/wantsView/wantsView.jsx";
import arrowforward from "../../assets/images/arrow_forward.svg";
import CompareSlider from "../reportResults/wantsView/compareSlider.jsx";
import ReportResultsFooter from "../reportResults/reportResultsFooter.jsx";
import { createPdfObject } from "../reportResults/utils.js";
import { createPDF } from "../reportResults/generatePdfFile.js";

export default function MagicReport() {

  //*Utilities for component display control or function handling. 
  const [activebutton, setActivebutton] = useState("map");
  const [isDetailsActive, setIsDetailsActive] = useState(false);                  //Controller of details view show
  const [details, setDetails] = useState(null);                                     //variable for passing down details depending on clicked variable
  const [mobilePopupOpen, setMobilePopupOpen] = useState(false);                //Controller for mobile popup
  const [toggleSummary, setToggleSummary] = useState(true);
  const [flyAnimationInProgress, setFlyAnimationInProgress] = useState(true);    //control for initial flyover animation
  const [showExtraDetails, setShowExtraDetails] = useState(false);
  const [streetviewInfo, setStreetviewInfo] = useState({                    //info for streetview window
    open: false,
    id: 0
  })
  const [tableComponentLoaded, setTableComponentLoaded] = useState(false);


  //*Control variables for API consumption
  // const [magicLink, setMagicLink] = useState(null);
  // const [userDefinitions, setUserDefinitions] = useState(null);
  const { token } = useParams();


  //*Misc initializing variables
  const [sliderSettings, setSliderSettings] = useState(null);     //Control variable for carousel settings


  //!TESTING VARIABLE
  const [report, setReport] = useState(null)
  const [client, setClient] = useState(null); //array of client info
  const [wantsMatrix, setWantsMatrix] = useState(null); //array of user defined matrix values
  const [wantsDetails,setWantsDetails] = useState(null); //array of user defined matrix values
  const [variableCosts, setVariableCosts] = useState(null); //array of variable costs
  const [allVariableCosts, setAllVariableCosts] = useState(null); //array of variable costs
  const [musts,setMusts] = useState(null); //array of musts
  const [assumptions, setAssumptions] = useState(null); //array of assumptions
  const [wantsResults, setWantsResults] = useState(null); //array of results from wants matrix  
  const [pdfConfig, setPdfConfig] = useState(null)
  const [borderDistances, setBorderDistances] = useState(null)
  const [cityNames, setCityNames] = useState(null);
  const [loading, setLoading] = useState(true);       //Controller for loading state
  const [sumBoxFiltered,setSumBoxFiltered] = useState([]); //array of filtered cities for summary box
  const [totalPayroll, setTotalPayroll] = useState(null); //total payroll
  const [representationLetter, setRepresentationLetter] = useState(null);
  const [wantsMatrixCopy, setWantsMatrixCopy] = useState(null)
  const [isSaved, setIsSaved] = useState(false);
  const [wantsMatrixRef, setWantsMatrixRef] = useState(null);
  const [reportId, setReportId] = useState(null);

  const navigate = useNavigate();

  //*Use effects for initialization of component
  //token validation for retrieval of report object
  useEffect(() => {
    if (token) {
      axios.get(process.env.REACT_APP_VERIFY_MAGIC_LINK + token)
        .then(res => {
          const clientId = res.data.report.siteSelectionReport.clientId;

          axios.get(process.env.REACT_APP_FIND_CLIENT + clientId).then((clientResponse) => {
            setReport({
              report: res.data.report,
              results: res.data.results
            })
            setClient(clientResponse.data);
            setReportId(res.data.report._id);
            setWantsMatrixRef(res.data.wantsMatrixRef)
            setPdfConfig(res.data.pdfConfig)
            setBorderDistances(res.data.borderDistances)

            // Define the array of city Names
            let cityArray = res.data.report.siteSelectionReport.must.cities.map(city => city.municipality_name);
            setCityNames(cityArray);

            // Define the initial filtered cities as all by default
            let initial_filtered = cityArray.map(city => cityArray.indexOf(city));
            setSumBoxFiltered(initial_filtered);

            setMusts(res.data.report.siteSelectionReport.must.cities);
            setAssumptions(res.data.report.siteSelectionReport.assumption);
            setWantsMatrix(res.data.report.siteSelectionReport.want.wantsMatrix)
            setWantsMatrixCopy(JSON.parse(JSON.stringify(res.data.report.siteSelectionReport.want.wantsMatrix)))
            setWantsResults(res.data.results.wantsCategories);
            setVariableCosts(res.data.variableCost?.cities);
            setAllVariableCosts(res.data.variableCost);
            const init_scoreSliderSettings = {                                                  //Initial settings for the carousel slider
              slidesToShow: cityArray.length > 3 ? 3 : cityArray.length,
              slidesToScroll: 1,
              infinite: false,
            };
            setSliderSettings(init_scoreSliderSettings);

            
            if (res.data.report.siteSelectionReport.want.TPCDocument !== "") {
              if (res.data.totalPayroll) {
                setTotalPayroll(res.data.totalPayroll.totalPayrollCostInfo);
              } else {
                setTotalPayroll("There is no payroll");
              }
            } else {
              setTotalPayroll("There is no payroll");
            }

            if (res.data.report.siteSelectionReport.assumption.representationDocument.filename !== "") {
              setRepresentationLetter(res.data.representationLetter.representationDocumentInfo);
            }

            return {clientData: clientResponse.data, mustData: res.data.report.siteSelectionReport.must.cities}
          }).then(async(data) => {
            const fetchPhoto = async(city) => {
              return new Promise((resolve, reject) => {
                  const request = {
                      query: city,
                      fields: ['name', 'geometry', 'photos'],
                  };

                  const service = new google.maps.places.PlacesService(document.createElement('div'));

                  service.findPlaceFromQuery(request, (results, status) => {
                      if (status === google.maps.places.PlacesServiceStatus.OK) {
                          resolve(results[0].photos[0].getUrl({ 'maxWidth': results[0].photos[0].height, 'maxHeight': results[0].photos[0].width }))
                      }
                  });
              })
            }
            
            const getImages = async () => {
              const cities = data.mustData;

              const photoPromises = cities.map(async city => {
                  const cityName = city.municipality_name.replace(" Metroplex", "")
                  const photo = await fetchPhoto(cityName + ", " + city.state_name);
                  city.urlPhoto = photo
                  return city;
              });

              const citiesWithPhoto = await Promise.all(photoPromises);
              setMusts(citiesWithPhoto)
            }
            
            const scriptId = "googleMapsScript";
            const existingScript = document.getElementById(scriptId);

            if (!existingScript) {
              const script = document.createElement("script");
              script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
              script.id = scriptId;
              script.async = true;
              document.body.appendChild(script);

              await new Promise((resolve, reject) => {
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
              })
            }

            await getImages()

            return data.clientData
          }).then((clientData) => {
            if (!clientData) {
              Swal.fire({
                  icon: 'error',
                  title: "There was an error retrieving the client data",
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 2500,
                  timerProgressBar: true,
                  didOpen: () => {
                      Swal.hideLoading()
                  },
                  didDestroy: () => {
                      setLoading(false)
                  }
              })
            }
            
            clientData && setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
            navigate("/expired");
          });
        }).catch(err => {
          console.log(err);
          setLoading(false);
          navigate("/expired");
        });
    }
  }, []);

  useEffect(() => {
    if (!isSaved && activebutton === "wants") {
        setWantsMatrixCopy(JSON.parse(JSON.stringify(wantsMatrix)))
    }
  }, [activebutton])

  const recalculateResults = () => {
    const data = {
      reportId: reportId,
      wantsMatrixCopy: wantsMatrixCopy,
      saveChanges: false,
    }

    const readyToCalculate = wantsMatrixCopy.reduce((acc, category) => acc + (category.weight === "" ? 0 : category.weight), 0) <= 100 && 
          wantsMatrixCopy.every(category => category.variables.reduce((acc, variable) => acc + (variable.weight === "" ? 0 : variable.weight), 0) <= 100)

    if (!readyToCalculate) {
      Swal.fire({
          position: "center",
          title: "Error",
          text: `The sum of the weights for each category and variable must be less than or equal to 100.`,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
      });
      
      return;
    }

    axios.post(process.env.REACT_APP_EDIT_MODE_RESULTS, data).then((editModeRes) => {
      if (!editModeRes.data) {
        Swal.fire({
            position: "center",
            title: "Error",
            text: `There was an error recalculating the results.`,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
        });

        return;
      }
      
      setWantsMatrixCopy(editModeRes.data.wantsMatrixCopy)
      setWantsMatrix(JSON.parse(JSON.stringify(editModeRes.data.wantsMatrixCopy)))

      setWantsResults(editModeRes.data.wantsCategories);

      setIsSaved(true)
      setActivebutton("wants")
    }).catch(err => {
      console.log(err)
      Swal.fire({
        position: "center",
        title: "Error",
        text: `There was an error recalculating the results.`,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      return
    })
  }

  const calculateTotalPoints = (city) => {
    return wantsResults.reduce((total, category) => {
        return total + (!category.variables ? 0 : category.variables.reduce((total, variable) => {
            const result = variable.results && variable.results.find(result => result.city === city);
            return total + (result ? result.valueResult.wantsPoints : 0);
        }, 0));
    }, 0);
  }

  const getMaxScore = (cities) => {
    return Math.max(...cities.map(city => calculateTotalPoints(city)))
  }

  const handleGoBackClick = () => {
    navigate(`/dashboard?reportId=${id}`)
}

const handleEditSaveClick = () => {
    if (activebutton === "edit") {
        recalculateResults()
    } else {
        setIsSaved(false)
        setActivebutton("edit");
    }
}

const handleMapClick = () => {
    setActivebutton("map")
    setIsDetailsActive(false)
}

const handleWantsClick = () => {
    setActivebutton("wants")
}

const downloadPdf = () => {
  const PDF = createPdfObject(report, wantsMatrixRef, totalPayroll, borderDistances)

  try {
    createPDF(PDF, pdfConfig)
  } catch (err) {
    console.log("Error creating PDF")
    console.log(err)
  }
}

  return (
    <section className="report-results-wrapper">
      {loading ? (
        <h1 style={{background:'#041A39'}}></h1>
      ) : (wantsMatrix && wantsResults && cityNames  && musts && assumptions && cityNames && totalPayroll ? (
        <>
          <div className={`button-wrapper ${!flyAnimationInProgress && !streetviewInfo.open ? "show" : ""}`}>
            <button className="button-item" disabled={!pdfConfig?.active} onClick={downloadPdf}>
              <span className="d-none d-lg-block">Download PDF</span>
            </button>
          </div>

          <div className={`d-none d-lg-flex button-wrapper ${!flyAnimationInProgress && !streetviewInfo.open ? "show" : ""}`}>
            <button
              id="edit-save-button"
              className={`button-item ${activebutton === "edit" ? "save" : ""}`}
              onClick={handleEditSaveClick}
            >
              {`${activebutton === "edit" ? "Preview changes" : "Edit mode"}`}
            </button>{" "}
            {/*Verify the current active button and toggle hover class on it*/}
            <button
              id="map"
              className={`button-item ${activebutton === "map" ? "active" : ""}`}
              onClick={handleMapClick}
            >
              Map view
            </button>
            <button
              className={`button-item ${activebutton === "wants" ? "active" : ""}`}
              onClick={handleWantsClick}
            >
              Wants view
            </button>
          </div>

          <div className={`summary-button 
            ${activebutton === "map" && !flyAnimationInProgress && !streetviewInfo.open ? "" : "show"}
            ${toggleSummary ? "" : "toggle"}
            ${mobilePopupOpen ? "mobileHide" : ""}`}
          >
            <div className="d-lg-none hide-show-summary" onClick={() => setToggleSummary(!toggleSummary)}>
                <img src={arrowDown} alt="Prodensa Automation" />
            </div>
            <div className="summary-box-head">
              <h4>Summary</h4>
              <CompareSlider 
                cityNames={cityNames} 
                setSumBoxFiltered={setSumBoxFiltered} 
                sliderSettings={sliderSettings} 
                setSliderSettings={setSliderSettings} 
                sumBoxFiltered={sumBoxFiltered} 
              />
            </div>
            <div className="slider-detailsButton-container">
              <div className="scores-row">
                <Slider  {...sliderSettings}>
                  {/*Shows the summary icon, mapping through cities and assigning a special class to the highest so its highlighted*/}
                  {cityNames.map((cityName, cityIndex) => {

                    return (
                      sumBoxFiltered.includes(cityIndex) &&
                      <div key={cityIndex} className="score-container">
                        <h5>{cityName}</h5>
                        <h2
                          className={
                            calculateTotalPoints(cityName) === getMaxScore(cityNames)
                              ? "highest"
                              : "std"
                          }
                        >
                          {calculateTotalPoints(cityName).toFixed(0)}
                        </h2>
                      </div>
                    )
                  })}
                </Slider>
              </div>
              <button onClick={() => setActivebutton("summary")}>
                <span>See details</span>
                <img src={arrowforward} />{" "}
                {/*Button, pending to add functionality of rendering the component summary*/}
              </button>
            </div>
          </div>

          <VideoAndMap
            activebutton={activebutton}
            typeOfResult={"generate"}
            flyAnimationInProgress={flyAnimationInProgress}
            setFlyAnimationInProgress={setFlyAnimationInProgress}
            streetviewInfo={streetviewInfo}
            setStreetviewInfo={setStreetviewInfo}
            assumptions={assumptions.logistics}
            allAssumptions={assumptions}
            representationLetter={representationLetter}
            representationLetterActive={assumptions.representationLetter}
            musts={musts}
            client={client}
            setMobilePopupOpen={setMobilePopupOpen}
            borderDistances={borderDistances}
          />
          <WantsView
            wantsMatrixRef={wantsMatrixRef}
            activebutton={activebutton}
            setIsDetailsActive={setIsDetailsActive}
            setDetails={setDetails}
            setTableComponentLoaded={setTableComponentLoaded}
            wantsResults={wantsResults}
            wantsMatrix={wantsMatrix}
            wantsMatrixCopy={wantsMatrixCopy}
            setWantsMatrixCopy={setWantsMatrixCopy}
            cityNames={cityNames}
            reportID={reportId}
            setWantsDetails={setWantsDetails}
            land={assumptions.land}
            token={true}
          />
          <Details
            wantsMatrixRef={wantsMatrixRef}
            setIsDetailsActive={setIsDetailsActive}
            isDetailsActive={isDetailsActive}
            details={details} setDetails={setDetails}
            city_names={cityNames}
            setShowExtraDetails={setShowExtraDetails}
            tableComponentLoaded={tableComponentLoaded}
            setTableComponentLoaded={setTableComponentLoaded}
            variableCosts={variableCosts}
            allVariableCosts={allVariableCosts}
            musts={musts}
            assumptions={assumptions}
            wantsDetails={wantsDetails}
            totalPayroll={totalPayroll}
          />
          <Summary 
            activebutton={activebutton}
            setActivebutton={setActivebutton}
            wantsResults={wantsResults}
            cityNames={cityNames}
            wantsMatrix={wantsMatrix}
            wantsMatrixRef={wantsMatrixRef}
          />
          {details && details.variableIdRef.toString() === "65cd417ff955fa725381e7e7" &&
            <PayrollDetails
              setShowExtraDetails={setShowExtraDetails}
              city_names={cityNames}
              showExtraDetails={showExtraDetails}
              totalPayroll={totalPayroll} 
            />
          }

          <ReportResultsFooter
              activebutton={activebutton}
              typeOfResult={"generate"}
              handleGoBackClick={handleGoBackClick}
              handleEditSaveClick={handleEditSaveClick}
              handleMapClick={handleMapClick}
              handleWantsClick={handleWantsClick}
              token={true}
          />
        </>
      ) : (
        <h1>Data load has failed</h1>
      )
      )
      }
    </section>
  );
}
