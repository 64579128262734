import {useEffect, useState} from "react";
import "../../../assets/css/siteSelection/client.css"
import info from "../../../assets/images/info.svg";
import {Col, Row} from "react-bootstrap";
import Axios from "axios";
import Swal from "sweetalert2";

const PlacesContainers = {
    Headquarters: Symbol("Headquarters"),
    None: Symbol("None"),
}

function NewClient(props) {
    const [focused, setFocused] = useState(false)
    const [placesFound: any[], setPlacesFound] = useState([])
    const [showPlacesContainer, setShowPlacesContainer] = useState(PlacesContainers.None)

    useEffect(() => {
        props.client.companyName !== "" && props.client.phone !== "" && props.client.industry !== "" &&
        props.client.contactName !== "" && props.client.mail !== "" && props.client.headquarters.fullName !== "" &&
        props.client.headquarters.latitude !== undefined && props.client.headquarters.longitude !== undefined 
        ? props.setIsDisable(false) : props.setIsDisable(true);
    }, [props.client])

    useEffect(() => {
        fetchCoordenates();
    }, [props.client.headquarters])

    const fetchCoordenates = async () => {
        if (!props?.client?.headquarters?.fullName) return
        
        try {
            const {data} = await Axios.get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.client.headquarters.fullName}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
            );
    
            setPlacesFound(data.features)
        } catch (error) {
            console.log(error)
        }
    };

    const selectLogo = async (e) => {
        const allowedTypes = ["image/jpeg", "image/png"];
        const selectedFile = e.target.files[0];

        if (selectedFile && !allowedTypes.includes(selectedFile?.type)) {
            await Swal.fire({
                icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
            return;
        }

        props.setSelectedFile(selectedFile)
    };

    return (
        <section className="newClientSection">
            <Row>
                <Col lg={{span: 8, offset: 1}} xs={{span: 11, offset: 1}}>
                    <div className="inputContainer">
                        <span>Company name</span>
                        <input value={props.client.companyName}
                               onChange={(e) => props.setClient({...props.client, companyName: e.target.value})}
                               type="text"
                               placeholder="Company name"/>
                    </div>
                    <div className="inputContainer">
                        <span>Phone</span>
                        <input value={props.client.phone}
                               onChange={(e) => props.setClient({...props.client, phone: e.target.value})} type="text"
                               placeholder="Phone"/>
                    </div>
                    <div className="inputContainer">
                        <span>Industry</span>
                        <input value={props.client.industry}
                               onChange={(e) => props.setClient({...props.client, industry: e.target.value})}
                               type="text" placeholder="Industry"/>
                    </div>
                    <div className="inputContainer">
                        <span>Contact name</span>
                        <input value={props.client.contactName}
                               onChange={(e) => props.setClient({...props.client, contactName: e.target.value})}
                               type="text"
                               placeholder="Contact name"/>
                    </div>
                    <div className="inputContainer">
                        <span>Mail</span>
                        <input value={props.client.mail}
                               onChange={(e) => props.setClient({...props.client, mail: e.target.value})}
                               type="email"
                               placeholder="Mail"
                               onFocus={() => setFocused(!focused)} onBlur={() => setFocused(!focused)}/>
                        <span className={`noteUser ${focused ? "show" : ""}`}>
									<img src={info} alt="ProdensaAutomation"/>
									Enter your client's email address
								</span>
                    </div>
                    <div className="inputContainer position-relative">
                        <span>Headquarters</span>
                        <input value={props.client.headquarters.fullName}
                               type="text"
                               placeholder="Headquarters"
                               onBlur={() => setShowPlacesContainer(PlacesContainers.None)}
                               onChange={(e) => {
                                   if (e.target.value !== "")
                                       setShowPlacesContainer(PlacesContainers.Headquarters)
                                   else
                                       setShowPlacesContainer(PlacesContainers.None)

                                   props.setClient(
                                       {
                                           ...props.client,
                                           headquarters: {
                                               ...props.client.headquarters,
                                               fullName: e.target.value,
                                               city: "",
                                               state: "",
                                               country: "",
                                               latitude: undefined,
                                               longitude: undefined,
                                           }
                                       })
                               }}/>
                        <div className={`placesFoundContainer ${showPlacesContainer === PlacesContainers.Headquarters ?
                            "show" : ""}`}>
                            {placesFound.map((place, index) => {
                                return (
                                    <span key={`place_${index}`} className="placeFound"
                                          onClick={() => {
                                              const state = place.context.find(x => x.id.includes("region"))?.text;
                                              const country = place.context.find(x => x.id.includes("country"))?.text;

                                              props.setClient({
                                                  ...props.client,
                                                  headquarters: {
                                                      ...props.client.headquarters,
                                                      fullName: place.place_name,
                                                      city: place.text,
                                                      state: state ?? "",
                                                      country: country ?? "",
                                                      latitude: place.geometry.coordinates[1],
                                                      longitude: place.geometry.coordinates[0],
                                                  }
                                              });
                                              setShowPlacesContainer(PlacesContainers.None);
                                          }}>
                                        {place.place_name}</span>
                                )
                            })}
                        </div>
                    </div>
                    <div className="inputContainer">
                        <span>Notes</span>
                        <input value={props.client.notes}
                               onChange={(e) => props.setClient({...props.client, notes: e.target.value})}
                               type="text"
                               placeholder="Notes"/>
                    </div>
                    <div className="inputContainer">
                        <span>Logo</span>
                        <input type="file"
                               accept=".jpg, .png"
                               placeholder="Logo"
                               onChange={(e) => selectLogo(e)}/>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default NewClient;
