import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const LaborLawsuits = ({ details, city_names, filtered, reportID, variableCosts }) => {

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null)

    useEffect(() => {
        if (variableCosts) {
            //Inserting the years into an array to be able to map them
            let yearsRef = []
            variableCosts.forEach(city => {
                let years = Object.keys(city.dataVariables.lawsuit ?? []);
                if (years.length > yearsRef.length) {
                    yearsRef = years
                }
            })
            setYears(yearsRef);
            setLoading(false);
        }

    }, [])



    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <h1>Loading...</h1>
            ) : (variableCosts && years ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Individual labor lawsuits</h5>
                            {years.map((year) =>
                                <h5 className="side-text" key={`${year}-column`}>{year}</h5>
                            )}
                            <h5 className="first-spacer last" style={{ background: 'rgba(130, 135, 133,0.7)' }}>Average</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            {years.map((year) =>
                                <div className="row-number" key={`${year}-row`}>
                                    {city_names.map((city, index) => {
                                        let cityLawSuit = variableCosts.find(cityFind => city === cityFind.cityName);
                                        let cityLawSuitToShow = "N/A"
                                        if (cityLawSuit) {
                                            let accessedLawSuit = cityLawSuit.dataVariables?.lawsuit?.[year] ? cityLawSuit.dataVariables.lawsuit[year] : null;
                                            if (accessedLawSuit) {
                                                cityLawSuitToShow = formatNumber(accessedLawSuit)
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-${year}-value`}>{cityLawSuitToShow}</h5>
                                        )
                                    }

                                    )}
                                </div>
                            )}
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let cellClass = "";
                                    let textToShow = "N/A"
                                    let cityToShow = details.results.find(cityFind => city === cityFind.city)
                                    if(cityToShow){
                                        if(cityToShow.valueResult?.totalCost !== null){
                                            if(cityToShow.valueResult?.bestOption){
                                                cellClass = "highest"
                                            }
                                            textToShow = formatNumber(cityToShow.valueResult.totalCost)
                                        }
                                    }
                                    
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-average`}><span className={cellClass}>{textToShow}</span></h5>
                                    )
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Individual labor lawsuits</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {years.map((year) =>
                                    <tr key={`${year}-row`}>
                                        <td>{year}</td>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <td key={`${city}-${year}-value`}>
                                                {variableCosts.find(cityFind => city === cityFind.cityName).dataVariables?.lawsuit?.[year] ? formatNumber(variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.lawsuit[year]) : "N/A"}
                                            </td>
                                        )}
                                    </tr>
                                )}
                                <tr>
                                    <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Average</td>
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A"
                                        let cityToShow = details.results.find(cityFind => city === cityFind.city)
                                        if(cityToShow){
                                            if(cityToShow.valueResult?.totalCost !== null){
                                                if(cityToShow.valueResult?.bestOption){
                                                    cellClass = "highest"
                                                }
                                                textToShow = formatNumber(cityToShow.valueResult.totalCost)
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td className={`number ${cellClass}`} key={`${city}-average`}>{textToShow}</td>
                                        )
                                    })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            )
            )}

        </Container>
    )
}

export default LaborLawsuits;