import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";


const StatuoryIncentives = ({ details, city_names, filtered, variableCosts }) => {

    return (
        <Container className="generic-wrapper">
            {/*First table*/}
            {details ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3}>
                            <h5 className="first-spacer">Concept</h5>
                            <h5 className="side-text">Incentives</h5>
                            <h5 className="side-text">Description</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title"key={`${city}-${index}-title`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {details.results.map((city, index) =>
                                    {
                                        const incentives = variableCosts?.find(x => x.cityName === city.city)?.dataVariables?.governmentIncentives?.incentives
                                        return filtered.includes(index) &&
                                        <h5 className="number"key={`${city._id}-${index}-incentives`}>
                                            {incentives === "" ? "N/A" : incentives}
                                        </h5>
                                    }
                                )}
                            </div>
                            <div className="row-number">
                                {details.results.map((city, index) =>
                                    {
                                        const description = variableCosts?.find(x => x.cityName === city.city)?.dataVariables?.governmentIncentives?.description
                                        return filtered.includes(index) &&
                                        <h5 className="number"key={`${city._id}-${index}-description`}>
                                            {description === "" ? "N/A" : description}
                                        </h5>
                                    }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Incentives</td>
                                    {details.results.map((city, index) =>
                                        {
                                            const incentives = variableCosts?.find(x => x.cityName === city.city)?.dataVariables?.governmentIncentives?.incentives
                                            return filtered.includes(index) &&
                                            <td key={`${city._id}-mobile-incentives`}>
                                                {incentives === "" ? "N/A" : incentives}
                                            </td>
                                        }
                                    )}
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    {details.results.map((city, index) =>
                                        {
                                            const description = variableCosts?.find(x => x.cityName === city.city)?.dataVariables?.governmentIncentives?.description
                                            return filtered.includes(index) &&
                                            <td key={`${city._id}-mobile-description`}>
                                                {description === "" ? "N/A" : description}
                                            </td>
                                        }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            )}
        </Container>
    )
}

export default StatuoryIncentives;