import Axios from "axios"

import {useEffect, useState} from "react";
import "../../../assets/css/siteSelection/client.css"
import NewClient from "./newClient.jsx";
import {Col, Row} from "react-bootstrap";
import newClient from "../../../assets/images/personAdd.svg";
import existingClient from "../../../assets/images/menuBook.svg";
import ExistingClient from "./existingClient.jsx";
import {Client} from "../../../models/client.ts";
import { useCookies } from "react-cookie";
import { useAuth } from "../../../context/auth-context";
import { customAlert } from "../../../utils/customAlert.js";

function ClientSection(props) {
    const [selectedFile, setSelectedFile] = useState({})

    const { user } = useAuth()
    const [cookies] = useCookies(['user'])

    useEffect(() => {
        props.setFunctionActionButton(() => submitClient);
    }, [props.client, selectedFile, props.draft.activeMenu])

    const navigationSectionClick = (activeSection) => {
        if (!props.draft.siteSelectionReport.clientId && props.activeClientSection !== activeSection) {
            props.setActiveClientSection(activeSection);
            props.setClient(new Client());
        }
    }

    const submitClient = () => {
        if(props.draft._id !== 0) {
            // props.setLoading(true);
            // props.setIsDisable(true);


            let data
            if (props.client.logo && props.client.logo.filename !== "" && !selectedFile.name) {
                const { logo, ...restOfClient } = props.client
                data = {client: JSON.stringify(restOfClient)}

            } else {
                data = new FormData()
                if (selectedFile.name) {
                    data.append('logo', selectedFile)
                }
                data.append('client', JSON.stringify(props.client))
            }

            Axios.patch(process.env.REACT_APP_UPDATE_CLIENT + props.client._id, data).then(clientRes => {
                if(!clientRes.data.data) {
                    props.setIsDisabled(false)
                    customAlert(clientRes.data.message)
                    return
                }
                
                if (props.draft.siteSelectionReport.clientId !== props.client._id) {
                    const { _id, ...report } = {...props.draft}
                    report.siteSelectionReport.clientId = props.client._id

                    Axios.patch(process.env.REACT_APP_UPDATE_REPORT + _id, report).then(reportRes => {
                        props.setDraft(prevState => {
                            return {
                                ...prevState,
                                siteSelectionReport: {
                                    ...prevState.siteSelectionReport,
                                    clientId: props.client._id,
                                }
                            }
                        })

                        props.setLoading(false);
                        props.setIsDisable(false);
                        props.setActiveMenu(1);
                        props.setActiveClientSection(1);
                    }).catch(err => {
                        // props.setLoading(false);
                        props.setIsDisable(false);
                        customAlert(err.response?.data?.message || err.response?.statusText)

                        console.log(err)
                    })
                }
                
                props.setLoading(false);
                props.setIsDisable(false);
                props.setActiveMenu(1);
                props.setActiveClientSection(1);
            }).catch(err => {
                // props.setLoading(false);
                props.setIsDisable(false);
                customAlert(err.response?.data?.message || err.response?.statusText)

                console.log(err)
            })
        } else {
            if(props.activeClientSection === 0) {
                props.setLoading(true);
                props.setIsDisable(true);
                let data = new FormData()
                
                if(selectedFile.name) {
                    data.append('logo', selectedFile)
                }
                data.append('client', JSON.stringify(props.client))
                
                Axios.post(process.env.REACT_APP_CREATE_CLIENT, data).then(clientRes => {
                    const { _id, ...report } = {...props.draft}
                    report.reportName = clientRes.data.companyName.substring(0, 3).toUpperCase() + "-CESS"
                    // report.reportType = "Site Selection"
                    report.siteSelectionReport.type = "Site Selection"
                    report.siteSelectionReport.profileUserId = user._id
                    report.siteSelectionReport.clientId = clientRes.data._id
    
                    Axios.post(process.env.REACT_APP_CREATE_REPORT, report).then(reportRes => {
                        
                        Axios.post(process.env.REACT_APP_CREATE_VARIABLE_COST, {
                            reportId: reportRes.data._id,
                        }).then(variableCostRes => {
                            const pdfConfig = {
                                reportId: reportRes.data._id,
                                clientId: clientRes.data._id
                            }

                            Axios.post(process.env.REACT_APP_GET_PDF_CONFIG, pdfConfig).then(pdfConfigRes => {
                                props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        _id: reportRes.data._id,
                                        reportName: reportRes.data.reportName,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            profileUserId: reportRes.data.siteSelectionReport.profileUserId,
                                            clientId: clientRes.data._id,
                                            percentage: reportRes.data.siteSelectionReport.percentage,
                                            status: reportRes.data.siteSelectionReport.status,
                                        }
                                    }
                                })
                                props.setVariableCost(variableCostRes.data)
                                props.setLoading(false);
                                props.setIsDisable(false);
                                props.setActiveMenu(1);
                                props.setActiveClientSection(1);
                                props.setPdfConfig({
                                    ...pdfConfigRes.data,
                                    reportID: reportRes.data._id
                                })
                            }).catch(err => {
                                console.log(err)
                                // props.setLoading(false);
                                props.setIsDisable(false);
                                customAlert(err.response?.data?.message || err.response?.statusText)
                            })
                            
                        }).catch(err => {
                            console.log(err)
                            // props.setLoading(false);
                            props.setIsDisable(false);
                            customAlert(err.response?.data?.message || err.response?.statusText)
                        })
                    }).catch(err => {
                        console.log(err)
                        // props.setLoading(false);
                        props.setIsDisable(false);
                        customAlert(err.response?.data?.message || err.response?.statusText)
                    })
                }).catch(err => {
                    // props.setLoading(false);
                    props.setIsDisable(false);
                    customAlert(err.response?.data?.message || err.response?.statusText)
                    console.log(err)
                })
            } else { //activeClientSection === 1
                props.setLoading(true);
                props.setIsDisable(true);
    
    
                let data
                if (props.client.logo && props.client.logo.filename !== "" && !selectedFile.name) {
                    const { logo, ...restOfClient } = props.client
                    data = {client: JSON.stringify(restOfClient)}
    
                } else {
                    data = new FormData()
                    if (selectedFile.name) {
                        console.log("new client logo", selectedFile)
                        data.append('logo', selectedFile)
                    }
                    data.append('client', JSON.stringify(props.client))
                }
    
                Axios.patch(process.env.REACT_APP_UPDATE_CLIENT + props.client._id, data).then(clientRes => {
                    if (!clientRes.data.data) {
                        props.setIsDisabled(false)
                        customAlert(clientRes.data.message)
                        return
                    }
                    
                    const { _id, ...report } = {...props.draft}
                    report.reportName = clientRes.data.data.companyName.substring(0, 3).toUpperCase() + "-CESS"
                    // report.reportType = "Site Selection"
                    report.siteSelectionReport.type = "Site Selection"
                    report.siteSelectionReport.profileUserId = user._id
                    report.siteSelectionReport.clientId = clientRes.data.data._id
                    
                    Axios.post(process.env.REACT_APP_CREATE_REPORT, report).then(reportRes => {
                        Axios.post(process.env.REACT_APP_CREATE_VARIABLE_COST, {
                            reportId: reportRes.data._id,
                        }).then(variableCostRes => {
                            const pdfConfig = {
                                reportId: reportRes.data._id,
                                clientId: clientRes.data.data._id
                            }
    
                            Axios.post(process.env.REACT_APP_GET_PDF_CONFIG, pdfConfig).then(pdfConfigRes => {
                                props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        _id: reportRes.data._id,
                                        reportName: reportRes.data.reportName,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            profileUserId: reportRes.data.siteSelectionReport.profileUserId,
                                            clientId: props.client._id,
                                            percentage: reportRes.data.siteSelectionReport.percentage,
                                            status: reportRes.data.siteSelectionReport.status,
                                        }
                                    }
                                })
        
                                props.setVariableCost(variableCostRes.data)
                                props.setLoading(false);
                                props.setIsDisable(false);
                                props.setActiveMenu(1);
                                props.setActiveClientSection(1);
                                props.setPdfConfig({
                                    ...pdfConfigRes.data,
                                    reportID: reportRes.data._id
                                })
                            }).catch(err => {
                                console.log(err)
                                // props.setLoading(false);
                                props.setIsDisable(false);
                                customAlert(err.response?.data?.message || err.response?.statusText)
                            })
                            
                        }).catch(err => {
                            console.log(err)
                            // props.setLoading(false);
                            props.setIsDisable(false);
                            customAlert(err.response?.data?.message || err.response?.statusText)
                        })
                    }).catch(err => {
                        // props.setLoading(false);
                        props.setIsDisable(false);
                        customAlert(err.response?.data?.message || err.response?.statusText)
                        console.log(err)
                    })
                }).catch(err => {
                    // props.setLoading(false);
                    props.setIsDisable(false);
                    customAlert(err.response?.data?.message || err.response?.statusText)
                    console.log(err)
                })
            }
        }
    }

    return (
        <section className="clientSection">
            <Row>
                <Col className="containerNavigation" lg={{span: 8, offset: 1}} xs={{span: 11, offset: 1}}>
                    {!props.draft.siteSelectionReport.clientId && <button className={`btnNavigation ${props.activeClientSection === 0 ? "active" : ""}`}
                            onClick={() => navigationSectionClick(0)}
                            disabled={props.draft.siteSelectionReport.client != null ? "disabled" : ""}>
                        <img src={newClient} alt="Prodensa automation"/>
                        <span>New client</span>
                    </button>}
                    <button className={`btnNavigation ${props.activeClientSection === 1 ? "active" : ""}`}
                            onClick={() => navigationSectionClick(1)}>
                        <img src={existingClient} alt="Prodensa automation"/>
                        <span>Existing client</span>
                    </button>
                </Col>
            </Row>
            <Row>
                {!props.draft.siteSelectionReport.clientId ?
                    <Col lg={{span: 8, offset: 1}} xs={{span: 11, offset: 1}}>
                        <div className="containerNavigationMark">
                            <div className={`selectedMark ${props.activeClientSection === 1 ? "move" : ""}`}></div>
                        </div>
                    </Col> : <Col lg={{span: 8, offset: 1}} xs={{span: 11, offset: 1}}>
                        <div className="containerNavigationMark" style={{width: "188px"}}>
                            <div className={`selectedMark`} style={{width: "188px"}}></div>
                        </div>
                    </Col>
                }
            </Row>
            {
                props.activeClientSection === 0 ?
                    <NewClient 
                        client={props.client}
                        setClient={props.setClient}
                        setIsDisable={props.setIsDisable}
                        setSelectedFile={setSelectedFile} 
                    /> :
                    <ExistingClient 
                        clients={props.allClients}
                        client={props.client}
                        setClient={props.setClient}
                        setIsDisable={props.setIsDisable}
                        setSelectedFile={setSelectedFile} 
                    />
            }

        </section>
    )
}

export default ClientSection;
