import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";

const InsecurityPerception = ({
  details,
  city_names,
  filtered,
  musts,
  assumptions,
  variableCosts,
}) => {
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState(null);

  useEffect(() => {
    if (musts && assumptions && variableCosts) {
      let yearsRef = []
      musts.forEach(city => {
        const years = Object.keys(city.security.security_perception?.seguro ?? [])
        if (years.length > yearsRef.length) {
          yearsRef = years;
        }
      })
      setYears(yearsRef);
      setLoading(false);
    }
  }, []);

  return (
    <Container className="generic-wrapper">
      {/*First table*/}
      {loading ? (
        <div>Loading...</div>
      ) : musts && assumptions && variableCosts ? (
        <>
          <Row className="no-gutters d-none d-lg-flex">
            <Col lg={3}>
              <h5 className="first-spacer"></h5>
              <h5
                className="first-spacer"
                style={{ background: "rgba(0,0,0,0.1)" }}
              >
                State
              </h5>
              {years.map((year) => (
                <h5 className="side-text" key={`${year}-column`}>{year}</h5>
              ))}
              <h5
                className="first-spacer last"
                style={{ background: "rgba(0,0,0,0.1)" }}
              >
                Average (last 5 years)
              </h5>
            </Col>
            <Col lg={9} className="table-container">
              <div className="table-header">
                {city_names.map(
                  (city, index) =>
                    filtered.includes(index) && (
                      <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                    )
                )}
              </div>
              <div className="row-number">
                {musts
                .sort((a, b) => a.municipality_name.localeCompare(b.municipality_name))
                .map(
                  (state, index) =>
                    filtered.includes(index) && (
                      <h5 className="number" key={`${state.datamexico_municipality_id}-state-name`}>{state.state_name}</h5>
                    )
                )}
              </div>
              {years.map((year) => (
                <div className="row-number" key={`${year}-column2`}>
                  {musts.sort((a, b) => a.municipality_name.localeCompare(b.municipality_name)).map(
                    (city, index) => {
                      let securityPerceptionAnswers = city.security.security_perception
                      const noData = !securityPerceptionAnswers?.seguro?.[year] && !securityPerceptionAnswers?.inseguro?.[year] && !securityPerceptionAnswers?.no_sabe_o_no_responde?.[year]
                      let totalAnswers = (securityPerceptionAnswers?.seguro?.[year] ?? 0) + (securityPerceptionAnswers?.inseguro?.[year] ?? 0) + (securityPerceptionAnswers?.no_sabe_o_no_responde?.[year] ?? 0);
                      let percentageSecure = (securityPerceptionAnswers?.seguro?.[year] / totalAnswers) * 100;
                      let percentageToShow = noData ? "N/A" : formatNumber(percentageSecure);
                      return filtered.includes(index) && (
                        <h5 className="number" key={`${city.datamexico_municipality_id}-${year}-value`}>{percentageToShow}</h5>
                      )
                    })}
                </div>
              ))}
              <div className="row-number">
                {city_names.map((avg, index) => {
                  let cellClass = "";
                  let avgToRender = details.results.find(result => result.city == avg);
                  let avgToShow = "N/A"
                  if (avgToRender) {
                    avgToShow = avgToRender.valueResult?.totalCost !== null ? formatNumber(avgToRender.valueResult.totalCost) : "N/A";
                    if (avgToRender.valueResult?.bestOption) {
                      cellClass += " lowest";
                    }
                  }
                  return (
                    filtered.includes(index) && (
                      <h5 className="number" key={`${avg}-average-value`}>
                        <span className={cellClass}>{avgToShow}</span>
                      </h5>
                    )
                  );
                })}
              </div>
            </Col>
          </Row>

          <section className="mobile-details-results-table d-lg-none">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {city_names.map(
                    (city, index) =>
                      filtered.includes(index) && (
                        <th key={`${city}-mobile-header`}>{city}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{background: "rgba(187, 189, 191, 0.7)"}}>State</td>
                  {musts.map(
                    (state, index) =>
                      filtered.includes(index) && (
                        <td key={`${state.datamexico_municipality_id}-mobile-state-name`}>{state.state_name}</td>
                      )
                  )}
                </tr>
                {years.map((year) => (
                  <tr key={`${year}-column`}>
                    <td>{year}</td>
                    {musts.map(
                      (city, index) => {
                        let securityPerceptionAnswers = city.security.security_perception
                        const noData = !securityPerceptionAnswers?.seguro?.[year] && !securityPerceptionAnswers?.inseguro?.[year] && !securityPerceptionAnswers?.no_sabe_o_no_responde?.[year]
                        let totalAnswers = (securityPerceptionAnswers?.seguro?.[year] ?? 0) + (securityPerceptionAnswers?.inseguro?.[year] ?? 0) + (securityPerceptionAnswers?.no_sabe_o_no_responde?.[year] ?? 0);
                        let percentageSecure = (securityPerceptionAnswers?.seguro?.[year] / totalAnswers) * 100;
                        let percentageToShow = noData ? "N/A" : formatNumber(percentageSecure);
                        return filtered.includes(index) && (
                          <td key={`${city.datamexico_municipality_id}-${year}-mobile-value`}>{percentageToShow}</td>
                        )
                      })}
                  </tr>
                ))}
                <tr>
                  <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Average (last 5 years)</td>
                  {city_names.map((avg, index) => {
                    let cellClass = "";
                    let avgToRender = details?.results?.find(result => result.city == avg);
                    let avgToShow = "N/A"
                    if (avgToRender) {
                      avgToShow = avgToRender.valueResult?.totalCost !== null ? formatNumber(avgToRender.valueResult.totalCost) : "N/A";
                      if (avgToRender.valueResult?.bestOption) {
                        cellClass += " lowest";
                      }
                    }
                    return (
                      filtered.includes(index) && (
                        <td className={cellClass} key={`${avg}-mobile-average-value`}>
                          {avgToShow}
                        </td>
                      )
                    );
                  }
                  )}
                </tr>
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div className="no-record-wrapper">
          <h3 className="no-record-title">There has been an issue retrieving data.</h3>
          <img src={infodanger} width="50px" height="50px" />
        </div > 
      )}
    </Container>
  );
};

export default InsecurityPerception;
