import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const  HomeTheft = ({ details, city_names, filtered, reportID, musts, variableCosts }) => {

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null)

    useEffect(() => {
        if (musts && variableCosts) {
            let yearsRef = []
            musts.forEach(city => {
                const years = Object.keys(city.security.home_theft.home_theft_by_year ?? [])
                if (years.length > yearsRef.length) {
                    yearsRef = years;
                }
            })
            setYears(yearsRef);
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ?
                (
                    <h1>Loading...</h1>
                ) : (musts && variableCosts && years ? (
                    <>
                        <Row className="no-gutters d-none d-lg-flex">
                            <Col lg={3}>
                                <h5 className="first-spacer"></h5>
                                <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>State</h5>
                                {years.map((year) =>
                                    <h5 className="side-text" key={`${year}-column`}>{year}</h5>
                                )}
                                <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>Average Home Theft/Year(Last 4 years)</h5>
                                {/* <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>100,000</h5> */}
                                <h5 className="first-spacer last" style={{ background: 'rgba(0,0,0,0.1)' }}>Ratio per 100,000</h5>
                            </Col>
                            <Col lg={9} className="table-container">
                                <div className="table-header">
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                    )}
                                </div>
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                        if (cityFind) {
                                            textToShow = cityFind.state_name;
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-state-name`}>{textToShow}</h5>
                                        )
                                    })}
                                </div>
                                {years.map((year) =>
                                    <div className="row-number" key={`${year}-column2`}>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                            if (cityFind.security.home_theft?.home_theft_by_year?.[year]) {
                                                textToShow = formatNumber(cityFind.security.home_theft.home_theft_by_year[year])
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <h5 className="number" key={`${city}-${year}-value`}>{textToShow}</h5>
                                            )
                                        })}
                                    </div>
                                )}
                                <div className="row-number">
                                    {city_names.map((avg, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = variableCosts.find((cityFind) => cityFind.cityName === avg);
                                        if (cityFind) {
                                            textToShow = cityFind.dataVariables?.homeTheft?.avgHomeTheft !== null ? formatNumber(cityFind.dataVariables.homeTheft.avgHomeTheft) : "N/A";
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${avg}-average-value`}>{textToShow}</h5>
                                        )
                                    })}
                                </div>
                                {/* <div className="row-number">
                            {details.rat.map((rat, index) =>
                                filtered.includes(index) &&
                                <h5 className="number">{rat}</h5>
                            )}
                        </div> */}
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = details.results.find((cityFind) => cityFind.city === city);
                                        let cellClass = "";
                                        if (cityFind) {
                                            if (cityFind.valueResult?.totalCost !== null) {
                                                if (cityFind.valueResult?.bestOption) {
                                                    cellClass += " lowest";
                                                }
                                                textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-ratio100k-value`}><span className={cellClass}>{textToShow}</span></h5>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>

                        <section className="mobile-details-results-table d-lg-none">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-mobile-header`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>State</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                            if (cityFind) {
                                                textToShow = cityFind.state_name;
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td key={`${city}-mobile-state-name`}>{textToShow}</td>
                                            )
                                        })}
                                    </tr>
                                    {years.map((year) =>
                                        <tr key={`${year}-mobile-column2`}>
                                            <td>{year}</td>
                                            {city_names.map((city, index) => {
                                                let textToShow = "N/A";
                                                let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                                if (cityFind.security.home_theft?.home_theft_by_year?.[year]) {
                                                    textToShow = formatNumber(cityFind.security.home_theft.home_theft_by_year[year])
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`${city}-${year}-mobile-value`}>{textToShow}</td>
                                                )
                                            })}
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Average Home Theft/Year(Last 4 years)</td>
                                        {city_names.map((avg, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = variableCosts.find((cityFind) => cityFind.cityName === avg);
                                            if (cityFind) {
                                                textToShow = cityFind.dataVariables?.homeTheft?.avgHomeTheft !== null ? formatNumber(cityFind.dataVariables.homeTheft.avgHomeTheft) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td key={`${avg}-average-mobile-value`}>{textToShow}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Ratio per 100,000</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = details.results.find((cityFind) => city === city);
                                            let cellClass = "";
                                            if (cityFind) {
                                                if (cityFind.valueResult?.totalCost !== null) {
                                                    if (cityFind.valueResult?.bestOption) {
                                                        cellClass += " lowest";
                                                    }
                                                    textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                                }
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td className={cellClass} key={`${city}-ratio100k-mobile-value`}>{textToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                )

                )}


        </Container >
    )
}

export default HomeTheft;