import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const StrategicDriver = ({ details, city_names, filtered, reportID, variableCosts }) => {
    //Defines an array containing all of the properties to render to avoid excessive code.
    const propertyNames = [
        "unionProBusinessCulture",
        "radicalTakeoverRisks",
        "laborKnowledgeLaborLaw",
        "employersLeadershipPerception",
        "productivityOrientedCulture",
        "HRExecutivesFaceChallenges",
        "managementOrientation",
        "proBusinessSocialEnvironment"
    ]

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (variableCosts) {
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (<h1>Loading...</h1>) : (
                variableCosts ? (
                    <>
                        <Row className="no-gutters d-none d-lg-flex">
                            <Col lg={3} className="fixed-container">
                                <h5 className="first-spacer">Strategic driver</h5>
                                <h5 className="first-spacer" style={{ background: 'rgba(130, 135, 133,0.7)' }}>Total</h5>
                                <h5 className="side-text">Union pro-business culture</h5>
                                <h5 className="side-text">Radical union/new union takeover risks</h5>
                                <h5 className="side-text">Labor knowledge of the labor law</h5>
                                <h5 className="side-text">Employers leadership perception</h5>
                                <h5 className="side-text">Productivity oriented culture</h5>
                                <h5 className="side-text">HR executives labor experience/readiness to face new challenges</h5>
                                <h5 className="side-text">Management orientation to people culture</h5>
                                <h5 className="side-text">Pro-business social environment</h5>
                            </Col>
                            <Col lg={9} className="table-container">
                                <div className="table-header">
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                    )}
                                </div>
                                <div className="table-header">
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = details.results.find(cityFind => city === cityFind.city);
                                        if (cityFind && cityFind.valueResult && cityFind.valueResult.totalCost !== null) {
                                            textToShow = formatNumber(cityFind.valueResult.totalCost);
                                        }
                                        return (
                                            filtered.includes(index) && (
                                                <h5
                                                    key={`${city}-total`}
                                                    className="city-title"
                                                    style={{ background: 'transparent' }}
                                                >
                                                    {textToShow}
                                                </h5>
                                            )
                                        );
                                    })}
                                </div>
                                {propertyNames.map((property) => (
                                    <div className="row-number" key={property}>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = variableCosts.find(cityFind => city === cityFind.cityName);
                                            if (cityFind) {
                                                if (cityFind.dataVariables?.unionEnvironment?.[property]) {
                                                    textToShow = formatNumber(cityFind.dataVariables.unionEnvironment[property]);
                                                }
                                            }
                                            if (filtered.includes(index)) {
                                                return (
                                                    <h5 className="number" key={`${city}-row`}>
                                                        {textToShow}
                                                    </h5>
                                                );
                                            }
                                            return null; // Ensures a value is always returned from map function
                                        })}
                                    </div>
                                ))}

                            </Col>
                        </Row>

                        <section className="mobile-details-results-table d-lg-none">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Strategic driver</th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-mobile-header`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Total</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = details?.results?.find(cityFind => city === cityFind.city);
                                            if (cityFind && cityFind.valueResult && cityFind.valueResult.totalCost !== null) {
                                                textToShow = formatNumber(cityFind.valueResult.totalCost);
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td key={`${city}-mobile-result`}>{textToShow}</td>
                                            );
                                        })
                                        }
                                    </tr>
                                    {propertyNames.map((property) => (
                                        <tr key={`${property}-mobile`}>
                                            <td>{property}</td>
                                            {city_names.map((city, index) => {
                                                let textToShow = "N/A";
                                                let cityFind = variableCosts.find(cityFind => city === cityFind.cityName);
                                                if (cityFind) {
                                                    if (cityFind.dataVariables?.unionEnvironment?.[property]) {
                                                        textToShow = formatNumber(cityFind.dataVariables.unionEnvironment[property]);
                                                    }
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`${city}-mobile-result`}>{textToShow}</td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                )
            )}

        </Container>
    )
}

export default StrategicDriver;