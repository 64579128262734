import "../../assets/css/reportResults/reportResultsFooter.css";

export default function reportResultsFooter(props) {
  
  return (
    <footer className={`d-lg-none results-Footer ${props.token ? "client" : ""}`}>
      <section className={`footer-section`} onClick={props.handleGoBackClick}>
        <span id="goBack" className="title">
          Go back
        </span>
      </section>

      {props.token && <section className={`footer-section ${props.activebutton === "edit" ? "save" : ""}`} 
        onClick={props.handleEditSaveClick}
      >
        <span id="edit-save" className="title">
          {props.activebutton === "edit" ? 
            (props.typeOfResult === "generate" ? "Preview changes" : "Save changes")
            : "Edit mode"
          }
        </span>
      </section>}

      <section className={`footer-section ${props.activebutton === "map" ? "active" : ""}`}
        onClick={props.handleMapClick}
      >
        <span id="map" className="title">
          Map view
        </span>
      </section>

      <section className={`footer-section ${props.activebutton === "wants" ? "active" : ""}`}
        onClick={props.handleWantsClick}
      >
        <span id="wants" className="title">
          Wants view
        </span>
      </section>
    </footer>
  )
}