import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";

const OutboundTrucks = ({
  details,
  city_names,
  filtered,
  musts,
  assumptions,
  variableCosts,
}) => {
  const [loading, setLoading] = useState(true);
  const [firstDestination, setFirstDestination] = useState(null);
  const [accessKey, setAccessKey] = useState(null);

  useEffect(() => {
    if (musts && assumptions && variableCosts) {

      let accessKey = "";
      switch (details?.variableIdRef?.toString()) {
        case "65cd42c0f955fa725381e7f9":
          accessKey = "distanceToClient";
          break;
        case "65cd42baf955fa725381e7f8":
          accessKey = "distanceToSeaport";
          break;
        case "65cd42b5f955fa725381e7f7":
          accessKey = "distanceToSupplier";
          break;
      }
      setAccessKey(accessKey);
      let firstDestination = variableCosts[0].dataVariables?.[accessKey];
      setFirstDestination(firstDestination);
      setLoading(false);
    }
  }, []);

  return (
    <Container className="generic-wrapper">
      {/*First table*/}
      {loading ? (
        <div>Loading... </div>
      ) : musts && assumptions && variableCosts ? (
        <>
          <h4 className="table-title">Outbound</h4>
          <Row className="no-gutters">
            <Col lg={6}>
              <h5 className="first-spacer">Origin</h5>
              {firstDestination.map((destination, index) => (
                <div className="side-text" style={{ display: "flex" }} key={`${destination?.destiny}-${index}-ref`}>
                  {city_names.map((origin, index) => (
                    <h5 key={`${origin}-${index}-column`}>
                      {origin.replace(" Metroplex", "")}
                      {index !== city_names.length - 1 && ',\u00A0'}
                    </h5>
                  ))}
                </div>
              )
              )}
            </Col>
            <Col lg={2}>
              <h5 className="first-spacer">Destination</h5>
              {firstDestination.map((destination) => (
                <h5 className="side-text" key={`${destination?.destiny}-destination-column`}>{destination?.destiny}</h5>
              ))}
            </Col>
            <Col lg={2}>
              <h5 className="first-spacer">Trucks per week</h5>
              {firstDestination.map((destination) => {
                let trucksMonth = destination?.quantity;
                let trucksPerWeekUnRounded = (trucksMonth * 12) / 52;
                let trucksPerWeek = Math.round(trucksPerWeekUnRounded);
                return (
                  <h5 className="side-text" key={`${destination?.destiny}-trucks`}>{trucksPerWeek}</h5>
                );
              }
              )}
            </Col>
            <Col lg={2}>
              <h5 className="first-spacer">Trucks per month</h5>
              {firstDestination.map((destination) => (
                <h5 className="side-text" key={`${destination?.destiny}-quantity`}>{destination?.quantity}</h5>
              ))}
            </Col>
          </Row>
          <h4 className="table-title">Time in truck hours</h4>
          <Row className="no-gutters">
            <Col lg={3} className="fixed-container">

              <h5 className="first-spacer">Destination</h5>
              {firstDestination.map((destination) => (
                <h5 className="side-text" key={`${destination?.destiny}-header`}>{destination?.destiny}</h5>
              ))}
              <h5
                className="side-text"
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Total
              </h5>
            </Col>
            <Col lg={9} className="table-container">
              <div className="table-header">
              </div>
              <div className="table-header">
                {city_names.map(
                  (city, index) =>
                    filtered.includes(index) && (
                      <h5 className="city-title" key={`${city}-headers`}>{city}</h5>
                    )
                )}
              </div>
              {firstDestination.map((destination, destinationIndex) => (
                <div className="row-number" key={`${destination?.destiny}-section`}>
                  {city_names.map(
                    (cityHour, index) => {
                      let truckHourToShow = "N/A";
                      let destinationToRender = variableCosts.find(city => city.cityName == cityHour || city.cityName == cityHour.replace(" Metroplex", ""));
                      if (destinationToRender) {
                        let destinationFind = destinationToRender?.dataVariables?.[accessKey].find(destinationFind => destinationFind.destiny == destination.destiny);
                        let truckHourTotal = destinationFind ? formatNumber(destinationFind.total) : "N/A";
                        truckHourToShow = truckHourTotal;
                      }
                      return filtered.includes(index) &&
                        <h5 className="number" key={`${cityHour}-${destination?.destiny}-${destinationIndex}-hours`}>{truckHourToShow}</h5>

                    })}
                </div>
              ))}
              <div className="row-number">
                {city_names.map(
                  (cityTotal, index) => {
                    let totalTrucksFinal = "N/A";
                    let cityToRender = details?.results?.find(city => city.city == cityTotal || city.cityName == cityTotal.replace(" Metroplex", ""));
                    if (cityToRender) {
                      let totalFinal = cityToRender.valueResult?.totalCost !== null ? formatNumber(cityToRender.valueResult.totalCost) : "N/A";
                      totalTrucksFinal = totalFinal;
                    }
                    return filtered.includes(index) &&
                      <h5 className="number" key={`${cityTotal}-hours-total`}>{totalTrucksFinal}</h5>

                  })}
              </div>
              <div className="row-number">
                {city_names.map(
                  (cityTotal, index) => {
                    let percentageToShow = "N/A";
                    let addendum = "";
                    let cityToRender = details?.results?.find(city => city.city == cityTotal || city.cityName == cityTotal.replace(" Metroplex", ""));
                    if (cityToRender) {
                      if (cityToRender.valueResult?.bestOption) {
                        percentageToShow = "Lowest Value";
                      } else {
                        if (cityToRender.valueResult?.typeOfDifference == "Percentage") {
                          addendum = " %";
                        }
                        let percentage = cityToRender.valueResult?.difference !== null ? cityToRender.valueResult.difference.toFixed(2) : "N/A";
                        percentageToShow = percentage;
                        percentageToShow += addendum;
                      }

                    }
                    return filtered.includes(index) &&
                      <h5 className="number red" key={`${cityTotal}-difference`}>{percentageToShow}</h5>

                  })}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <div className="no-record-wrapper">
          <h3 className="no-record-title">There has been an issue retrieving data.</h3>
          <img src={infodanger} width="50px" height="50px" />
        </div >
      )}
    </Container>
  );
};

export default OutboundTrucks;
