import "../../../assets/css/siteSelection/wantsMobile.css"
import "../../../assets/css/siteSelection/wantsMobileVarSettings.css"

import { useState } from 'react';
import { Accordion, DropdownButton, Dropdown } from 'react-bootstrap';

import imgOperatingCost from "../../../assets/images/operatingCost.svg"
import imgCompetitionLabor from "../../../assets/images/competitionLabor.svg"
import imgEnviromentLabor from "../../../assets/images/enviromentLabor.svg"
import imgLogistics from "../../../assets/images/logistics.svg"
import imgInfrastructure from "../../../assets/images/infrastructure.svg"
import imgGovermentIncetives from "../../../assets/images/govermentIncentives.svg"
import imgSecurity from "../../../assets/images/security.svg"
import imgQualityLife from "../../../assets/images/qualityLife.svg"

import info from "../../../assets/images/infoBlue.svg"
import infoIconDanger from "../../../assets/images/infoDanger.svg"
import imgDefinition from "../../../assets/images/definitionIcon.svg"
import imgMetric from "../../../assets/images/metricIcon.svg"
import imgSource from "../../../assets/images/sourceIcon.svg"
import imgSettings from "../../../assets/images/settings.svg"
import imgDelete from "../../../assets/images/delete.svg"
import close from "../../../assets/images/close.svg"

const ICONS = {
    "65cc0a7810d19bdcf598a2c8": imgOperatingCost,
    "65cc0a7810d19bdcf598a2c9": imgCompetitionLabor,
    "65cc0a7810d19bdcf598a2ca": imgEnviromentLabor,
    "65cc0a7810d19bdcf598a2cb": imgLogistics,
    "65cc0a7810d19bdcf598a2cc": imgInfrastructure,
    "65cc0a7810d19bdcf598a2cd": imgGovermentIncetives,
    "65cc0a7810d19bdcf598a2ce": imgSecurity,
    "65cc0a7810d19bdcf598a2cf": imgQualityLife
}

const PAYROLL_ID = "65cd417ff955fa725381e7e7"
const LOGISTIC_COSTS_ID = "65cd419ff955fa725381e7e8"

const MANUAL_CRITERIA = [
    "65cd42daf955fa725381e7fc",
    "65cd42f3f955fa725381e7fe",
    "65cd42ecf955fa725381e7fd"
]

function CategoryHeader({ children, icon, category, updateWeightTab }) {
    return (
        <>
            <div className="mobile-catTitleSection">
                <img src={icon} alt="Prodensa Automation" />
                <h5>{children}</h5>
            </div>
            <div className="mobile-inputContainer">
                <input
                    type="number"
                    value={category.weight}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => updateWeightTab(category.catIdRef, +e.target.value)}
                />
                <div className="numberSelector"
                    onClick={(e) => {
                        e.stopPropagation()
                        updateWeightTab(category.catIdRef, category.weight + 1)
                    }}
                />
                <div className="numberSelector"
                    onClick={(e) => {
                        e.stopPropagation()
                        updateWeightTab(category.catIdRef, category.weight - 1)
                    }}
                />
            </div>
        </>
    )
}

function Variable(props) {

    const landContract = props.draft.siteSelectionReport.assumption.land.landContract.toLowerCase() ?
        props.draft.siteSelectionReport.assumption.land.landContract.toLowerCase()
        :
        "land"

    const varRefTitle = props.varRef._id?.toString() === "65cd41c4f955fa725381e7ec" ?
        `Average ${landContract} cost`
        :
        props.varRef.title

    return (
        <section className="mobile-var-section">
            <div className="mobile-var-title-info">
                <img ref={props.menuInformationRef} src={info}
                    onClick={(e) => props.menuInformationRef.current = e.target}
                    alt="Prodensa Automation"
                />
                <div className="informationContainer" onClick={(e) => e.stopPropagation()}>
                    <h5>Information</h5>
                    <div className="rowInfo">
                        <img src={imgDefinition} alt="Prodensa Automation" />
                        <div className="w-100">
                            <h5 className="title">Definition</h5>
                            <textarea rows={3} className="description"
                                value={props.variable.information.definition}
                                onChange={(e) => props.updateVariableInformation(props.category.catIdRef, props.variable, props.varIndex, "definition", e.target.value)} />
                        </div>
                    </div>
                    <div className="rowInfo">
                        <img src={imgMetric} alt="Prodensa Automation" />
                        <div className="w-100">
                            <h5 className="title">Metric</h5>
                            <textarea rows={1.5} className="description"
                                value={props.variable.information.metric}
                                onChange={(e) => props.updateVariableInformation(props.category.catIdRef, props.variable, props.varIndex, "metric", e.target.value)} />
                        </div>
                    </div>
                    <div className="rowInfo">
                        <img src={imgSource} alt="Prodensa Automation" />
                        <div className="w-100">
                            <h5 className="title">Source</h5>
                            <textarea rows={1.5} className="description"
                                value={props.variable.information.source}
                                onChange={(e) => props.updateVariableInformation(props.category.catIdRef, props.variable, props.varIndex, "source", e.target.value)} />
                        </div>
                    </div>
                </div>


                <div className="mobile-var-title">
                    {!props.variable.addedVariable && <h5>{varRefTitle}</h5>}
                    {props.variable.addedVariable && <input type="text" value={props.variable.title}
                        onChange={(e) => props.changeTitleVariable(props.category.catIdRef, props.varIndex, e.target.value)} />}
                    <button onClick={() => {
                        if (MANUAL_CRITERIA.includes(props.variable.varIdRef?.toString())) {
                            props.initializeManualCriteriaModel(props.category.catIdRef, props.varIndex, props.variable, props.varRef)
                        } else {
                            props.initializeCriteriaModel(props.category.catIdRef, props.varIndex, props.variable, props.varRef)
                        }
                    }}>
                        Criteria
                    </button>
                </div>
            </div>
            <div className="mobile-var-weight">
                <span>{props.varPoints}</span>
                <div className="mobile-inputContainer">
                    <input
                        type='number'
                        value={props.variable.weight}
                        onChange={(e) => props.updateWeightVariable(props.category.catIdRef, props.variable, props.varIndex, +e.target.value)}
                    />
                    <div className="numberSelector"
                        onClick={() => props.updateWeightVariable(props.category.catIdRef, props.variable, props.varIndex, props.variable.weight + 1)}
                    />
                    <div className="numberSelector"
                        onClick={() => props.updateWeightVariable(props.category.catIdRef, props.variable, props.varIndex, props.variable.weight - 1)}
                    />
                </div>
                {(props.variable.addedVariable || props.variable.moreOptions) &&
                    <img className="settingsVariable" ref={props.menuSettingsRef} src={imgSettings} alt="Prodensa Automation"
                        onClick={(e) => props.menuSettingsRef.current = e.target}
                    />
                }
                {props.varRef?._id?.toString() === PAYROLL_ID &&
                    <img className="settingsVariable" src={imgSettings} alt="Prodensa Automation"
                        onClick={() => props.setShowPayrollModal(true)}
                    />
                }
                {props.varRef?._id?.toString() === LOGISTIC_COSTS_ID &&
                    <img className="settingsVariable" src={imgSettings} alt="Prodensa Automation"
                        onClick={() => props.setShowLogisticCosts(true)}
                    />
                }
                {
                    props.variable.moreOptions && props.settingsModal(props.varRef)
                }
                {props.variable.addedVariable &&
                    <div className="containerSettings" onClick={(e) => e.stopPropagation()}>
                        <img className="mobile-settings-close" src={close} alt="Prodensa Automation" onClick={() => document.body.click()} />
                        <h5>Settings</h5>

                        <div className="titleAndType">
                            <input 
                                type="text" 
                                style={{ background: "rgba(4, 26, 57, 0.05)" }}
                                placeholder="Type of data"
                                onChange={(e) => props.changeVariableTypeOfData(props.category.catIdRef, props.varIndex, e.target.value)}
                            />
                            
                            <DropdownButton title={`${props.variable.settings.typeOfValue}`} align="end">
                                <Dropdown.Item onClick={(e) => props.changeVariableSettingsTypeOfValue(props.category.catIdRef, props.varIndex, e.target.innerText)} as="button">$</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => props.changeVariableSettingsTypeOfValue(props.category.catIdRef, props.varIndex, e.target.innerText)} as="button">%</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => props.changeVariableSettingsTypeOfValue(props.category.catIdRef, props.varIndex, e.target.innerText)} as="button">#</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className="cities">
                            {props.variable.settings.cities.map((city, cityIndex) => (
                                <div className="inputContainer" key={cityIndex}>
                                    <span className="label">{city.name}</span>
                                    <input type="number" value={city.value}
                                        onChange={(e) => props.changeVariableSettingsCityvalue(props.category.catIdRef, props.varIndex, cityIndex, e.target.value)}
                                    />
                                    <span className="valueSelected">
                                        {props.variable.settings.typeOfValue === "Type of value" ? "Select type of value" : props.variable.settings.typeOfValue}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button className="btnBgBlueTextGradient" onClick={() => document.body.click()}>Apply</button>
                        </div>
                    </div>
                }
                {props.variable.addedVariable &&
                    <img className="mobile-deleteRow" src={imgDelete}
                        onClick={() => props.removeVariableToCriteria(props.category.catIdRef, props.varIndex)}
                        alt="Prodensa Automation"
                    />
                }
            </div>
        </section>
    )
}

export default function WantsMobile(props) {
    const [activeItems, setActiveItems] = useState([])

    function toggleItem(item_id) {
        const newItem = { id: item_id }
        const isItemInArray = activeItems.some(item => item.id === newItem.id)

        const updateActiveItems = isItemInArray
            ? activeItems.filter(item => item.id !== newItem.id)
            : [...activeItems, newItem]
        setActiveItems(updateActiveItems)
    }

    function getTotal(items) {
        return items.reduce((acc, item) => acc + item.weight, 0)
    }

    return (
        <section className="d-lg-none wantsMobileContainer">
            <Accordion activeKey={activeItems.map(item => item.id)}>
                {props.wantsMatrix
                    .sort((a, b) => {
                        const da = props.wantsMatrixRef.find(item => item._id === a.catIdRef).position
                        const db = props.wantsMatrixRef.find(item => item._id === b.catIdRef).position

                        return da - db
                    })
                    .map((category, catIndex) => {
                        let totalWeightInfo = 0

                        const catRef = props.wantsMatrixRef.find(item => item._id === category.catIdRef)

                        return (
                            <Accordion.Item eventKey={catRef._id} key={catRef._id}>
                                <Accordion.Header onClick={() => toggleItem(catRef._id)}>
                                    <CategoryHeader
                                        icon={ICONS[catRef._id.toString()]}
                                        category={category}
                                        updateWeightTab={props.updateWeightTab}
                                    >
                                        {catRef.title}
                                    </CategoryHeader>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <section className="mobile-cat-header">
                                        <h5>{catRef.title}</h5>
                                        <div className="mobile-weight-header">
                                            <h5>Points</h5>
                                            <h5>Weight</h5>
                                        </div>
                                    </section>
                                    {category.variables
                                        .sort((a, b) => {
                                            const da = catRef.variables.find(item => item._id === a.varIdRef)?.position
                                            const db = catRef.variables.find(item => item._id === b.varIdRef)?.position

                                            return da - db
                                        })
                                        .map((variable, varIndex) => {
                                            totalWeightInfo += variable.weight
                                            const varRef = !variable.addedVariable ?
                                                catRef.variables.find(item => item._id === variable.varIdRef && !item.deletedRegister)
                                                : variable

                                            const varPoints = (variable.weight * category.weight) / 100

                                            if (!varRef) return

                                            return (
                                                <Variable
                                                    key={variable.varIdRef}
                                                    category={category}
                                                    catRef={catRef}
                                                    catIndex={catIndex}
                                                    variable={variable}
                                                    varRef={varRef}
                                                    varIndex={varIndex}
                                                    varPoints={varPoints}
                                                    updateVariableInformation={props.updateVariableInformation}
                                                    updateWeightVariable={props.updateWeightVariable}
                                                    menuInformationRef={props.menuInformationRef}
                                                    menuSettingsRef={props.menuSettingsRef}
                                                    changeTitleVariable={props.changeTitleVariable}
                                                    initializeManualCriteriaModel={props.initializeManualCriteriaModel}
                                                    initializeCriteriaModel={props.initializeCriteriaModel}
                                                    changeVariableSettingsTypeOfValue={props.changeVariableSettingsTypeOfValue}
                                                    changeVariableSettingsCityvalue={props.changeVariableSettingsCityvalue}
                                                    removeVariableToCriteria={props.removeVariableToCriteria}
                                                    settingsModal={props.settingsModal}
                                                    setShowPayrollModal={props.setShowPayrollModal}
                                                    setShowLogisticCosts={props.setShowLogisticCosts}
                                                    draft={props.draft}
                                                />
                                            )
                                        })
                                    }

                                    <section className="mobile-addAndTotal">
                                        <div className="mobile-addContainer">
                                            <button onClick={() => props.addVariableToCriteria(category.catIdRef)}>Variable</button>
                                        </div>
                                        <div className="mobile-totalVarWeight">
                                            <span>{category.weight}</span>
                                            <span>{`${totalWeightInfo}%`}</span>
                                            <div className={`mobile-dangerMessage ${totalWeightInfo > 100 ? "show" : ""}`}>
                                                <img src={infoIconDanger} alt="Prodensa Automation" />
                                                <p>The sum of all variables shouldn't surpass 100%. Please adjust the values of each
                                                    category.</p>
                                            </div>
                                        </div>
                                    </section>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
                <section className="mobile-totalWeight">
                    <h5>Total</h5>
                    <h5 className={`${getTotal(props.wantsMatrix) > 100 ? "danger" : ""}`}>{getTotal(props.wantsMatrix)}</h5>
                    <div className={`mobile-dangerMessage ${getTotal(props.wantsMatrix) > 100 ? "show" : ""}`}>
                        <img src={infoIconDanger} alt="Prodensa Automation" />
                        <p>The sum of all categories shouldn't surpass 100%. Please adjust the values of each
                            category.</p>
                    </div>
                </section>
            </Accordion>
        </section>
    )
}