import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const SchoolsHospitals = ({ details, city_names, filtered, reportID, variableCosts, wantsMatrixRef }) => {


	const [loading, setLoading] = useState(true)
	const [typeOfView, setTypeOfView] = useState(null) //1 = schools, 2 = hospitals, 3 = retail chains
	const [varRef, setVarRef] = useState(null)

	const viewIndexUpper = ["", "privateSchools", "privateHospitals", "retailChains"];
	const viewIndexLower = ["", "privateSchoolsRatio", "privateHospitalRatio", "retailChains"];


	useEffect(() => {
		if (variableCosts) {
			let typeOfView = 0;
			switch (details.variableIdRef.toString()) {
				case "65cd436af955fa725381e80b":
					typeOfView = 1
					break;
				case "65cd4364f955fa725381e80a":
					typeOfView = 2
					break;
				case "65cd435df955fa725381e809":
					typeOfView = 3
					break;
			}

			wantsMatrixRef.forEach(cat => {
				cat.variables.forEach(variable => {
					if (variable._id === details.variableIdRef) {
						setVarRef(variable)
					}
				}
				)
			})

			setTypeOfView(typeOfView)
			setLoading(false)
		}
	}, [])

	return (
		<Container className="generic-wrapper" >
			{/*First table*/}
			{loading ? (
				<h1>Loading...</h1>
			) : (variableCosts && typeOfView ? (
				<>
					<Row className="no-gutters d-none d-lg-flex">
						<Col lg={3}>
							<h5 className="first-spacer">Indicator</h5>
							<h5 className="side-text">{varRef?.title}</h5>
							<h5 className="side-text last" style={{ background: 'rgba(0,0,0,0.1)' }}>Ratio per 100,000</h5>
						</Col>
						<Col lg={9} className="table-container">
							<div className="table-header">
								{city_names.map((city, index) =>
									filtered.includes(index) &&
									<h5 className="city-title" key={`${city}-header`}>{city}</h5>
								)}
							</div>
							<div className="row-number">
								{city_names.map((number, index) => {
									let textToShow = "N/A";
									let cityFind = variableCosts.find((city) => city.cityName === number);
									if (cityFind) {
										let accessed = cityFind.dataVariables?.[viewIndexUpper?.[typeOfView]];
										let toView = accessed?.[viewIndexLower?.[typeOfView]]
										if (toView) {
											textToShow = formatNumber(toView);
										}
									}
									return (
										filtered.includes(index) &&
										<h5 className="number" key={`${number}-value`}>{textToShow}</h5>
									)
								}
								)}
							</div>
							<div className="row-number">
								{city_names.map((ratio, index) => {
									let textToShow = "N/A";
									let cityFind = details.results.find((city) => city.city === ratio);
									let cellClass = "";
									if (cityFind) {
										if (cityFind.valueResult?.totalCost !== null) {
											if (cityFind.valueResult?.bestOption) {
												cellClass += " highest";
											}
											textToShow = cityFind.valueResult?.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";

										}
									}
									return (
										filtered.includes(index) &&
										<h5 className="number" key={`${ratio}-ratio100k`}><span className={cellClass}>{textToShow}</span></h5>
									)
								}
								)}
							</div>
						</Col>
					</Row>

					<section className="mobile-details-results-table d-lg-none">
						<table>
							<thead>
								<tr>
									<th>Indicator</th>
									{city_names.map(
										(city, index) =>
											filtered.includes(index) &&
											<th key={`${city}-mobile-header`}>{city}</th>
									)}
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{varRef.title}</td>
									{city_names.map(
										(number, index) => {
											let textToShow = "N/A";
											let cityFind = variableCosts.find((city) => city.cityName === number);
											if (cityFind) {
												let accessed = cityFind.dataVariables?.[viewIndexUpper?.[typeOfView]];
												let toView = accessed?.[viewIndexLower?.[typeOfView]]
												if (toView) {
													textToShow = formatNumber(toView);
												}
											}
											return (
												filtered.includes(index) &&
												<td key={`${number}-mobile-value`}>{textToShow}</td>
											)
										}
									)}
								</tr>
								<tr>
									<td style={{ background: "rgba(187, 189, 191, 0.7)" }}>Ratio per 100,000</td>
									{city_names.map(
										(ratio, index) => {
											let cellClass = "";
											let textToShow = "N/A";
											let cityFind = details.results.find((city) => city.city === ratio);
											if (cityFind) {
												if (cityFind.valueResult?.totalCost !== null) {
													textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
													cellClass = cityFind.valueResult?.bestOption ? "lowest" : "";
												}
											}
											return (
												filtered.includes(index) &&
												<td className={cellClass} key={`${ratio}-mobile-ratio100k`}>{textToShow}</td>
											)
										}
									)}
								</tr>
							</tbody>
						</table>
					</section>
				</>
			) : (
				<div className="no-record-wrapper">
					<h3 className="no-record-title">There has been an issue retrieving data.</h3>
					<img src={infodanger} width="50px" height="50px" />
				</div >
			))}

		</Container >
	)
}

export default SchoolsHospitals;