import { useEffect, useRef, useState } from "react";
import Axios from "axios"
import Swal from "sweetalert2"
import { format } from "date-fns"
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/auth-context"
import { customAlert } from "../../utils/customAlert.js";
import { getClientName } from "../../utils/getClientNameById.js"
import { sortTable } from "../../utils/sortTable.js";
import { Filters } from "../../utils/reportLogFilters.js";
import PageSelector from "./pageSelector.jsx";
import groups from "../../assets/images/groups.svg"
import checkicon from "../../assets/images/check_green.svg"
import listAlt from "../../assets/images/list_alt.svg"
import moreDots from "../../assets/images/moreDots.svg"
import sortDown from "../../assets/images/sortDown.svg"
import arrowDown from "../../assets/images/arrowDown.svg"
import calendar from "../../assets/images/calendar_month.svg"
import MobileFilters from "./mobileFilters.jsx"
import formatList from "../../assets/images/format_list_bulleted.svg"

export default function AllReports(props) {
	// #region STATES
	// estados para almacenar los datos brutos y filtrados
	const [allReports, setAllReports] = useState([])
	const [filteredReports, setFilteredReports] = useState([])

	const { user } = useAuth()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [mailError, setMailError] = useState(false)
	const [customMail, setCustomMail] = useState("")
	const [emailReportId, setEmailReportId] = useState(null)
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 

	// estados para el control de sort de los elementos
	const [orderClient, setOrderClient] = useState("DSC")
	const [orderStatus, setOrderStatus] = useState("DSC")
	const [orderDate, setOrderDate] = useState("DSC")

	// estados para el control de filtros
	const [activeFilter, setActiveFilter] = useState(0)
	const [dataType, setDataType] = useState("")
	const [searchClient, setSearchClient] = useState("")
	const [clients, setClients] = useState({
		unique: [],
		filtered: []
	})
	const [filters, setFilters] = useState({
		client: [],
		Teaser: false,
		"Site Selection": false,
		Feasibility: false,
		startDate: "",
		endDate: GetTodayDate()
	})

	// estados para el control de pagina y elementos visibles por pagina
    const [itemsPerPage, setItemsPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1);

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(10);

	const [selectedOptions, setSelectedOptions] = useState({
		client: [],
		type: [],
		date: [filters.endDate]
	})

	// estados para boton de apply en filtros
	const [filterApplied, setFilterApplied] = useState({
		client: false,
		type: false,
		date: false,
	})
	
	const [enable, setEnable] = useState({
		client: true,
		type: true,
		date: true,
	})

	//ref para activar o desactivar dropdown menus
	const pageFilterReportRef = useRef(null)
	const clientFilterRef = useRef(null)
	const typeFilterRef = useRef(null)
	const dateFilterRef = useRef(null)
	const showOptionsReportRef = useRef(null)
	const refs = [pageFilterReportRef, clientFilterRef, typeFilterRef, dateFilterRef]
	
	
	// -------------- states ⬆, useEffect's ⬇ -------------	
	//#region USEEFFECTS
	useEffect(() => {
		if(loading) {
			loadingAnimation()
		} else if (props.isOpened) {
			Swal.close()
		}
	}, [loading, props.isOpened])
	
	useEffect(() => {
		if (user && props.isOpened) {
			loadingAnimation()
			fetchAllReports(user.role, user._id)
		}
	}, [user, props.isOpened, props.isMinimized, props.showClientId])
	
	useEffect(() => { 
		handleTable("DSC", "date")       
        document.body.addEventListener("click", handleCloseDropdowns)

        return () => {
            document.body.removeEventListener("click", handleCloseDropdowns)
        }
    }, [])

	useEffect(() => {
		setClients({
			...clients,
			unique: Array.from(new Set(props.allClients.map(client => client.companyName.charAt(0).toUpperCase() + client.companyName.slice(1)))),
		})
	}, [props.allClients])
	
	//set everything to default when is closed and opened again
	useEffect(() => {
		if (!props.isOpened && !props.isMinimized) {
			setActiveFilter(0)
			setDataType("")
			setSearchClient("")
			setFilters({
				client: [],
				Teaser: false,
				"Site Selection": false,
				Feasibility: false,
				startDate: "",
				endDate: GetTodayDate()
			})
			setItemsPerPage(10)
			setCurrentPage(1)
			setSelectedOptions({
				client: [],
				type: [],
				date: [filters.endDate]
			})
			setFilterApplied({
				client: false,
				type: false,
				date: false,
			})
			setEnable({
				client: true,
				type: true,
				date: true,
			})
			props.setshowClientId(0)
		}
	},[props.isOpened])
	
	//open with a filter set if coming from clients section	
	useEffect(() => {
		if(allReports.length > 0 && props.showClientId !== 0 && !loading && props.isOpened) {
			const report = allReports.find((report => report.siteSelectionReport.clientId === props.showClientId))
			setSelectedOptions({
				...selectedOptions,
				client: [getClientName(report.siteSelectionReport.clientId, props.allClients)],
			})
			setFilterApplied({
				...filterApplied,
				client: true,
			})
			setFilters({
				...filters,
				client: [getClientName(report.siteSelectionReport.clientId, props.allClients)],
			})
		}
	}, [props.showClientId, loading])
	
	// colocar indices para mostrar los reportes por pagina
    useEffect(() => {
        setStartIndex((currentPage - 1) * itemsPerPage)
        setEndIndex(currentPage * itemsPerPage)
    }, [itemsPerPage, currentPage])

	// habilitar boton de apply en los filtros
	useEffect(() => {
		switch (dataType) {
			case "client":
				if (filters.client.length > 0) {
					setEnable({...enable, client: false})
				} else {
					setEnable({...enable, client: true})
				}
				break;
			case "type":
				if(filters.Feasibility || filters["Site Selection"] || filters.Teaser) {
					setEnable({...enable, type: false})
				} else {
					setEnable({...enable, type: true})
				}
				break;
			case "date":
				setEnable({...enable, date: !(filters.startDate && filters.endDate)})
				break;
			default:
				break;
		}
	}, [filters])

	// actualizar table si cambia la cantidad de reportes
	useEffect(() => {
		if (allReports.length > 0) {
			handleTable("DSC", "date")
			
			const filterReports = Filters(allReports, filterApplied, selectedOptions.client, selectedOptions.type, selectedOptions.date, props.allClients) 
			setFilteredReports(SearchBarFilter(filterReports, props.search))
			
			if(currentPage > (Math.ceil(allReports.length / itemsPerPage))) {
				setCurrentPage(currentPage - 1)
			}
		}
	}, [allReports])

	// uso de filtros
	useEffect(() => {
		setCurrentPage(1)

		if (allReports.length > 0) {
			const filterReports = Filters(allReports, filterApplied, selectedOptions.client, selectedOptions.type, selectedOptions.date, props.allClients)
			setFilteredReports(SearchBarFilter(filterReports, props.search))
		}
	}, [filterApplied, props.search])
	
	// actualizar lista de clientes en el filtro de clients
	useEffect(() => {
		if (clients.unique.length > 0) {
			setClients({
				...clients,
				filtered: ClientSearchBarFilter(clients.unique, searchClient)
			})
		}
	}, [searchClient, clients.unique])

	// ---------------- useEffect's ⬆, handle Functions ⬇ ------
	
	// #region HANDLE FUNCTIONS
	//close dropdown menus for filters and for each dropdown menu of reports
	const loadingAnimation = () => {
		Swal.fire({
			title: 'Loading...',
			html: 'Please wait...',
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading()
			}
		});
	}
	
	function handleCloseDropdowns(e) {
        if (!refs.some(ref => ref.current && ref.current.contains(e.target))) {
			setActiveFilter(0)
		}

		const allShowOptions = Array.prototype.slice.call(document.getElementsByClassName("moreOptionsReport"))
		if (showOptionsReportRef.current && showOptionsReportRef.current.contains(e.target)) {
			for (const element of allShowOptions) {
				if(showOptionsReportRef.current && !showOptionsReportRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			}
			allShowOptions.find(element => showOptionsReportRef.current && showOptionsReportRef.current.nextElementSibling.contains(element)).classList.toggle("show")
		} else {
			for(const element of allShowOptions) {
				element.classList.remove("show")
			}
		}
	}
	
	function handleSearchClient(e) {
		const { value } = e.target
		setSearchClient(value)
	}
	
	//manage filters depending which filter was applied
	function handleFilters(element) {
		const name = element.getAttribute("data-type")
		const value = element.value

		setDataType(name)

		switch (name) {
			case "client":
				if(filters.client.includes(value)) {
					const newArray = filters.client.filter(i => i !== value)
					setFilters({
						...filters,
						client: newArray
					})

					setSelectedOptions({
						...selectedOptions,
						[name]: newArray
					})
				} else {
					setFilters({
						...filters,
						client: [...filters.client, value]
					})

					setSelectedOptions({
						...selectedOptions,
						[name]: [...selectedOptions[name], value]
					})
				}
				break;
			case "type":
				setFilters({
					...filters,
					[value]: !(filters[value])
				}
				)

				if(selectedOptions[name].includes(value)) {
					const newArray = selectedOptions[name].filter(i => i !== value)
					setSelectedOptions({
						...selectedOptions,
						[name]: newArray
					})
				} else {
					setSelectedOptions({
						...selectedOptions,
						[name]: [...selectedOptions[name], value]
					})
				}
				break;
			case "date":
				const startEnd = element.name
				
				handleSetDate(startEnd, value)
				break;
			default:
				break;
		}
	}
	
	//control the number of reports shown per page
	function handleItemsPerPage(e) {
		setItemsPerPage(+e.target.innerText)

		setCurrentPage(1)
	}

	//manage dates for filter date
	function handleSetDate(startEnd, value) {
		switch (startEnd) {
			case "startDate":
				if (value > filters.endDate) {
					setFilters({
						...filters,
						[startEnd]: filters.endDate,
					})
					
					setSelectedOptions({
						...selectedOptions,
						date: [filters.endDate, filters.endDate]
					})
				} else {
					setFilters({
						...filters,
						[startEnd]: value,
					})
					setSelectedOptions({
						...selectedOptions,
						date: [value, filters.endDate]
					})
				}
				break;
				case "endDate": 
				if( filters.startDate) {
					setFilters({
						...filters,
						endDate: value < filters.startDate ? filters.startDate : value,
					})

					setSelectedOptions({
						...selectedOptions,
						date: value < filters.startDate ? [filters.startDate, filters.startDate] : [filters.startDate, value]
					})
				} else {
					setFilters({
						...filters,
						endDate: value > GetTodayDate() ? GetTodayDate() : value,
					})

					setSelectedOptions({
						...selectedOptions,
						date: value > GetTodayDate() ? [GetTodayDate()] : [value],
					})
				}
				break;
			default:
				break;
		}
	}

	//apply filters
	function handleSubmit(e) {
		e.stopPropagation()
		const type = e.target.getAttribute("data-type")

		setFilterApplied({
			...filterApplied,
			[type]: true,
		})

		if (e.target.getAttribute("data-type") === "client") {
			setSearchClient("")
		}

		setActiveFilter(0)
	}

	//reset all filters to default
	function handleReset(e) {
		e.stopPropagation()

		const type = e.target.getAttribute("data-type")

		switch (type) {
			case "client":
				setFilters({
					...filters,
					client: []
				})

				setSelectedOptions({
					...selectedOptions,
					client: []
				})

				setFilterApplied({
					...filterApplied,
					client: false
				})

				setSearchClient("")
				break;
			case "type":
				setFilters({
					...filters,
					"Teaser": false,
					"Site Selection": false,
					"Feasibility": false
				})
		
				setSelectedOptions({
					...selectedOptions,
					type: []
				})

				setFilterApplied({
					...filterApplied,
					type: false,
				})
				break;
				case "date":
				setFilters({
					...filters,
					startDate: "",
					endDate: GetTodayDate(),
				})

				setSelectedOptions({
					...selectedOptions,
					date: [GetTodayDate()]
				})

				setFilterApplied({
					...filterApplied,
					date: false,
				})
				break;
				case "global":
					setFilters({
						client: [],
						"Teaser": false,
						"Site Selection": false,
						"Feasibility": false,
						startDate: "",
						endDate: GetTodayDate(),
					})
	
					setSelectedOptions({
						client: [],
						type: [],
						date: [GetTodayDate()]
					})
	
					setFilterApplied({
						client: false,
						type: false,
						date: false,
					})
				break;
			default:
				break;
		}

		setActiveFilter(0)
	}

	function handleEdit(reportId) {
		props.closeReportLog()
		props.openDraft(reportId)
	}

	const handleShareReport = (reportId) => {
		setCustomMail("")
		setShowEmailModal(true)
		setEmailReportId(reportId)
	}

	const validateMail = (customMail) => {
		Swal.fire({
			title: 'Loading...',
			html: 'Please wait...',
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading()
			}
		});

		if (emailPattern.test(customMail)) {
			sendMail();
		} else {
			Swal.close()
			setMailError(true);
		}
	}

	const sendMail = () => {
		Axios.post(process.env.REACT_APP_GENERATE_MAGIC_LINK + emailReportId, { email: customMail }).then(res => {
			Swal.close();
			if (res.data.message === "Mail sent successfully") {
				Swal.fire({
					position: "center",
					titleText: `Your mail has been sent.`,
					showConfirmButton: false,
					imageUrl: checkicon,
					imageWidth: 50,
					imageHeight: 50,
					timer: 2000,
					timerProgressBar: true,
					backdrop: 'rgba(0, 0, 0, 0)',
					customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
					}
				});
				setShowEmailModal(false);
			}
		}).catch(err => {
			console.log(err);
			Swal.fire({
				position: "center",
				titleText: `Failed to send mail.`,
				text: "Please enter a valid email address.",
				icon: "error",
				showConfirmButton: false,
				customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
				}
			});
		});
	}

	const handleOpenResults = async(reportId, typeOfView) => {
		loadingAnimation()
		Axios.get(process.env.REACT_APP_FIND_RESULTS + reportId).then(res => {
			Swal.close()
			if (res.data) {
				navigate(`/reportResults/${reportId}?typeOfResult=${typeOfView}`)
			} else {
				Swal.fire({
					icon: "error",
					title: "Results not found!",
					text: "Need to run the report first to create results.",
					showConfirmButton: false,
					allowEscapeKey: false,
					allowOutsideClick: false,
					timer: 2000,
					timerProgressBar: true,
				})
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

	//duplicate report
	function handleDuplicateReport(reportId) {
		setLoading(true)
		Axios.post(process.env.REACT_APP_DUPLICATE_REPORT, {
			reportId,
			userId: user._id
		}).then(res => {
			if(!res.data.data) {
				Swal.fire({
					icon: 'error',
					title: res.data.message,
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading()
					},
					didDestroy: () => {
						fetchAllReports(user.role)
					}
				})
				return
			}

			fetchAllReports(user.role)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}
	
	//delete report
	function handleDeleteReport(reportId) {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "swal2-button--confirm",
				cancelButton: "swal2-button--cancel"
			},
			buttonsStyling: false
		});

		swalWithBootstrapButtons.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Delete report",
			cancelButtonText: "Cancel",
			reverseButtons: true
		}).then(result => {
			if(result.isConfirmed) {
				Axios.patch(process.env.REACT_APP_DELETE_REPORT + reportId).then(res => {
					if(res.data.data) {
						const pinnedReports = [...props.inProgressReports]
						const indexReport = pinnedReports.findIndex(report => report.draftID === reportId)
						if(indexReport !== -1) {
							Axios.delete(process.env.REACT_APP_DELETE_PINNED_REPORT + pinnedReports[indexReport]._id).then(res => {
								console.log("pin removed")
								Axios.post(process.env.REACT_APP_GET_PINNED_REPORT_BY_USER_ID, {
									userId: user._id,
								}).then(pinnedReports => {
									props.setInProgressReports(pinnedReports.data)
								}).catch(err => {
									console.log(err)
									customAlert(err.response?.data?.message || err.response?.statusText)
								})
							}).catch(err => {
								console.log(err)
								customAlert(err.response?.data?.message || err.response?.statusText)
							})
						}

						fetchAllReports(user.role)
						swalWithBootstrapButtons.fire({
							title: "Deleted!",
							text: "Your report has been deleted.",
							icon: "success"
						});
					} else {
						swalWithBootstrapButtons.fire({
							title: "Oops..",
							text: "Someting went wrong 😟",
							icon: "error"
						});
					}
				}).catch(err => {
					console.log(err)
					customAlert(err.response?.data?.message || err.response?.statusText)
				})

			}
		}).catch(err => console.log(err))
	}

	// #region UTILS
	// utils ⬇

	// fetch allReports
	const fetchAllReports = async(role, userId) => {
		try {
			const res = await props.fetchAllClientsOpen()
			if (res) {
				Axios.post(process.env.REACT_APP_GET_ALL_REPORTS + userId, { role })
					.then(reports => {
						if(reports.data) {
							setAllReports(reports.data)
							
							Swal.close()
						}
					})
					.catch(err => {
						console.log(err)
						customAlert(err.response?.data?.message || err.response?.statusText)
					})
			}
		} catch (err) {
			customAlert(err.response?.data?.message || err.response?.statusText)	
		}
	}
	
	//sort table depending from which type
	const handleTable = (order, type) => {
		setFilteredReports(sortTable(filteredReports, props.allClients, order, type))
		const resetOrders = { client: "DSC", status: "DSC", date: "DSC" };
		const oppositeOrder = { ASC: "DSC", DSC: "ASC" };

		const newOrders = { ...resetOrders, [type]: oppositeOrder[order] };

		setOrderClient(newOrders.client);
		setOrderStatus(newOrders.status);
		setOrderDate(newOrders.date);
	}

	//filter for the search bar input value
	function SearchBarFilter(data, searchTerm) {
		if (searchTerm !== "" && data.length > 0) {
			const filteredData = data.filter(
			(item) =>
				item.folio.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.reportName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				getClientName(item.clientId, props.allClients).toLowerCase().includes(searchTerm.toLowerCase())
			);
			return filteredData;
		}
		return data;
	}

	//filter for the client search input filter
	function ClientSearchBarFilter(data, searchTerm) {
		if (searchTerm !== "" && data.length > 0) {
			const filteredData = data.filter((client) => client.toLowerCase().includes(searchTerm.toLowerCase()))
			return filteredData
		}

		return data;
	}

	//get the current date for date filter initialization
	function GetTodayDate() {
		const today = new Date()
		return today.toISOString().split('T')[0];
	}
	//--------------------- utils --------------------

	// region JSX
	return (
        <section className="tableSection">
			<MobileFilters 
				setDataType={setDataType}
				filters={filters}
				setFilters={setFilters}
				handleFilters={handleFilters}
				enable={enable}
				handleReset={handleReset}
				handleSubmit={handleSubmit}
				searchClient={searchClient}
				handleSearchClient={handleSearchClient}
				clients={clients}
				allClients={props.allClients}
			/>
			
            <div className="filtersContainer d-none d-lg-flex">
                <div className="filtersTypeContainer">
					<div ref={clientFilterRef} className={`clientFilter ${activeFilter === 1 ? "active" : ""} ${filterApplied.client ? "apply": ""}`} 
						onClick={() => activeFilter === 1 ? setActiveFilter(0) : setActiveFilter(1)}>
						<img src={groups} alt="Prodensa automation"/>
						<span>Client</span>
						<img src={arrowDown} alt="Prodensa automation"/>
						<div className={`filterOptions ${activeFilter === 1 ? "show" : ""}`} onClick={(e) => e.stopPropagation()}>
						<div className="clients">
							<h5>Client</h5>
							<div className="searchContainerFilter">
								<input className="searchFilter" type="text" placeholder="Search clients" 
									value={searchClient} onChange={handleSearchClient}/>
							</div>
							{clients.filtered.map((report, index) => (
								<div key={index + 1} className="report">
									<input data-type="client" type="checkbox" id={`${report}`} value={`${report}`} checked={filters.client.includes(report)} 
									onChange={(e) => handleFilters(e.target)}/>
									<label className="client" htmlFor={`${report}`}>{report}</label>
								</div>
							))}
						</div>
							<div className="btnFilter">
								<button data-type="client" onClick={handleReset} name="client">Reset</button>
								<button data-type="client" disabled={enable.client} onClick={handleSubmit} name="client">Apply</button>
							</div>
						</div>
					</div>
					<div ref={typeFilterRef} className={`typeFilter ${activeFilter === 2 ? "active" : ""} ${filterApplied.type ? "apply" : ""}`} 
						onClick={() => activeFilter === 2 ? setActiveFilter(0) : setActiveFilter(2)}>
						<img src={formatList} alt="Prodensa automation"/>
						<span>Type</span>
						<img src={arrowDown} alt="Prodensa automation"/>
						<div className={`filterOptions ${activeFilter === 2 ? "show" : ""}`} onClick={(e) => e.stopPropagation()}>
							<h5>Type</h5>
							<div className="pt-1">
								<div className="optionContainer">
									<input data-type="type" type="checkbox" id="teaser" value="Teaser" checked={filters.Teaser} onChange={(e) => handleFilters(e.target)}></input>
									<label className="option" htmlFor="teaser">Teaser</label>
								</div>
								<div className="optionContainer">
									<input data-type="type" type="checkbox" id="site selection" value="Site Selection" checked={filters["Site Selection"]} onChange={(e) => handleFilters(e.target)}></input>
									<label className="option" htmlFor="site selection">Site selection</label>
								</div>
								<div className="optionContainer">
									<input data-type="type" type="checkbox" id="feasibility" value="Feasibility" checked={filters.Feasibility} onChange={(e) => handleFilters(e.target)}></input>
									<label className="option" htmlFor="feasibility">Feasibility</label>
								</div>
							</div>
							<div className="btnFilter">
								<button data-type="type" onClick={handleReset} name="type">Reset</button>
								<button data-type="type" disabled={enable.type} onClick={handleSubmit} name="type">Apply</button>
							</div>
						</div>
					</div>
					<div ref={dateFilterRef} className={`dateFilter ${activeFilter === 3 ? "active" : ""} ${filterApplied.date ? "apply" : ""}`} 
						onClick={() => activeFilter === 3 ? setActiveFilter(0) : setActiveFilter(3)}>
						<img src={calendar} alt="Prodensa automation"/>
						<span>Date</span>
						<img src={arrowDown} alt="Prodensa automation"/>
						<div className={`filterOptions ${activeFilter === 3 ? "show" : ""}`} onClick={(e) => e.stopPropagation()}>
							<h5 className="pb-2">Date</h5>
							<div className="optionContainer">
								<label htmlFor="startDate" className="option">From</label>
								<input data-type="date" type="date" name="startDate" id="startDate" value={filters.startDate} onChange={(e) => handleFilters(e.target)}/>
								<label htmlFor="endDate" className="option">To</label>
								<input data-type="date" type="date" name="endDate" id="endDate" value={filters.endDate} onChange={(e) => handleFilters(e.target)}/>
							</div>
							<div className="btnFilter pt-2">
								<button data-type="date" onClick={handleReset} name="date">Reset</button>
								<button data-type="date" disabled={enable.date} onClick={handleSubmit} name="date">Apply</button>
							</div>
						</div>
					</div>
					<button data-type="global" className={`${Object.values(filterApplied).some(value => value === true) ? "show" : ""}`} 
						onClick={handleReset} name="date">Reset</button>
                </div>
                <div ref={pageFilterReportRef} className="pagesFilterContainer" onClick={() => activeFilter === 4 ? setActiveFilter(0) : setActiveFilter(4)}>
					<div className={`itemsPerPageFilter ${activeFilter === 4 ? "active" : ""}`}>
						<img src={listAlt} alt="Prodensa automation"/>
						<span>Show per page</span>
						<img src={arrowDown} alt="Prodensa automation"/>
						<div className={`filterOptions ${activeFilter === 4 ? "show" : ""}`}>
							<span className="option" onClick={handleItemsPerPage}>10</span>
							<span className="option" onClick={handleItemsPerPage}>25</span>
							<span className="option" onClick={handleItemsPerPage}>50</span>
							<span className="option" onClick={handleItemsPerPage}>100</span>
						</div>
					</div>
                </div>
            </div>
            <table className="tableReport">
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th style={{width: "26%"}}>Report name</th>
                        <th style={{width: "22%"}}>
                            Client
                            <img className={`sort ${orderClient === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderClient, "client")} />
                        </th>
                        <th style={{width: "17%"}}>Type</th>
                        <th style={{width: "15%"}}>
                            Status
                            <img className={`sort ${orderStatus === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderStatus, "status")} />
                        </th>
                        <th style={{width: "20%"}}>
                            Date modified
                            <img className={`sort ${orderDate === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderDate, "date")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!loading && filteredReports.length > 0 && filteredReports.slice(startIndex, endIndex).map((report) => {
                        return (
                            <tr key={report._id}>
                                <td style={{ opacity: 0.6 }}>{report.folio}</td>
                                <td>{report.reportName}</td>
                                <td style={{ fontWeight: 700 }}>{getClientName(report.siteSelectionReport.clientId, props.allClients)}</td>
                                <td>{report.siteSelectionReport.type}</td>
                                <td className={`${report.siteSelectionReport.status ? "finish" : "pending"}`}>{report.siteSelectionReport.percentage}%</td>
                                <td className="dateAndMore">
                                    <span>{format(new Date(report.updatedAt), "dd MMM yyyy")}</span>
                                    <img ref={showOptionsReportRef} src={moreDots} onClick={(e) => showOptionsReportRef.current = e.target} alt="ProdensaAutomation" />
                                    <div className={`moreOptionsReport`}>
                                        <span className="option" onClick={() => handleOpenResults(report._id, "preview")}>Preview report</span>
                                        <span className="option" onClick={() => handleOpenResults(report._id, "generate")}>View report</span>
                                        {/* <span className="option">Download PDF</span> */}
                                        <span className="option" onClick={() => handleEdit(report._id)}>Edit</span>
                                        <span className="option" onClick={() => handleDuplicateReport(report._id)} >Duplicate</span>
                                        <span className="option" onClick={() => handleShareReport(report._id)}>Share</span>
                                        <span className="option" onClick={() => handleDeleteReport(report._id)} >Delete</span>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
					<Modal className="mail-form-modal" centered show={showEmailModal} onHide={() => setShowEmailModal(false)}>  {/*Modal for adding custom mail */}
						<Modal.Header closeButton>
							<Modal.Title>Send to mail</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<input type="text" className={`mail-capture ${mailError ? "error" : ""}`} placeholder="Enter email address" onChange={(e) => setCustomMail(e.target.value)}></input>
							<p className={`error-msg ${mailError ? "error" : ""}`}>Please enter a valid input</p>
						</Modal.Body>
						<Modal.Footer>
							<button className="send-button" 
								onClick={() => validateMail(customMail)}
							>
								Send
							</button>
						</Modal.Footer>
					</Modal>
                </tbody>
            </table>
			<PageSelector currentPage={currentPage} data={filteredReports} itemsPerPage={itemsPerPage} onChange={(value) => setCurrentPage(value)} isMinimized={props.isMinimized}></PageSelector>
        </section>
	)
}
