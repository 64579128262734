import Axios from "axios"
import Swal from "sweetalert2"

import "../../assets/css/clients/clients.css";

import {useState, useEffect} from "react"
import info from "../../assets/images/info.svg";
import close from "../../assets/images/close.svg"
import { Client } from "../../models/client.ts";
import { customAlert } from "../../utils/customAlert.js";

const PlacesContainers = {
  Headquarters: Symbol("Headquarters"),
  None: Symbol("None"),
}

export default function EditClient({ setActiveSection, idtoEdit, allClients, setClientEdited, handleShowImageModal }) {
  const [focused, setFocused] = useState(false)
  const [isDisable, setIsDisable] = useState(true)
  const [client, setClient] = useState(new Client())
  const [selectedFile, setSelectedFile] = useState({})
  const [placesFound: any[], setPlacesFound] = useState([])
  const [showPlacesContainer, setShowPlacesContainer] = useState(PlacesContainers.None)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setClient(allClients.find((client) => client._id === idtoEdit))
  }, [idtoEdit])

  useEffect(() => {
    client.companyName && client.industry && client.phone && 
    client.mail && client.contactName && client.headquarters ? setIsDisable(false) : setIsDisable(true)
  }, [client])

  useEffect(() => {
    fetchCoordenates();
}, [client.headquarters])

  const fetchCoordenates = async () => {
    if (!client?.headquarters?.fullName) return
    
    try {
      const {data} = await Axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${client.headquarters.fullName}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
      );
  
      setPlacesFound(data.features)
    } catch (error) {
      console.log(error)
    }
  };
  
  function handleCancel() {
    setActiveSection(1)
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function handleSave(id) {
    let data
    if(!selectedFile.name && client.logo?.filename !== "") {
      const { logo, ...restOfClient } = client
      data = {client: JSON.stringify(restOfClient)}

    } else {
      data = new FormData()
      if (selectedFile.name) {
        data.append('logo', selectedFile)
      }
      data.append('client', JSON.stringify(client))
    }

    Axios.patch(process.env.REACT_APP_UPDATE_CLIENT + id, data).then(client => {
      if(!client.data.data) {
        customAlert(client.data.message)
        return
      }
      
      Swal.fire({
        icon: "success",
        title: "Client has been updated successfully!",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        willClose: () => {
          setActiveSection(1)
          setClientEdited(true)
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }, 170);
        }
      })
    }).catch(err => {
      console.log(err)
      customAlert(err.response?.data?.message || err.response?.statusText)
    })
  }

  function handleChange(e) {
    const { name, value } = e.target

    setClient({
      ...client,
      [name]: value
    })
  }

  const selectLogo = async (e) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    const selectedFile = e.target.files[0];

    if (selectedFile && !allowedTypes.includes(selectedFile?.type)) {
        await Swal.fire({
            icon: "error",
            title: "File not supported",
            showConfirmButton: false,
            showCancelButton: false,
            timer: 2500,
            timerProgressBar: true,
        });
        return;
    }

    setSelectedFile(selectedFile)
  };

  const handleCloseLogo = () => {
    setClient({
      ...client,
      logo: {
        ...client.logo,
        filename: "",
      }
    })
  }


  return (
    <section className="editClientSection">
        <div className="inputContainer">
          <span>Company name</span>
          <input value={client.companyName} type="text" onChange={handleChange}
            name="companyName" placeholder="Company name"/>
        </div>
        <div className="inputContainer">
            <span>Phone</span>
            <input value={client.phone} type="number" onChange={handleChange}
              name="phone" placeholder="Phone"/>
        </div>
        <div className="inputContainer">
            <span>Industry</span>
            <input value={client.industry} type="text" onChange={handleChange} name="industry" placeholder="Industry"/>
        </div>
        <div className="inputContainer">
            <span>Contact name</span>
            <input value={client.contactName} type="text" onChange={handleChange}
              name="contactName" placeholder="Contact name"/>
        </div>
        <div className="inputContainer">
          <span>Mail</span>
          <input value={client.mail}
            type="text" onChange={handleChange}
            name="mail" placeholder="Mail"
            onFocus={() => setFocused(!focused)} onBlur={() => setFocused(!focused)}/>
          <span className={`noteUser ${focused ? "show" : ""}`}>
            <img src={info} alt="ProdensaAutomation"/>
            Enter your client's email address
          </span>
        </div>
        <div className="inputContainer position-relative">
          <span>Headquarters</span>
          <input value={client.headquarters.fullName}
            type="text"
            placeholder="Headquarters"
            onBlur={() => setShowPlacesContainer(PlacesContainers.None)}
            onChange={(e) => {
                if (e.target.value !== "")
                    setShowPlacesContainer(PlacesContainers.Headquarters)
                else
                    setShowPlacesContainer(PlacesContainers.None)

                setClient(
                    {
                        ...client,
                        headquarters: {
                            ...client.headquarters,
                            fullName: e.target.value,
                            city: "",
                            state: "",
                            country: "",
                            latitude: undefined,
                            longitude: undefined,
                        }
                    })
                  }}/>
          <div className={`placesFoundContainer ${showPlacesContainer === PlacesContainers.Headquarters ?
              "show" : ""}`}>
              {placesFound.map((place, index) => {
                  return (
                      <span key={`place_${index}`} className="placeFound"
                            onClick={() => {
                                const state = place.context.find(x => x.id.includes("region"))?.text;
                                const country = place.context.find(x => x.id.includes("country"))?.text;

                                setClient({
                                    ...client,
                                    headquarters: {
                                        ...client.headquarters,
                                        fullName: place.place_name,
                                        city: place.text,
                                        state: state ?? "",
                                        country: country ?? "",
                                        latitude: place.geometry.coordinates[1],
                                        longitude: place.geometry.coordinates[0],
                                    }
                                });
                                setShowPlacesContainer(PlacesContainers.None);
                            }}>
                          {place.place_name}</span>
                  )
              })}
          </div>
        </div>
        <div className="inputContainer">
            <span>Notes</span>
            <textarea rows={5} type="textField" value={client.notes} onChange={handleChange}
              name="notes" placeholder="Add comments..."/>
        </div>
        {client.logo && client.logo.filename !== "" ? (
          <div className="inputContainer">
            <span>Logo</span>
            <div className="inputFileContainer">
                <div className="selectFileContainer">
                    <button className="secondaryButton" onClick={() => handleShowImageModal(client.logo.path)}><span>View attached file</span></button>
                    <span>{client.logo.filename}</span>
                </div>
                <img src={close} alt="Prodensa automation" onClick={handleCloseLogo}/>
            </div>
          </div>
        ) : (
        <div className="inputContainer">
            <span>Logo</span>
            <input 
              type="file"
              accept=".jpg, .png"
              name="logo"
              placeholder="Logo"
              onChange={(e) => selectLogo(e)}/>
        </div>
        )}
        <div className="containerActions">
          <button className="cancelButton" onClick={handleCancel}>Cancel
          </button>
          <button className="secondaryButton" disabled={isDisable} onClick={() => handleSave(client._id)}>
              <span>Save Changes</span>
          </button>
        </div>
    </section>
  )
}
