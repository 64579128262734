import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useState, useEffect } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const Homicides = ({ city_names, details, reportID, filtered, musts, variableCosts }) => {
    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null)

    useEffect(() => {
        if (musts && variableCosts) {
            let yearsRef = [] 
            musts.forEach(city => {
                const years = Object.keys(city.security.homicides.homicides_by_year ?? [])
                if (years.length > yearsRef.length) {
                    yearsRef = years;
                }
            })
            setYears(yearsRef);
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>Loading ...</div>
            ) : (
                musts && variableCosts && years ? (
                    <>
                        <Row className="no-gutters d-none d-lg-flex">
                            <Col lg={3}>
                                <h5 className="first-spacer"></h5>
                                <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>State</h5>
                                {years.map((year) =>
                                    <h5 className="side-text" key={`${year}-column`}>{year}</h5>
                                )}
                                <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>Average Homicides/Year(Last 4 years)</h5>
                                {/* <h5 className="first-spacer" style={{ background: 'rgba(0,0,0,0.1)' }}>100,000</h5> */}
                                <h5 className="first-spacer last" style={{ background: 'rgba(0,0,0,0.1)' }}>Ratio per 100,000</h5>
                            </Col>
                            <Col lg={9} className="table-container">
                                <div className="table-header">
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                    )}
                                </div>
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A"
                                        let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                        if (cityFind) {
                                            textToShow = cityFind.state_name;
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-state-name`}>{textToShow}</h5>
                                        )
                                    }
                                    )}
                                </div>
                                {years.map((year) =>
                                    <div className="row-number" key={`${year}-column2`}>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A"
                                            let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                            if (cityFind.security?.homicides?.homicides_by_year) {
                                                textToShow = cityFind.security.homicides.homicides_by_year[year] ? formatNumber(cityFind.security.homicides.homicides_by_year?.[year]) : "N/A";
                                            }
                                            return (filtered.includes(index) &&
                                                <h5 className="number" key={`${city}-${year}-value`}>{textToShow}</h5>)

                                        })}
                                    </div>
                                )}
                                <div className="row-number">
                                    {city_names.map((avg, index) => {
                                        let textToShow = "N/A"
                                        let cityFind = variableCosts.find((cityFind) => cityFind.cityName === avg);
                                        if (cityFind) {
                                            textToShow = cityFind.dataVariables?.homicides?.avgHomicides !== null ? formatNumber(cityFind.dataVariables.homicides.avgHomicides) : "N/A";
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${avg}-average-value`}>{textToShow}</h5>
                                        )
                                    })}
                                </div>
                                {/* <div className="row-number">
                                    {details.rat.map((rat, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="number">{rat}</h5>
                                    )}
                                </div> */}
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A"
                                        let cityFind = details?.results?.find((cityFind) => cityFind.city === city);
                                        if (cityFind) {
                                            if (cityFind.valueResult?.totalCost !== null) {
                                                if (cityFind.valueResult?.bestOption) {
                                                    cellClass += " lowest";
                                                }
                                                textToShow = cityFind.valueResult?.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-ratio100k-value`}><span className={cellClass}>{textToShow}</span></h5>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>

                        <section className="mobile-details-results-table d-lg-none">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-mobile-header`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>State</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = musts.find(cityFind => city === cityFind.municipality_name);
                                            if (cityFind) {
                                                textToShow = cityFind.state_name;
                                            }
                                            return (
                                                filtered.includes(index) && (
                                                    <td key={`${city}-mobile-state`}>{textToShow}</td>
                                                )
                                            );
                                        })}
                                    </tr>
                                    {years.map((year) => (
                                        <tr key={year}>
                                            <td>{year}</td>
                                            {city_names.map((city, index) => {
                                                let textToShow = "N/A";
                                                let cityFind = musts?.find(cityFind => city === cityFind.municipality_name);
                                                if (cityFind) {
                                                    textToShow = cityFind.security?.homicides?.homicides_by_year?.[year] ? formatNumber(cityFind.security.homicides.homicides_by_year[year]) : "N/A";
                                                }
                                                return (
                                                    filtered.includes(index) && (
                                                        <td key={`${city}-${year}-mobile`}>{textToShow}</td>
                                                    )
                                                );
                                            })}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Average Homicides/Year(Last 4 years)</td>
                                        {city_names.map((avg, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = variableCosts?.find(cityFind => avg === cityFind.cityName);
                                            if (cityFind) {
                                                textToShow = cityFind.dataVariables?.homicides?.avgHomicides !== null ? formatNumber(cityFind.dataVariables.homicides.avgHomicides) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) && (
                                                    <td key={`${avg}-mobile-average`}>{textToShow}</td>
                                                )
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Ratio per 100,000</td>
                                        {city_names.map((city, index) => {
                                            let cellClass = "";
                                            let textToShow = "N/A";
                                            let cityFind = details?.results?.find(cityFind => city === cityFind.city);
                                            if (cityFind) {
                                                textToShow = cityFind.valueResult?.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                                cellClass = cityFind.valueResult?.bestOption ? "lowest" : "";
                                            }
                                            return (
                                                filtered.includes(index) && (
                                                    <td key={`${city}-mobile-ratio`} className={cellClass}>{textToShow}</td>
                                                )
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                )
            )
            }

        </Container >
    )
}

export default Homicides;