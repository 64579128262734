import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState, Fragment } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const TsuGraduates = ({ details, city_names, filtered, reportID, musts, variableCosts }) => {

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null);

    useEffect(() => {
        if (musts && variableCosts) {
            let yearsRef = []
            musts.forEach(city => {
                const years = Object.keys(city.graduates?.technical_studies?.electronics_and_automation?.graduates_per_year ?? [])
                if (years.length > yearsRef.length) {
                    yearsRef = years;
                }
            })
            setYears(yearsRef);
            setLoading(false);
        }
    }, []);


    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>loading...</div>
            ) : (musts && variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">EAP</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = musts.find((cityFind) => cityFind.municipality_name === city);
                                    if (cityFind) {
                                        if (cityFind.EAP) {
                                            textToShow = formatNumber(cityFind.EAP);
                                        }
                                    }
                                    return filtered.includes(index) && <h5 className="number" style={{ border: "1px solid rgba(0, 0, 0, 0.05) " }} key={`${city}-eap`}>{textToShow}</h5>;
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Indicator</h5>
                            <h5 className="side-text">TSU Graduates last 4 years</h5>
                            <h5 className="side-text last">AVG TSU Graduates per year</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                    if (cityFind) {
                                        if (cityFind.dataVariables.technicalStudies?.technicalStudiesSum) {
                                            textToShow = formatNumber(cityFind.dataVariables.technicalStudies.technicalStudiesSum);
                                        }
                                    }
                                    return filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-row1`}>{textToShow}</h5>;
                                }
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let cellClass = "";
                                    let textToShow = "N/A";
                                    let cityToShow = details.results.find(cityFind => city === cityFind.city)
                                    if (cityToShow) {
                                        if (cityToShow.valueResult?.totalCost !== null) {
                                            if (cityToShow.valueResult?.bestOption) {
                                                cellClass = "highest";
                                            }
                                            textToShow = formatNumber(cityToShow.valueResult.totalCost);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-row2`}><span className={cellClass}>{textToShow}</span></h5>
                                    )
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>EAP</th>
                                    {city_names.map((city, index) => filtered.includes(index) && <th key={`${city}-mobile-eap`}>{city}</th>)}
                                </tr>
                                <tr>
                                    <th>Indicator</th>
                                    {city_names.map((city, index) => filtered.includes(index) && <th key={`${city}-mobile-indicator`}>TSU Graduates last 4 years</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>TSU Graduates last 4 years</td>
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                        if (cityFind) {
                                            if (cityFind.dataVariables?.technicalStudies?.technicalStudiesSum) {
                                                textToShow = formatNumber(cityFind.dataVariables.technicalStudies.technicalStudiesSum);
                                            }
                                        }
                                        return filtered.includes(index) && <td key={`${city}-mobile-row1`}>{textToShow}</td>;
                                    })
                                    }
                                </tr>
                                <tr>
                                    <td>AVG TSU Graduates per year</td>
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A";
                                        let cityToShow = details.results.find(cityFind => city === cityFind.city)
                                        if (cityToShow) {
                                            if (cityToShow.valueResult?.totalCost !== null) {
                                                if (cityToShow.valueResult?.bestOption) {
                                                    cellClass = "highest";
                                                }
                                                textToShow = formatNumber(cityToShow.valueResult.totalCost);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td key={`${city}-mobile-row2`}><span className={cellClass}>{textToShow}</span></td>
                                        )
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>

                    
                    <h4 className="table-title">Technical level</h4>
                    <h5 className="table-title" style={{opacity: ".9", marginBottom: "30px"}}>Field: Mechanical, electrical, electronic, chemical engineering and related professions</h5>

                    {Object.keys(musts[0].graduates.technical_studies).map((subfield) =>
                        <Fragment key={`${subfield}-table`}>
                            <h4 className="table-title d-none d-lg-block">{musts[0].graduates.technical_studies[subfield].name}</h4>
                            <Row className="no-gutters d-none d-lg-flex">
                                <Col lg={3} className="fixed-container">
                                    <h5 className="first-spacer">Year</h5>
                                    {years.map((year) =>
                                        <h5 className="side-text" style={{height:"70.43px"}} key={`${year}-${subfield}-column1`}>{year}</h5>
                                    )}
                                    <h5 className="side-text" style={{ background: 'rgba(187, 189, 191,0.7)', fontWeight: '700' }}>Total</h5>
                                </Col>
                                <Col lg={9} className="table-container">
                                    <div className="table-header">
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <h5 className="city-title" key={`${city}-${subfield}-row1`}>{city}</h5>
                                        )}
                                    </div>
                                    {years.map((year) =>
                                        <div className="row-number" key={`${year}-${subfield}-column2`}>
                                            {city_names.map((cityGrads, index) => {
                                                let textToShow = "N/A";
                                                let cityFind = musts.find((cityFind) => cityFind.municipality_name === cityGrads);
                                                if (cityFind) {
                                                    if (cityFind.graduates.technical_studies?.[subfield]?.graduates_per_year?.[year]) {
                                                        textToShow = formatNumber(cityFind.graduates.technical_studies[subfield].graduates_per_year[year]);
                                                    }
                                                }
                                                return filtered.includes(index) && <h5 className="number" key={`${cityGrads}-${subfield}-column2`}>{textToShow}</h5>;
                                            })}
                                        </div>
                                    )}
                                    <div className="row-number">
                                        {city_names.map((total, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = variableCosts.find((cityFind) => cityFind.cityName === total);
                                            if (cityFind) {
                                                if (cityFind.dataVariables?.technicalStudies?.technicalStudiesSum) {
                                                    textToShow = formatNumber(cityFind.dataVariables.technicalStudies.technicalStudiesSum);
                                                }
                                            }
                                            return filtered.includes(index) &&
                                                <h5 className="number" style={{ fontWeight: '700' }} key={`${total}-${subfield}`}>{textToShow}</h5>
                                        }
                                        )}
                                    </div>

                                </Col>
                            </Row>
                        </Fragment>
                    )}

                    {Object.keys(musts[0].graduates.technical_studies).map((subfield) =>
                        <Fragment key={`${subfield}-mobile-table`}>
                            <h5 className="table-title d-lg-none">{musts[0].graduates?.technical_studies?.[subfield]?.name}</h5>
                            <section className="mobile-details-results-table d-lg-none">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Year</th>
                                            {city_names.map((city, index) => filtered.includes(index) && <th key={`${city}-mobile-year`}>{city}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {years.map((year) =>
                                            <tr key={`${year}-${subfield}-row1`}>
                                                <td>{year}</td>
                                                {city_names.map((cityGrads, index) => {
                                                    let textToShow = "N/A";
                                                    let cityFind = musts.find((cityFind) => cityFind.municipality_name === cityGrads);
                                                    if (cityFind) {
                                                        if (cityFind.graduates.technical_studies?.[subfield]?.graduates_per_year?.[year]) {
                                                            textToShow = formatNumber(cityFind.graduates.technical_studies[subfield].graduates_per_year[year]);
                                                        }
                                                    }
                                                    return filtered.includes(index) && <td key={`${cityGrads}-${subfield}-row2`}>{textToShow}</td>;
                                                })}
                                            </tr>
                                        )}
                                        <tr>
                                            <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Total</td>
                                            {city_names.map((total, index) => {
                                                let textToShow = "N/A";
                                                let cityFind = variableCosts.find((cityFind) => cityFind.cityName === total);
                                                if (cityFind) {
                                                    if (cityFind.dataVariables?.technicalStudies?.technicalStudiesSum) {
                                                        textToShow = formatNumber(cityFind.dataVariables.technicalStudies.technicalStudiesSum);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <td key={`${total}-${subfield}`}>{textToShow}</td>
                                            }
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </Fragment>
                    )
                    }
                </>
            )
                : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                ))}

        </Container>
    )
}

export default TsuGraduates;