import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { Fragment, useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const EngineeringGraduates = ({ details, city_names, filtered, reportID, musts, variableCosts }) => {

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null);

    useEffect(() => {
        if (musts && variableCosts) {
            let yearsRef = []
            musts.forEach(city => {
                const years1 = Object.keys(city.graduates.degree.electronics_and_automation.graduates_per_year ?? [])
                const years2 = Object.keys(city.graduates.degree.industrial_engineering.graduates_per_year ?? [])

                if (years1.length > yearsRef.length || years2.length > yearsRef.length) {
                    yearsRef = years1.length > years2.length ? years1 : years2
                }
            })
            setYears(yearsRef);
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <h1>Loading...</h1>
            ) : (musts && variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">EAP</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="row-number">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="number" key={`${city}-eap`}>{musts.find(cityFind => cityFind.municipality_name === city)?.EAP ? 
                                        formatNumber(musts.find(cityFind => cityFind.municipality_name === city)?.EAP) : "NaN"}</h5>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Indicator</h5>
                            <h5 className="side-text">Engineering graduates last 5 years</h5>
                            <h5 className="side-text">AVG Engineering graduates per year</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let dataToShow = "N/A"
                                    let electAndAutoSum = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.engineeringGraduates?.electAndAutoSum
                                    let industrialSum = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.engineeringGraduates?.industrialSum
                                    if (electAndAutoSum && industrialSum) {
                                        dataToShow = electAndAutoSum + industrialSum
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-row1`}>{formatNumber(dataToShow)}</h5>

                                    )
                                }
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let dataToShow = "N/A"
                                    let cityFind = details?.results?.find(cityFind => city === cityFind.city)
                                    if (cityFind) {
                                        if (cityFind.valueResult?.totalCost !== null) {
                                            dataToShow = Math.round(cityFind.valueResult.totalCost)
                                        }
                                    }
                                    return filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-row2`}>{formatNumber(dataToShow)}</h5>
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    {Object.keys(musts[0].graduates.degree).map((subfield) =>
                        <Fragment key={`${subfield}`}>
                            <h4 className="table-title d-none d-lg-flex">{musts[0]?.graduates?.degree?.[subfield]?.name}</h4>
                            <Row className="no-gutters d-none d-lg-flex">
                                <Col lg={3} className="fixed-container">
                                    <h5 className="first-spacer">Year</h5>
                                    {years.map((year) =>
                                        <h5 className="side-text" key={`${year}-${subfield}`}>{year}</h5>
                                    )}
                                    <h5 className="side-text" style={{ background: 'rgba(187, 189, 191,0.7)', fontWeight: '700' }}>Total</h5>
                                </Col>
                                <Col lg={9} className="table-container">
                                    <div className="table-header">
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <h5 className="city-title" key={`${city}-${subfield}-header`}>{city}</h5>
                                        )}
                                    </div>
                                    {years.map((year) =>
                                        <div className="row-number" key={`${year}-${subfield}-section1`}>
                                            {city_names.map((cityGrads, index) => {
                                                let textToShow = "N/A"
                                                let cityFind = musts.find(cityFind => cityGrads === cityFind.municipality_name)
                                                if (cityFind) {
                                                    if (cityFind.graduates?.degree?.[subfield]?.graduates_per_year?.[year]) {
                                                        textToShow = cityFind.graduates?.degree?.[subfield]?.graduates_per_year?.[year]
                                                    }

                                                    return filtered.includes(index) &&
                                                        <h5 className="number">{formatNumber(textToShow)}</h5>
                                                }
                                            }

                                            )}
                                        </div>
                                    )}
                                    <div className="row-number">
                                        {city_names.map((total, index) => {
                                            let textToShow = "N/A"
                                            let cityFind = variableCosts.find(cityFind => total === cityFind.cityName)
                                            if (cityFind) {
                                                if (cityFind.dataVariables.engineeringGraduates?.[subfield === "electronics_and_automation" ? "electAndAutoSum" : "industrialSum"]) {
                                                    textToShow = cityFind.dataVariables?.engineeringGraduates?.[subfield === "electronics_and_automation" ? "electAndAutoSum" : "industrialSum"]
                                                }

                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`${total}-${subfield}`}>{formatNumber(textToShow)}</h5>
                                            }
                                        }
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Fragment>
                    )}

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>EAP</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-eap`} style={{textAlign: "center"}}>{musts.find(cityFind => cityFind.municipality_name === city)?.EAP ? formatNumber(musts.find(cityFind => cityFind.municipality_name === city)?.EAP) : "NaN"}</th>
                                    )}
                                </tr>
                                <tr>
                                    <th>Indicator</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Engineering graduates last 5 years</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A"
                                        let electAndAutoSum = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.engineeringGraduates?.electAndAutoSum
                                        let industrialSum = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.engineeringGraduates?.industrialSum
                                        if (electAndAutoSum && industrialSum) {
                                            dataToShow = electAndAutoSum + industrialSum
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td key={`${city}-mobile-row1`}>{formatNumber(dataToShow)}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>AVG Engineering graduates per year</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A"
                                        let cityFind = details?.results?.find(cityFind => city === cityFind.city)
                                        if (cityFind) {
                                            if (cityFind.valueResult?.totalCost !== null) {
                                                dataToShow = Math.round(cityFind.valueResult.totalCost)
                                            }
                                        }
                                        return filtered.includes(index) &&
                                            <td key={`${city}-mobile-row2`}>{formatNumber(dataToShow)}</td>
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>

                    {Object.keys(musts[0].graduates.degree).map((subfield) =>
                        <Fragment key={`${subfield}-mobile`}>
                            <section className="mobile-details-results-table d-lg-none">
                                <h5 className="table-title">{musts[0].graduates?.degree?.[subfield]?.name}</h5>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Year</th>
                                            {city_names.map((city, index) =>
                                                filtered.includes(index) &&
                                                <th key={`${city}-${subfield}-mobile-header`}>{city}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {years.map((year) =>
                                            <tr key={`${year}-${subfield}-section1`}>
                                                <td>{year}</td>
                                                {city_names.map((cityGrads, index) => {
                                                    let textToShow = "N/A"
                                                    let cityFind = musts.find(cityFind => cityGrads === cityFind.municipality_name)
                                                    if (cityFind) {
                                                        if (cityFind.graduates?.degree?.[subfield]?.graduates_per_year?.[year]) {
                                                            textToShow = cityFind?.graduates?.degree?.[subfield]?.graduates_per_year[year]
                                                        }

                                                        return filtered.includes(index) &&
                                                            <td>{formatNumber(textToShow)}</td>
                                                    }
                                                }

                                                )}
                                            </tr>
                                        )}
                                        <tr>
                                            <td style={{background: "rgba(187, 189, 191, 0.7)"}}>Total</td>
                                            {city_names.map((total, index) => {
                                                let textToShow = "N/A"
                                                let cityFind = variableCosts.find(cityFind => total === cityFind.cityName)
                                                if (cityFind) {
                                                    if (cityFind.dataVariables?.engineeringGraduates?.[subfield === "electronics_and_automation" ? "electAndAutoSum" : "industrialSum"]) {
                                                        textToShow = cityFind.dataVariables?.engineeringGraduates?.[subfield === "electronics_and_automation" ? "electAndAutoSum" : "industrialSum"]
                                                    }

                                                    return filtered.includes(index) &&
                                                        <td key={`${total}-${subfield}`}>{formatNumber(textToShow)}</td>
                                                }
                                            })
                                            }
                                        </tr>
                                    </tbody>
                                </table>

                            </section>
                        </Fragment>
                    )}

                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            ))
            }

        </Container>
    )
}

export default EngineeringGraduates;