import "../../assets/css/detailsViews/tableStyles.css";

import { Container, Row, Col } from "react-bootstrap";

export default function AddedVariableDetails(props) {
    const {
        details,
        city_names,
        filtered,
    } = props

    const rowTitle = details?.typeOfData === "" ? "City value" : details?.typeOfData
    
    return (
        <Container className="generic-wrapper" >
            <Row className="no-gutters d-none d-lg-flex">
                <Col lg={3}>
                    <h5 className="first-spacer">Concept</h5>
                    <h5 className="side-text">{rowTitle}</h5>
                    <h5 className="side-text">Difference from better value</h5>
                </Col>

                <Col lg={9} className="table-container">
                    <div className="table-header">
                        {city_names.map((city, index) =>
                            filtered.includes(index) &&
                            <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                        )}
                    </div>

                    <div className="row-number">
                        {city_names.map((city, index) => {
                            const cityFound = details.results.find(cityResult => cityResult.city === city)
                            let cityValue = cityFound.valueResult?.totalCost
    
                            if (details.typeOfInput === "%") {
                                cityValue = cityValue + details.typeOfInput
                            } else if (details.typeOfInput === "$") {
                                cityValue = details.typeOfInput + cityValue
                            }

                            return (
                                filtered.includes(index) &&
                                <h5 className="number" key={`${city}-value`}>{cityValue}</h5>
                            )
                        }
                        )}
                    </div>

                    <div className="row-number">
                        {city_names.map((city, index) => {
                            const cityFound = details.results.find(cityResult => cityResult.city === city)
                            let cityValue = cityFound.valueResult?.difference

                            if (cityFound.valueResult?.typeOfDifference === "Percentage" && cityValue !== 0) {
                                cityValue = cityValue + "%" 
                            }

                            if (cityValue === 0) {
                                cityValue = "Best value"
                            }

                            return (
                                filtered.includes(index) &&
                                <h5 className="number red" key={`${city}-difference`}>{cityValue}</h5>
                            )
                        }
                        )}
                    </div>
                </Col>
            </Row>

            <section className="mobile-details-results-table d-lg-none">
                <table>
                    <thead>
                        <tr>
                            <th>Concept</th>
                            {city_names.map((city, index) =>
                                filtered?.includes(index) &&
                                <th key={`${city}-headerMobile`}>{city}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{rowTitle}</td>
                            {city_names.map((city, index) => {
                                const cityFound = details.results.find(cityResult => cityResult.city === city)
                                let cityValue = cityFound.valueResult?.totalCost
        
                                if (details.typeOfInput === "%") {
                                    cityValue = cityValue + details.typeOfInput
                                } else if (details.typeOfInput === "$") {
                                    cityValue = details.typeOfInput + cityValue
                                }

                                return (
                                    filtered.includes(index) &&
                                    <td className="number" key={`${city}-valueMobile`}>{cityValue}</td>
                                )
                            })}
                        </tr>
                        <tr>
                            <td>Difference from better value</td>
                            {city_names.map((city, index) => {
                                const cityFound = details.results.find(cityResult => cityResult.city === city)
                                let cityValue = cityFound.valueResult?.difference

                                if (cityFound.valueResult?.typeOfDifference === "Percentage" && cityValue !== 0) {
                                    cityValue = cityValue + "%" 
                                }

                                if (cityValue === 0) {
                                    cityValue = "Best value"
                                }

                                return (
                                    filtered.includes(index) &&
                                    <td className="number red" key={`${city}-differenceMobile`}>{cityValue}</td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </section>
        </Container>
    )
}
