import "../../assets/css/login/login.css"
import logo from "../../assets/images/logo.svg";
import info from "../../assets/images/info.svg";
import world from "../../assets/images/world.png";

import { customAlert, loadingAlert } from "../../utils/customAlert.js"


import Axios from "axios"
import Swal from "sweetalert2"
import { Modal } from "react-bootstrap"
import { useCookies  } from "react-cookie"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [cookies, setCookies] = useCookies(["token"])

	const [focused, setFocused] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [rememberMe, setRememberMe] = useState(false)

	// const [loading, setLoading] = useState(false)
	const [animateContainer, setAnimatecontainer] = useState(false);
	const [animateColumn, setAnimateColumn] = useState(false);

	const [showEmailModal, setShowEmailModal] = useState(false);
	const [passwordResetInput, setPasswordResetInput] = useState("")


	const { user, updateUser } = useAuth();
	const navigate = useNavigate();


	useEffect(() => {
		if(cookies.token) {
			navigate('/dashboard')
		}
	}, [cookies.token])
	
	useEffect(() => {
		username !== "" && password !== "" ? setIsDisable(false) : setIsDisable(true);
	}, [username, password])

	const loginClick = (e) => {
		loadingAlert()
		
		e.preventDefault();
		Axios.post(process.env.REACT_APP_LOGIN, {
			username,
			password
		}).then(res => {
			if (!res.data.data) {
				customAlert(res.data.message)
				return
			}

			const { token, ...user } = res.data.data
			
			if (rememberMe) {
				const expirationDate = new Date()
				expirationDate.setDate(expirationDate.getDate() + 7)
				// setCookies("user", res.data.data, { expires: expirationDate })
				setCookies("token", token, { expires: expirationDate })
			} else {
				// setCookies("user", res.data.data)
				setCookies("token", token)
			}

			updateUser(user)
			setAnimateColumn(true);
			setAnimatecontainer(true);
		}).catch(err => {
			console.log(err)
			customAlert(err.response.data.message || err.response?.statusText)
		})
	}

	const handleForgotPassword = () => {
		setShowEmailModal(true)
	}

	const handleResetChange = (e) => {
		setPasswordResetInput(e.target.value)
	}

	const handleResetPassword = () => {
		loadingAlert()

		Axios.patch(process.env.REACT_APP_RESET_PASSWORD, {
			mail: passwordResetInput
		}).then(res => {
			if (res.status >= 200 && res.status < 300) {
				customAlert(res.data.message, "success")
				setPasswordResetInput("")
				setShowEmailModal(false)
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response.data.message || err.response?.statusText)
		})
	}

	// const handleAnimationEnd = () => {
	// 	navigate("/dashboard")
	// }
	
	// useEffect(() => {
	// 	if (!loading) {
	// 		Swal.close()
	// 		return
	// 	}

	// 	Swal.fire({
	// 		title: 'Loading...',
	// 		html: 'Please wait...',
	// 		allowEscapeKey: false,
	// 		allowOutsideClick: false,
	// 		didOpen: () => {
	// 			Swal.showLoading()
	// 		}
	// 	});
	// }, [loading])

	return (!user && (
		<section className="login">
			<img className="logo-header-mobile d-block d-lg-none" src={logo} alt="ProdensaAutomation" />
			<img className="world" src={world} alt="ProdensaAutomation" />
			<div className="container">
				<div className="row">
					<div className="col-10 col-lg-4 offset-lg-7">
						<img className="logo d-none d-lg-block" src={logo} alt="ProdensaAutomation" />
						<form onSubmit={(e) => loginClick(e)}>
							<div className="inputContainer">
								<span className="username">Username</span>
								<input onChange={(e) => setUsername(e.target.value)} value={username} type="text" placeholder="Username" autoComplete="username" onFocus={() => setFocused(!focused)} onBlur={() => setFocused(!focused)} />
								<span className={`noteUser ${focused ? "show" : ""}`}>
									<img src={info} alt="ProdensaAutomation" />
									Enter your email address
								</span>
							</div>
							<div className="inputContainer passwordContainer">
								<span className="password">Password</span>
								<input onChange={(e) => setPassword(e.target.value)} value={password} type={`${!showPassword ? "password" : "text"}`} placeholder="Password" autoComplete="current-password" />
								<div className={`passwordIcon ${showPassword ? "show" : ""}`} onClick={() => setShowPassword(!showPassword)} onMouseEnter={() => setShowTooltip(!showTooltip)} onMouseLeave={() => setShowTooltip(!showTooltip)} />
								<span className={`passwordTooltip ${showTooltip ? "show" : ""}`}>{showPassword ? "Hide password" : "Show password"}</span>
							</div>
							<div className="rememberContainer">
								<input id="rememberMe" type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>
								<label htmlFor="rememberMe">Remember me</label>
								<div className="forgot-password" onClick={handleForgotPassword}>Forgot password?</div>
							</div>
							<button disabled={isDisable} className={`${isDisable ? "loginDisable" : "loginSubmit"}`} type="submit">
								<span>Login</span>
							</button>
						</form>
					</div>
				</div>
			</div >
			<span className="privacy">Privacy</span>

			<Modal className="mail-form-modal" centered show={showEmailModal} onHide={() => setShowEmailModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Enter your email for password reset</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<input type="text"  placeholder="Enter email address" value={passwordResetInput} onChange={handleResetChange}></input>
				</Modal.Body>
				<Modal.Footer>
					<button className="send-button" onClick={handleResetPassword}
					>
						Send
					</button>
				</Modal.Footer>
			</Modal>

			<div className={`animationContainer ${animateContainer ? "show" : ""}`}>
			{/* <div className={`animationContainer ${animateContainer ? "show" : ""}`} onAnimationEnd={handleAnimationEnd}> */}
				<div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
				<div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
				<div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
				<div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
			</div>
		</section >)
	)
}

export default Login;
