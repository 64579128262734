import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const NationalProgress = ({ details, city_names, filtered, reportID, musts }) => {
    //For test before integration
    // const details = {
    // state:["Sonora","Coahuila","Nuevo Leon","Baja California","Queretaro","Yucatan"],
    // social_pi:[71.5,69.2,69.2,70.0,72.0,71.0],
    // national_ranking:[2,4,4,2,3,5]
    // };

    // const city_names = ["Saltillo", "Torreon", "Monterrey", "Mexicali", "Queretaro", "Yucatan"];
    const [loading, setLoading] = useState(true)


    //REQUEST IS REDUNDANT PER VIEW, CHANGE TO A GLOBAL GATHER AND THEN PROPAGATE ACCORDINGLY

    useEffect(() => {
        if (musts) {
            setLoading(false);
        }
    }, [])

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                musts ? (
                    <>
                        <h4 className="table-title">National Progress Index</h4>
                        <Row className="no-gutters d-none d-lg-flex">
                            <Col lg={3}>
                                <h5 className="first-spacer">Concept</h5>
                                <h5 className="side-text">State</h5>
                                <h5 className="side-text">Social Progress Index</h5>
                                {/* <h5 className="side-text">National Ranking</h5> */}
                            </Col>
                            <Col lg={9} className="table-container">
                                <div className="table-header">
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                    )}
                                </div>
                                <div className="row-number">
                                    {city_names.map((state, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = musts.find((city) => city.municipality_name === state);
                                        if (cityFind) {
                                            textToShow = cityFind.state_name;
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${state}-state-name`}>{textToShow}</h5>
                                        )
                                    })}
                                </div>
                                <div className="row-number">
                                    {city_names.map((social_index, index) => {
                                        let textToShow = "N/A";
                                        let cellClass = "";
                                        let cityFind = details.results.find((city) => city.city === social_index);
                                        if (cityFind) {
                                            if (cityFind.valueResult?.totalCost !== null) {
                                                textToShow = formatNumber(cityFind.valueResult.totalCost);
                                            }
                                            if (cityFind.valueResult?.bestOption) {
                                                cellClass = "lowest";
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className={`number ${cellClass}`} key={`${social_index}-total`}>{textToShow}</h5>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>

                        <section className="mobile-details-results-table d-lg-none">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Concept</th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-header`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>State</td>
                                        {city_names.map((state, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = musts.find((city) => city.municipality_name === state);
                                            if (cityFind) {
                                                textToShow = cityFind.state_name;
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td key={`${state}-state-name`}>{textToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                    <tr>
                                        <td>Social Progress Index</td>
                                        {city_names.map((social_index, index) => {
                                            let textToShow = "N/A";
                                            let cellClass = "";
                                            let cityFind = details.results.find((city) => city.city === social_index);
                                            if (cityFind) {
                                                if (cityFind.valueResult?.totalCost !== null) {
                                                    textToShow = formatNumber(cityFind.valueResult.totalCost);
                                                }
                                                if (cityFind.valueResult?.bestOption) {
                                                    cellClass = "lowest";
                                                }
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td className={cellClass} key={`${social_index}-total`}>{textToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                ))}

        </Container >
    )
}

export default NationalProgress;