import "../../assets/css/navigationMenu/navigationMobile.css"

import contract from '../../assets/images/contract.svg';
import folder from '../../assets/images/folder.svg';
import person from '../../assets/images/person.svg';
import manufacturing from "../../assets/images/manufacturing.svg"
import moreDots from "../../assets/images/moreDots.svg"

import Axios from "axios"
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/auth-context";
import { customAlert, loadingAlert } from "../../utils/customAlert";

export default function NavigationMobile(props) {
	const MENU_OPTIONS = [
		{
			_id: 0,
			image: contract,
			title: "New report",
			description: "Create a new report for an existing or new client.",
			options: [
				{
					_id: 10,
					title: "Teaser"
				},
				{
					_id: 11,
					title: "Site selection"
				},
				{
					_id: 12,
					title: "Feasibility"
				}
			]
		},
		{
			_id: 1,
			image: folder,
			title: "Report log",
			description: "View, edit, and filter existing reports.",
		},
		{
			_id: 2,
			image: person,
			title: "Clients",
			description: "View, edit, and filter existing clients.",
		},
		{
			_id: 3,
			image: manufacturing,
			title: "Settings",
			description: "Edit settings for reports",
			options: [
				{
					_id: 30,
					title: "Users"
				},
				{
					_id: 31,
					title: "Streetview"
				},
				{
					_id: 32,
					title: "Border"
				},
				{
					_id: 33,
					title: "update Electricity Costs",
					settingOption: 10,
					optionKey: "electricity"
				},
				{
					_id: 34,
					title: "update Lawsuits",
					settingOption: 11,
					optionKey: "lawsuits"
				},
				{
					_id: 35,
					title: "update Strikes History",
					settingOption: 12,
					optionKey: "strikes"
				},
				{
					_id: 36,
					title: "update Priv. Schools",
					settingOption: 13,
					optionKey: "schools"
				},
				{
					_id: 37,
					title: "update Priv. Hospitals",
					settingOption: 14,
					optionKey: "hospitals"
				},
				{
					_id: 38,
					title: "update Priv. Retail Chains",
					settingOption: 15,
					optionKey: "retailChains"
				},
				{
					_id: 39,
					title: "update Priv. Social Progress Index",
					settingOption: 16,
					optionKey: "socialProgress"
				},
			]
		},
	]

	const [tableOptionToUpdate, setTableOptionToUpdate] = useState("")
	
	const { user } = useAuth()

	const moreOptionsMobileRef = useRef(null)
	const updateTableMobRef = useRef(null)

	const handleShowCardOptions = (e) => {
		const allShowCardOptions = Array.prototype.slice.call(document.querySelectorAll(".navMobile-card-options"))
		if (moreOptionsMobileRef.current && moreOptionsMobileRef.current.contains(e.target)) {
			for (const element of allShowCardOptions) {
				if (!moreOptionsMobileRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			}
			allShowCardOptions.find(element => element === moreOptionsMobileRef.current.nextElementSibling).classList.toggle("show")
		} else {
			for (const element of allShowCardOptions) {
				element.classList.remove("show")
			}
		}
	}

	const handleOptionClick = (id, optionKey) => {
		switch (id) {
			case 1:
				props.openReportLog()
				break
			case 2:
				props.openClients()
				break
			case 11:
				props.openDraft()
				break
			case 30:
				props.setConfigOpened(1)
				break
			case 31:
				props.setConfigOpened(2)
				break
			case 32:
				props.setConfigOpened(3)
				break
			case 33:
			case 34:
			case 35:
			case 36:
			case 37:
			case 38:
			case 39:
				setTableOptionToUpdate(optionKey)

				updateTableMobRef?.current.click()
				break
			default:
				break
		}
	}

	const handleTableFile = async (e) => {
		const selectedFile = e.target.files[0];
		const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		if (!selectedFile) return

		if (selectedFile && allowTypes !== selectedFile.type) {
			await Swal.fire({
					icon: "error",
					title: "File not supported",
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
			});
			return;
		}

		loadingAlert()
		
		const data = new FormData()
		data.append("fileType", tableOptionToUpdate)
		data.append("file", selectedFile)

		Axios.post(process.env.REACT_APP_UPDATE_CITIES_INFORMATION, data).then(res => {
			customAlert(res.data.message, "success")

			setTableOptionToUpdate("")
			
			updateTableMobRef.current.value = ""
		}).catch(err => {
			setTableOptionToUpdate("")
			
			updateTableMobRef.current.value = ""

			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}
	
	useEffect(() => {
		document.body.addEventListener('click', handleShowCardOptions)
		
		return function cleanup() {
			document.body.removeEventListener('click', handleShowCardOptions)
		}
	}, [])
	
	return (
		<section className="navigationMobile d-lg-none">
			{MENU_OPTIONS.map((option, index) => {
				const disabledSection = (user?.role === "BD" && option._id === 3) || (user?.role === "BD" && option._id === 2)
				if(disabledSection) return null

				return (<div className="navMobile-card" key={index} onClick={() => handleOptionClick(option._id)}>
					<img src={option.image} alt="Prodensa Automation" />
					<h5>{option.title}</h5>
					<p>{option.description}</p>
					{option.options &&
						<img className="navMobile-card-dots"
							ref={moreOptionsMobileRef}
							src={moreDots}
							alt="Prodensa automation"
							onClick={(e) => moreOptionsMobileRef.current = e.target} />
					}
					{option.options && (
						<div className={`navMobile-card-options`}>
							<input type="file" accept=".xlsx" ref={updateTableMobRef} style={{display: "none"}} onChange={handleTableFile}/>
							
							{option.options.map((opt, index) => {
								if (user?.role !== "admin" && opt._id === 30) return null
								const disabledOption = (user?.role === "BD" && opt._id === 11) || opt._id === 12 || opt._id === 10
								const disabledOptionClass = disabledOption ? "disabledOption" : ""

								return (
									<h5 key={index} className={disabledOptionClass} disabled={disabledOption} onClick={() => handleOptionClick(opt._id, opt.optionKey)}>{opt.title}</h5>
								)
							}
							)}
						</div>
					)}
				</div>)
			}
			)}
		</section>
	)
}