import "../../assets/css/dashboard/dashboard.css"

import Axios from "axios"
import {format } from "date-fns"
import {enUS} from "date-fns/locale"
import {useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useAuth } from "../../context/auth-context.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2"

import PinButton from "./pinButton.jsx";
import {Draft} from "../../models/draft.ts";
import Clients from "../clients/clients.jsx"
import ReportLog from "../reportLog/reportLog";
import RecentReports from "./recentReports.jsx";
import SearchClients from "./searchClients.jsx";
import { customAlert } from "../../utils/customAlert.js"
import NavigationMenu from "../navigation/navigationMenu.jsx";
import SiteSelection from "../siteSelection/siteSelection.jsx";
import Teaser from "../teaser/teaser.jsx";
import NavBar from "../navbar/navbar.jsx";
import {SiteSelectionReport} from "../../models/siteSelectionReport.ts";
import Streetviewconfig from "../streetviewconfig/streetviewconfig.jsx";
import BorderConfig from "../borderConfig/borderConfig.jsx";
import NavigationMobile from "../navigation/navigationMobile.jsx";
import UserConfig from "../usersConfig/UserConfig.jsx";

function Dashboard() {
    const formattedDate = format(Date.now(), "EEEE, MMMM dd yyyy", {locale: enUS})
    const [cookies, _setCookies, removeCookie] = useCookies(['token'])
    const mapReportId = useLocation()
    const [isReportId, setIsReportId] = useState(() => {
        const searchParams = new URLSearchParams(mapReportId.search)
        const paramsReportId = searchParams.get("reportId")
        return paramsReportId
    })

    // const cleanedUrl = mapReportId.pathname.split("?")[0];
    const cleanedUrl = "/dashboard"
    window.history.replaceState(null, "", cleanedUrl);

    const [allClients, setAllClients] = useState([])
    // const [activeUser, setActiveUser] = useState(null)
    const [wantsMatrixReference, setWantsMatrixReference] = useState(null)
    const [siteSelectionOpened, setSiteSelectionOpened] = useState(false)
    const [reportLogOpened, setReportLogOpened] = useState(false)
    const [getRecentReports, setGetRecentReports] = useState(false)
    const [clientsOpened, setClientsOpened] = useState(false)
    const [configOpened, setConfigOpened] = useState(null)
    // const [streetConfOpened, setStreetConfOpened] = useState(false);
    // const [borderConfOpened, setBorderConfOpened] = useState(false);
    const [showClientId, setshowClientId] = useState(0)
    const [activateFetch, setActivateFetch] = useState(false)
    const [selectedDraft, setSelectedDraft] = useState()
    const [inProgressReports, setInProgressReports] = useState([])
    const [drafts, setDrafts] = useState([])
    const [finishAnimation, setFinishAnimation] = useState(false)
    const [startAnimation, setStartAnimation] = useState(false)
    const [initialAnimation, setInitialAnimation] = useState(() => isReportId ? false : true)
    // const [loading, setLoading] = useState(false)

    const { user, updateUser } = useAuth()

    const navigate = useNavigate()

    // useEffect(() => {
    //     if (!loading) {
    //         Swal.close()
	// 		return
    //     }

    //     Swal.fire({
	// 		title: 'Loading...',
	// 		html: 'Please wait...',
	// 		allowEscapeKey: false,
	// 		allowOutsideClick: false,
	// 		didOpen: () => {
	// 			Swal.showLoading()
	// 		}
	// 	});
    // }, [loading])
    
    useEffect(() => {
        if (user && activateFetch) {
            // setLoading(true)
            
            fetchAllClients()
        }
    }, [user, activateFetch])

    async function getClientsWithPromise() {
        Swal.fire({
            title: 'Loading...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        
        const res = await fetchAllClientsOpen()

        if (res) {
            Swal.close()
        }
    }
    
    useEffect(() => {
        if (user && !activateFetch && clientsOpened) {
            // setLoading(true)
            
            getClientsWithPromise()
        }

        if(user && !reportLogOpened && !clientsOpened && !siteSelectionOpened && !isReportId) {
            // setLoading(true)
            Axios.post(process.env.REACT_APP_GET_PINNED_REPORT_BY_USER_ID, {
                userId: user._id,
            }).then(pinnedReports => {
                setInProgressReports(pinnedReports.data)
                // setLoading(false)
            }).catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        }
    }, [user, reportLogOpened, clientsOpened, siteSelectionOpened, isReportId])
    
    useEffect(() => {
        if (!user) {
            if (!cookies.token) {
                navigate('/')
            }

            // setLoading(true)
            
            Axios.post(process.env.REACT_APP_FIND_USER_BY_TOKEN, {
                token: cookies.token
            }).then(res => {
                if (!res.data.data) {
                    removeCookie("token")
                    Swal.close()
                    navigate('/')
                }

                updateUser(res.data.data)
                // setLoading(false)
            }).catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        }
    }, [user])

    useEffect(() => {
        if (!siteSelectionOpened && selectedDraft) {
            if (!wantsMatrixReference && selectedDraft) {
                // setLoading(true)
                Axios.get(process.env.REACT_APP_GET_WANTSMATRIXREFERENCE).then(res => {
                    setWantsMatrixReference(res.data.data)
                    if (!res.data.data) {
                        customAlert(res.data.message)
                        return
                    }

                    // setLoading(false)
                    setSiteSelectionOpened(true);
                }).catch(err => {
                    console.log(err)
                    customAlert(err.response?.data?.message || err.response?.statusText)
                })
            } else {
                setSiteSelectionOpened(true);
            }
        }
    }, [selectedDraft])

    useEffect(() => {
        if (startAnimation) {
            document.body.style.overflow = "hidden"
        }

        if (finishAnimation) {
            document.body.style.overflow = "auto"
        }
    }, [startAnimation, finishAnimation])

    // useEffect(() => {
    //     if (isReportId) {
    //         setInitialAnimation(false)
    //     }
    // }, [isReportId])

    // useEffect(() => {
    //     if(!loading && isReportId) {
    //         openDraft(isReportId)
    //     }
    // }, [isReportId])
    useEffect(() => {
        if (isReportId) {
            openDraft(isReportId)
        }
    }, [isReportId])

    const capitalizedName = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1).split(' ')[0];
    }

    const openDraft = (draftID) => {
        if (!draftID) {
            setSelectedDraft(new Draft(0, "", 0, new SiteSelectionReport()));
        } else {
            // setLoading(true)
            Axios.get(process.env.REACT_APP_FIND_REPORT + draftID).then(reportRes => {
                if (!reportRes.data.data) {
                    Swal.fire({
                        icon: 'error',
                        title: reportRes.data.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        timer: 2500,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        },
                        didDestroy: () => {
                            // setLoading(true)
                            Axios.post(process.env.REACT_APP_GET_RECENT_REPORTS + user._id, { role: user.role }).then(recentRepRes => {
                                if (recentRepRes.data) {
                                    setGetRecentReports(recentRepRes.data)
                                }
                                // setLoading(false)
                            }).catch(err => {
                                console.log(err)
                                customAlert(err.response?.data?.message || err.response?.statusText)
                            })
                        }
                    })
                    return
                }

                const { siteSelectionReport } = reportRes.data.data;
                siteSelectionReport.assumption.utilities.waterAvailable = siteSelectionReport.assumption.utilities.waterAvailable || false;
                setSelectedDraft(reportRes.data.data)
                // setLoading(false)
            }).catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        }
    }

    const fetchAllClients = () => {
        Axios.get(process.env.REACT_APP_FIND_ALL_CLIENTS)
        .then(clientsRes => {
            setAllClients(clientsRes.data)
        })
        .catch(err => {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }

    const fetchAllClientsOpen = () => {
        return new Promise(resolve => {
            Axios.get(process.env.REACT_APP_FIND_ALL_CLIENTS)
            .then(clientsRes => {
                setAllClients(clientsRes.data)
                resolve(true)
            })
            .catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        })
    }

    return (
        <>
            {/* DASHBOARD CONTAINER FOR SPLIT SCREEN */}
            <div className={`dashboardContainer ${reportLogOpened || clientsOpened || siteSelectionOpened || configOpened ? "handleHeight" : ""}`}>
                {/* WELCOME LOGIN ANIMATION */}
                {initialAnimation && <div className={`animationContainer ${user ? "show" : ""}`} 
                        onAnimationEnd={() => {setFinishAnimation(true); setTimeout(() => setInitialAnimation(false), 1000)}} 
                        onAnimationStart={() => setStartAnimation(true)}>
                    <div className={`columnAnimation ${user ? "animate" : ""}`}></div>
                    <div className={`columnAnimation ${user ? "animate" : ""}`}></div>
                    <div className={`columnAnimation ${user ? "animate" : ""}`}></div>
                    <div className={`columnAnimation ${user ? "animate" : ""}`}></div>
                    <h2 className={`title ${user ? "animate" : ""}`}
                        data-name={user && capitalizedName(user.fullName)}>Welcome </h2>
                </div>}

                <NavigationMenu openDraft={openDraft}
                                initialAnimation={initialAnimation}
                                openReportLog={() => setReportLogOpened(true)}
                                openClients={() => setClientsOpened(true)}
                                setOpenConfig={setConfigOpened}
                />

                <section className="dashboard">
                    <NavBar 
                        allClients={allClients} 
                        openReportLog={() => setReportLogOpened(true)}
                        setshowClientId={setshowClientId}
                    />
                    
                    <SearchClients
                        allClients={allClients}
                        openReportLog={() => setReportLogOpened(true)}
                        setshowClientId={setshowClientId}/>

                    <div className={`dashboardRightContainer ${initialAnimation ? "animatedContainer" : ""}`}>
                        <h5 className="dashboard-date">{formattedDate}</h5>
                        <h1 className="welcome">Good
                            morning, {user && capitalizedName(user.fullName)}!
                        </h1>

                        <NavigationMobile 
                            openDraft={openDraft}
                            initialAnimation={initialAnimation}
                            openReportLog={() => setReportLogOpened(true)}
                            openClients={() => setClientsOpened(true)}
                            setConfigOpened={setConfigOpened}
                        />
                            
                        <h3 className="recent">Recent reports</h3>
                        <RecentReports
                            isReportId={isReportId}
                            allClients={allClients}
                            siteSelectionOpened={siteSelectionOpened}
                            reportLogOpened={reportLogOpened}
                            clientsOpened={clientsOpened}
                            startAnimation={startAnimation}
                            finishAnimation={finishAnimation}
                            showClientId={showClientId} 
                            openDraft={openDraft} 
                            inProgressReports={inProgressReports}
                            getRecentReports={getRecentReports}
                            setGetRecentReports={setGetRecentReports}
                            setInProgressReports={setInProgressReports} />
                    </div>

                    <div className={`pinPrivacySpace ${reportLogOpened || clientsOpened || siteSelectionOpened || configOpened ? "d-none" : ""} 
                    d-lg-none`}></div>

                    <PinButton 
                        className={`pinButton ${reportLogOpened || clientsOpened || siteSelectionOpened || configOpened ? "d-none" : ""}`}
                        inProgressReports={inProgressReports}
                        setInProgressReports={setInProgressReports}
                        openDraft={openDraft} />

                    <span className={`privacy ${reportLogOpened || clientsOpened || siteSelectionOpened || configOpened ? "d-none" : ""}`}>
                        Privacy
                    </span>
                </section>
            </div>

            {/* <Teaser/> */}

            <SiteSelection selectedDraft={selectedDraft}
                isOpened={siteSelectionOpened}
                setSiteSelectionOpened={setSiteSelectionOpened}
                inProgressReports={inProgressReports}
                setInProgressReports={setInProgressReports}
                drafts={drafts}
                wantsMatrixReference={wantsMatrixReference}
                isReportId={isReportId}
                setIsReportId={setIsReportId}
                setDrafts={setDrafts}
                allClients={allClients}
                fetchAllClientsOpen={fetchAllClientsOpen}
                />

            <ReportLog
                isOpened={reportLogOpened}
                setReportLogOpened={setReportLogOpened}
                showClientId={showClientId}
                setshowClientId={setshowClientId}
                allClients={allClients}
                setActivateFetch={setActivateFetch} 
                openDraft={openDraft} 
                inProgressReports={inProgressReports}
                fetchAllClientsOpen={fetchAllClientsOpen}
                />

            <Clients
                isOpened={clientsOpened}
                setClientsOpened={setClientsOpened}
                openReportLog={() => setReportLogOpened(true)}
                setshowClientId={setshowClientId} 
                allClients={allClients}
                setAllClients={setAllClients}
                setActivateFetch={setActivateFetch} />

            <UserConfig
                isOpened={configOpened}
                setUserConfigOpened={setConfigOpened}
            />

            <Streetviewconfig
                isOpened={configOpened}
                setStreetConfOpened={setConfigOpened}
            />

            <BorderConfig
                isOpened={configOpened}
                setBorderConfOpened={setConfigOpened}
            />
        </>
    )
}

export default Dashboard;
