import { html_templates } from "./htmlTemplates.js";
class PdfDynamic {
    addingReport(doc, pdfConfig) {
        doc.querySelector(".clientName").textContent = pdfConfig?.pdfData?.clientName ?? "";
    }

    addingObject(doc, pdfConfig) {
        doc.querySelector(".object-description").textContent = pdfConfig?.pdfData?.objectiveHighlighted ?? "";
        doc.querySelector(".object-long-text").textContent = pdfConfig?.pdfData?.objectivePrimary ?? "";
        doc.querySelector(".object-short-text").textContent = pdfConfig?.pdfData?.objectiveSecondary ?? "";
    }

    addingWhyClient(doc, pdf, pdfConfig) {
        doc.querySelector(".why-client-desc").textContent =  pdfConfig?.pdfData?.whyProdensaHighlighted ?? "";
        doc.querySelector(".why-client-long-text").textContent = pdfConfig?.pdfData?.whyProdensaPrimary ?? "";
        doc.querySelector(".why-client-short-text").textContent = pdfConfig?.pdfData?.whyProdensaSecondary ?? "";

        doc.querySelector(".exp-years").textContent =
            pdf.report.why_client.expyears;
        doc.querySelector(".client-helped").textContent =
            pdf.report.why_client.clients;
    }

    addingAssumptions(doc, pdf, pdfConfig) {
        doc.querySelector(".assumptions-desc").textContent = pdfConfig?.pdfData?.assumptionsHighlighted ?? "";
        doc.querySelector(".assumptions-primary").textContent = pdfConfig?.pdfData?.assumptionsPrimary ?? "";
        doc.querySelector(".assumptions-secondary").textContent = pdfConfig?.pdfData?.assumptionsSecondary ?? "";

        doc.querySelector(".cities-evaluated").textContent = pdf.report.must.cities.map(city => city.municipality_name).join(", ");

        // building area

        Object.keys(pdf.report.assumption.building).forEach((key) => {
            if (key != "requirements") {
                doc
                    .querySelector(".building-assuption-area")
                    .insertAdjacentHTML(
                        "afterbegin",
                        html_templates.buildingTemplate(
                            key,
                            pdf.report.assumption.building[key]
                        )
                    );
            } else {
                pdf.report.assumption.building[key]["options"].forEach((each) => {
                    doc
                        .querySelector(".building-assuption-area")
                        .insertAdjacentHTML(
                            "afterbegin",
                            html_templates.buildingTemplate(each, "Yes")
                        );
                });
            }
        });

        // utilities area

        Object.keys(pdf.report.assumption.utilities).forEach((key) => {
            doc
                .querySelector(".utilities-area")
                .insertAdjacentHTML(
                    "afterbegin",
                    html_templates.buildingTemplate(
                        key,
                        pdf.report.assumption.utilities[key]
                    )
                );
        });

        // utilities area

        const MAX_LINES_FIRST_PAGE = 21
        
        let inboundLines = 0

        const inboundLinesToCut = []

        let outboundLines = 0
        
        const needToCut = {
            where: "",
            index: 0
        }
        
        const getLines = Object.keys(pdf.report.assumption.logistics).reduce((acc, key) => {
            if (key == "logisticsInbound") {
                inboundLines += 1
                
                inboundLines += pdf.report.assumption.logistics[key].inboundGroups.length * 2
                
                inboundLines += pdf.report.assumption.logistics[key].inboundGroups.flatMap((item, index) => {
                    const extraSum = index === 0 ? 3 : 2
                    const nextIterationsSum = index > 0 ? inboundLinesToCut.slice(0, index).reduce((acc, curr) => acc += curr, 0) : 0

                    const numberToSave = item.origins?.length + extraSum + nextIterationsSum
                    inboundLinesToCut.push(numberToSave)
                    return item.origins.map(item2 => item2)
                }).length

                return acc + inboundLines
            }
            
            if (key == "Outbound Logistics") {
                outboundLines += 2

                outboundLines += pdf.report.assumption.logistics[key]["destinations"].length

                return acc + outboundLines
            }

            if (key !== "logisticsInbound" && key !== "Outbound Logistics") {
                if (pdf.report.assumption.logistics[key]) return acc + 1
            }

            return acc
        }, 0)

        if (getLines > MAX_LINES_FIRST_PAGE) {
            if (inboundLines + 2 > MAX_LINES_FIRST_PAGE) {
                let indexSelected = null;
                let accumulator = 10;

                for (let i = 0; i < inboundLinesToCut.length; i++) {
                    accumulator += inboundLinesToCut[i];
                    
                    if (accumulator > 21 && !indexSelected) {
                        indexSelected = i;
                        break;
                    }
                }

                needToCut.where = "inbound"
                needToCut.index = indexSelected
            } else {
                needToCut.where = "outbound"
            }
        }

        Object.keys(pdf.report.assumption.logistics).forEach((key) => {
            if (key == "logisticsInbound") {
                doc
                    .querySelector(".logistics-assumption")
                    .insertAdjacentHTML(
                        "beforeend",
                        `<li class="grid grid-cols-1 py-2 px-2 border-bottom-line text-center">
                            <p class="text-[#191819] font-normal text-[10px] fix-p-2">Inbound Logistics</p>
                        </li>`
                    );
                
                const groupsToMap = needToCut.where === "inbound" ? pdf.report.assumption.logistics[key].inboundGroups.slice(0, needToCut.index) : pdf.report.assumption.logistics[key].inboundGroups
                
                groupsToMap.map(group => {
                    doc
                    .querySelector(".logistics-assumption")
                    .insertAdjacentHTML(
                        "beforeend",
                        `<li class="grid grid-cols-1 py-2 px-2 border-bottom-line text-center">
                            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${group.groupName}</p>
                        </li>`
                    );
                    
                    doc
                    .querySelector(".logistics-assumption")
                    .insertAdjacentHTML(
                        "beforeend",
                        html_templates.logisticsTemplate("Origin", "Qty.", "Unit (per month)", true)
                    );

                    
                    group.origins.map(city => {
                        doc
                        .querySelector(".logistics-assumption")
                        .insertAdjacentHTML(
                            "beforeend",
                            html_templates.logisticsTemplate(city.fullName, city.quantity, city.unit)
                        );
                    })
                })

                if (needToCut.where === "inbound") {
                    doc
                        .querySelector(".extra-logistics-pages")
                        .insertAdjacentHTML(
                            "beforeend",
                            html_templates.logisticsExtraPage()
                        );

                    pdf.report.assumption.logistics[key].inboundGroups.slice(needToCut.index).map(group => {
                        doc
                        .querySelector(".logistics-assumption-extra")
                        .insertAdjacentHTML(
                            "beforeend",
                            `<li class="grid grid-cols-1 py-2 px-2 border-bottom-line text-center">
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${group.groupName}</p>
                            </li>`
                        );
                        
                        doc
                        .querySelector(".logistics-assumption-extra")
                        .insertAdjacentHTML(
                            "beforeend",
                            html_templates.logisticsTemplate("Origin", "Qty.", "Unit (per month)", true)
                        );
    
                        
                        group.origins.map(city => {
                            doc
                            .querySelector(".logistics-assumption-extra")
                            .insertAdjacentHTML(
                                "beforeend",
                                html_templates.logisticsTemplate(city.fullName, city.quantity, city.unit)
                            );
                        })
                    })
                }
            }

            if (key == "Outbound Logistics") {
                const classToFind = needToCut.where === "" ? ".logistics-assumption" : ".logistics-assumption-extra"

                if (needToCut.where !== "" && !doc.querySelector(".logistics-assumption-extra")) {
                    doc
                        .querySelector(".extra-logistics-pages")
                        .insertAdjacentHTML(
                            "beforeend",
                            html_templates.logisticsExtraPage()
                        );
                }
                
                doc
                    .querySelector(classToFind)
                    .insertAdjacentHTML(
                        "beforeend",
                        `<li class="grid grid-cols-1 py-2 px-2 border-bottom-line text-center">
                            <p class="text-[#191819] font-normal text-[10px] fix-p-2">Outbound Logistics</p>
                        </li>`
                    );

                doc
                    .querySelector(classToFind)
                    .insertAdjacentHTML(
                        "beforeend",
                        html_templates.logisticsTemplate("Destination", "Qty.", "Unit (per month)", true)
                    );

                pdf.report.assumption.logistics[key][
                    "destinations"
                ]
                .map((city) => {
                    doc
                        .querySelector(classToFind)
                        .insertAdjacentHTML(
                            "beforeend",
                            html_templates.logisticsTemplate(city.fullName, city.quantity, city.unit)
                        );
                })
            }
            
            if (key !== "logisticsInbound" && key !== "Outbound Logistics") {
                const dataToShow = pdf.report.assumption.logistics[key] ? pdf.report.assumption.logistics[key] : "Not applicable"
                
                doc
                    .querySelector(".logistics-assumption")
                    .insertAdjacentHTML(
                        "beforeend",
                        html_templates.buildingTemplate(
                            key,
                            dataToShow
                        )
                    );
            }
        });

        // utilities area

        pdf.report.assumption.environmentalImpacts.forEach((Each) => {
            doc
                .querySelector(".environmental-impacts")
                .insertAdjacentHTML(
                    "beforeend",
                    html_templates.buildingTemplate(Each, "Yes")
                );
        });

        // exchange-rate
        doc.querySelector(".exchange-rate").textContent =
            pdf.report.assumption.exchangeRate;
    }

    async addingMust(doc, pdf, pdfConfig, borderDistances) {
        doc.querySelector(".must-desc").textContent = pdfConfig?.pdfData?.mustsHighlighted ?? "";
        doc.querySelector(".must-primary").textContent= pdfConfig?.pdfData?.mustsPrimary ?? "";
        // doc.querySelector(".must-secondary").textContent= pdfConfig?.pdfData?.mustsSecondary ?? "";

        // filter area

        Object.keys(pdf.report.must.filters).forEach((key) => {
            doc.querySelectorAll(".must-filter").forEach((Each) => {
                Each.insertAdjacentHTML(
                    "beforeend",
                    html_templates.buildingTemplate(
                        key,
                        pdf.report.must.filters[key]
                    )
                );
            });
        });

        const selectedIndustries = pdf.assumptions.selectedIndustries
        
        for (const city of pdf.report.must.cities) {
            const cityPageHTML = await html_templates.cityPage(city, pdf.report.must.filters, pdfConfig, borderDistances, selectedIndustries);
            doc.querySelector(".cities-pages").insertAdjacentHTML("beforeend", cityPageHTML);
        }

        pdf.report.must.cities.forEach((Each) => {
            doc
                .querySelector(".city-area-must")
                .insertAdjacentHTML("beforeend", html_templates.htmlCities(Each, borderDistances));
        });
    }

    addingWant(doc, pdf, pdfConfig) {
        doc.querySelector(".want-desc").textContent = pdfConfig?.pdfData?.wantsHighlighted ?? "";
        doc.querySelector(".want-primary").textContent = pdfConfig?.pdfData?.wantsPrimary ?? "";
        doc.querySelector(".want-secondary").textContent = pdfConfig?.pdfData?.wantsSecondary ?? "";
        doc.querySelector(".want-tertiary").textContent = pdfConfig?.pdfData?.wantsTertiary ?? "";

        doc.querySelector(".total-wants-points").textContent = pdf.report.want.wantMatrix.reduce((acc, curr) => acc + curr.weight, 0);

        pdf.report.want.wantMatrix.sort((a, b) => a.position - b.position).forEach((Eachmatrix, key) => {
            const wantDescription = pdfConfig?.wantsData?.find(wantPdf => wantPdf.id === Eachmatrix.catIdRef)?.description
            
            doc
                .querySelector(".want-matrix-container")
                .insertAdjacentHTML(
                    "beforeend",
                    html_templates.htmlWantMatrix(Eachmatrix, key + 1, wantDescription)
                );
        });

        pdf.report.want.wantMatrix.sort((a, b) => a.position - b.position).forEach((Eachmatrix, index) => {
            let element;
            if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c8") {
                element = "operating-system-main"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c9") {
                element = "competition-for-labor"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ca") {
                element = "labor-environment"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cb") {
                element = "logistics-table"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cc") {
                element = "infrastructure"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cd") {
                element = "government-incentives"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ce") {
                element = "security"
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cf") {
                element = "quality-of-life"
            }

            doc
                .querySelector(".wants-table-pages")
                .insertAdjacentHTML(
                    "beforeend",
                    html_templates.htmlWantMatrixCat(Eachmatrix, element, index)
                );
        });
    }

    addingWantTable(doc, pdf) {
        pdf.report.want.wantMatrix.sort((a, b) => a.position - b.position).forEach((Eachmatrix, catIndex) => {
            let element;
            if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c8") {
                element = doc.querySelector(".operating-system-main");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c9") {
                element = doc.querySelector(".competition-for-labor");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ca") {
                element = doc.querySelector(".labor-environment");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cb") {
                element = doc.querySelector(".logistics-table");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cc") {
                element = doc.querySelector(".infrastructure");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cd") {
                element = doc.querySelector(".government-incentives");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ce") {
                element = doc.querySelector(".security");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cf") {
                element = doc.querySelector(".quality-of-life");
            }

            Eachmatrix["variables"].sort((a, b) => b.position - a.position).forEach((Eachvar, varIndex) => {
                element
                    .querySelector("div ul li:first-child")
                    .insertAdjacentHTML(
                        "afterend",
                        html_templates.htmlWantMatrixVar(Eachvar, catIndex, Eachmatrix["variables"]?.length - varIndex)
                    );
            });
        })
    }

    addingWantCitiesTable(doc, pdf) {
        pdf.report.want.wantMatrix.forEach(Eachmatrix => {
            let element;

            if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c8") {
                element = doc.querySelector(".cities-table-operating-system-main");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2c9") {
                element = doc.querySelector(".cities-table-competition-for-labor");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ca") {
                element = doc.querySelector(".cities-table-labor-environment");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cb") {
                element = doc.querySelector(".cities-table-logistics-table");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cc") {
                element = doc.querySelector(".cities-table-infrastructure");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cd") {
                element = doc.querySelector(".cities-table-government-incentives");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2ce") {
                element = doc.querySelector(".cities-table-security");
            } else if (Eachmatrix.catIdRef.toString() === "65cc0a7810d19bdcf598a2cf") {
                element = doc.querySelector(".cities-table-quality-of-life");
            }

            const number_of_variables = Eachmatrix.variables.length

            element
                .querySelector("div:first-child")
                .insertAdjacentHTML(
                    "beforeend",
                    "<span class='text-[10px] text-[#239DFF] font-thing fix-p-2'>Selected City</span>"
                )

            Eachmatrix.variables.sort((a,b) => a.position - b.position).forEach(variable => {
                element
                    .querySelector("div:first-child")
                    .insertAdjacentHTML(
                        "beforeend",
                        `<span class='text-[10px] text-[#239DFF] font-thing text-center fix-p-2'>${Eachmatrix.position}.${variable.position} ${variable.title}</span>`
                    )
            })
    
            element
                .querySelector("div:first-child")
                .insertAdjacentHTML(
                    "beforeend",
                    "<span class='text-[10px] text-[#239DFF] font-thing text-center fix-p-2'>Points</span>"
                )

            pdf.report.must.cities.slice().reverse().forEach((city, index) => {
                const city_cat_points = Eachmatrix.totals.find(cat => cat.cityId === city.datamexico_municipality_id)?.points
                
                const points = Eachmatrix.variables.sort((a,b) => a.position - b.position).map(variable => {
                    const city_var_points = variable.results.find(res => res.cityId === city.datamexico_municipality_id)?.points
                    
                    return `<span class='text-[10px] text-center fix-p-2'>${city_var_points}</span>`
                }).toString().replaceAll(",", "")

                element
                    .querySelector("div:first-child")
                    .insertAdjacentHTML(
                        "afterend",
                        `
                            <div class="want-cities-cols want-cities-cols-${number_of_variables} py-2 px-2 border-bottom-line">
                                <span class='flex gap-1 text-[10px] fix-p-2'> <span class="text-[10px] font-thin">${pdf.report.must.cities.length - index}</span> ${city.municipality_name}</span>

                                ${points}

                                <span class='text-[10px] text-[#239DFF] text-center fix-p-2'>${city_cat_points?.toFixed(2)}</span>
                            </div>
                        `
                    )
            })
        })
    }

    addingSummary(doc, pdf, pdfConfig) {
        // doc.querySelector(".summary-title").textContent = pdf.report.summary.title;
        doc.querySelector(".summary-desc").textContent = pdfConfig?.pdfData?.summaryHighlighted ?? "";
        doc.querySelector(".summary-long").textContent = pdfConfig?.pdfData?.summaryRegular ?? "";

        doc.querySelector(".summary-cities-main").textContent = pdfConfig?.pdfData?.summaryCitiesHighlighted ?? "";
        doc.querySelector(".summary-cities-primary").textContent = pdfConfig?.pdfData?.summaryCitiesPrimary ?? "";
        doc.querySelector(".summary-cities-secondary").textContent = pdfConfig?.pdfData?.summaryCitiesSecondary ?? "";

        pdf.report.total.forEach(city => {
            doc
                .querySelector(".cities-cards")
                .insertAdjacentHTML(
                    "beforeend",
                    html_templates.htmlCitiesCards(city)
                )
        })

        doc
            .querySelector(".total-categories-table")
            .insertAdjacentHTML(
                "beforeend",
                html_templates.htmlTotalCategoriesTable(pdf.report)
            )
    }

    addingSources(doc, pdf) {
        pdf.report.sources.slice(0, 24).forEach(Eachsource => {
        doc
            .querySelector(".sources-area")
            .insertAdjacentHTML(
                "beforeend",
                html_templates.sourcesTemplate(Eachsource.variable, Eachsource.source)
            );
        });
            
        if (pdf.report.sources.length > 23) {
            doc
                .querySelector(".sources-extra-page")
                .insertAdjacentHTML(
                    "beforeend",
                    html_templates.sourceExtraPage()
                );

            pdf.report.sources.slice(24).forEach(Eachsource => {
                doc
                    .querySelector(".sources-extra-area")
                    ?.insertAdjacentHTML(
                        "beforeend",
                        html_templates.sourcesTemplate(Eachsource.variable, Eachsource.source)
                    );
                });
        }
    }

    updateDate(doc, date) {
        const elements = doc.querySelectorAll(".date-now")

        elements.forEach(ele => {
            ele.textContent = date
        })
    }

    updatePagination(doc) {
        const elements = doc.querySelectorAll(".pagination")

        elements.forEach((ele, index) => {
            const page = index + 2 < 10 ? `0${index + 2}` : `${index + 2}`
            ele.textContent = `Page ${page}`

            // Index pagination

            // Must index pagination
            if (ele.classList.contains("must-front-page-link")) {
                const mustFrontPage = doc.querySelector(".must-front-page")
                const mustFirstPage = doc.querySelector(".must-first-page")
                const mustCitiesStart = doc.querySelector(".must-cities-start")
                
                if (mustFrontPage) {
                    mustFrontPage.textContent = page
                }
                if (mustFirstPage) {
                    mustFirstPage.textContent = index + 3 < 10 ? `0${index + 3}` : `${index + 3}`
                }
                if (mustCitiesStart) {
                    mustCitiesStart.textContent = index + 4 < 10 ? `0${index + 4}` : `${index + 4}`
                }
            }

            if (ele.classList.contains("must-cities-summary-link")) {
                const summaryPage = doc.querySelector(".must-cities-summary")

                if (summaryPage) {
                    summaryPage.textContent = page
                }
            }

            if (ele.classList.contains("wants-front-page-link")) {
                const wantsFrontPage = doc.querySelector(".wants-front-page")
                const wantsFirstPage = doc.querySelector(".wants-first-page")

                if (wantsFrontPage) {
                    wantsFrontPage.textContent = page
                }
                if (wantsFirstPage) {
                    wantsFirstPage.textContent = index + 3 < 10 ? `0${index + 3}` : `${index + 3}`
                }
            }

            const classToPageMap = {
                "operating-system-main-link": ".operating-system-main-page",
                "competition-for-labor-link": ".competition-for-labor-page",
                "labor-environment-link": ".labor-environment-page",
                "logistics-table-link": ".logistics-table-page",
                "infrastructure-link": ".infrastructure-page",
                "government-incentives-link": ".government-incentives-page",
                "security-link": ".security-page",
                "quality-of-life-link": ".quality-of-life-page"
            };

            for (const [linkClass, pageSelector] of Object.entries(classToPageMap)) {
                if (ele.classList.contains(linkClass)) {
                    const pageElement = doc.querySelector(pageSelector);
                    if (pageElement) {
                        pageElement.classList.remove("hidden");
                    }
                    // Exit loop once a match is found and processed
                    break;
                }
            }
            
            if (ele.classList.contains("summary-front-page-link")) {
                const allWantsPages = doc.querySelectorAll(".wantIndexPage")
                const summaryFrontPage = doc.querySelector(".summary-front-page")
                const citiesResultsPage = doc.querySelector(".cities-results-page")

                if (summaryFrontPage) {
                    summaryFrontPage.textContent = page
                }

                if (citiesResultsPage) {
                    citiesResultsPage.textContent = `${index + 3}`
                }

                let numeration = 2
                let paginationNumeration = 8
                
                allWantsPages.forEach(ele => {
                    if (!ele.classList.contains("hidden")) {
                        const wantIteration = ele.querySelector("p:first-child")
                        const wantPage = ele.querySelector("p:last-child")

                        wantIteration.textContent = `0${numeration}`

                        wantPage.textContent = `${index + 2 - paginationNumeration}`
                        
                        numeration++
                        paginationNumeration--
                    }
                })
            }

            if (ele.classList.contains("sources-front-page-link")) {
                const sourcesFrontPage = doc.querySelector(".sources-front-page")
                const sourcesFirstPage = doc.querySelector(".sources-first-page")

                if (sourcesFrontPage) {
                    sourcesFrontPage.textContent = page
                }
                if (sourcesFirstPage) {
                    sourcesFirstPage.textContent = `${index + 3}`
                }
            }
        })
    }
}

export const pdfdynamic = new PdfDynamic();
