import Axios from "axios"
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap"
import { useEffect, useRef, useState } from "react"
import { useAuth } from "../../context/auth-context"
import Swal from "sweetalert2"
import { v4 as uuidv4 } from "uuid";

import { customAlert, loadingAlert } from "../../utils/customAlert.js";
import profile from "../../assets/images/profileExample.jpg"
import MenuOptions from "./menuOptions.jsx"
import logo from "../../assets/images/logo.svg"
import folder from "../../assets/images/folder.svg"
import person from "../../assets/images/person.svg"
import contract from "../../assets/images/contract.svg"
import manufacturing from "../../assets/images/manufacturing.svg"
import "../../assets/css/navigationMenu/navigationMenu.css"

const menuOptions = [
	{
		_id: 0,
		image: contract,
		title: "New report",
		description: "Create a new report for an existing or new client.",
		options: ["Teaser", "Site selection", "Feasibility"]
	},
	{
		_id: 1,
		image: folder,
		title: "Report log",
		description: "View, edit, and filter existing reports.",
	},
	{
		_id: 2,
		image: person,
		title: "Clients",
		description: "View, edit, and filter existing clients.",
	},
]

const TABLE_SETTING_OPTIONS = [
	{
		id: uuidv4(),
		title: "update Electricity Costs",
		settingOption: 10,
		optionKey: "electricity"
	},
	{
		id: uuidv4(),
		title: "update Lawsuits",
		settingOption: 11,
		optionKey: "lawsuits"
	},
	{
		id: uuidv4(),
		title: "update Strikes History",
		settingOption: 12,
		optionKey: "strikes"
	},
	{
		id: uuidv4(),
		title: "update Priv. Schools",
		settingOption: 13,
		optionKey: "schools"
	},
	{
		id: uuidv4(),
		title: "update Priv. Hospitals",
		settingOption: 14,
		optionKey: "hospitals"
	},
	{
		id: uuidv4(),
		title: "update Priv. Retail Chains",
		settingOption: 15,
		optionKey: "retailChains"
	},
	{
		id: uuidv4(),
		title: "update Priv. Social Progress Index",
		settingOption: 16,
		optionKey: "socialProgress"
	},
]

export function PasswordInput({ id, value, onChange, showPassword, handleShowPassword }) {
	return (
		<div className="password-input-container">
			<input 
				id={id} 
				type={`${showPassword ? "text" : "password"}`} 
				placeholder={`Enter ${id} password`} 
				value={value} 
				onChange={onChange} 
			/>
			<div 
				className={`passwordIcon ${showPassword ? "show" : ""}`} 
				onClick={() => handleShowPassword(id)}
			/>
		</div>
	)
}

function NavigationMenu(props) {
	const fileInputRef = useRef(null);
	const moreOptionsRef = useRef(null);
	const updateSettingsRef = useRef(null)
	const [_cookies, _setCookie, removeCookie] = useCookies(['token'])
	const [showUserOption, setShowUserOptions] = useState(false);
	const [settingsShow, setSettingsShow] = useState(false);
	const [updateSettingsShow, setUpdateSettingsShow] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [passwords, setPasswords] = useState({ 
		current: "", 
		new: "", 
		confirm: "" 
	});
	const [showPasswords, setShowPasswords] = useState([]);
	const [tableOptionToUpdate, setTableOptionToUpdate] = useState("")

	const passwordInputs = {
		current: "current",
		new: "new",
		confirm: "confirm"
	}

	const { user, updateUser } = useAuth()

	useEffect(() => {
		document.body.addEventListener('click', closeUserOptions);

		return function cleanup() {
			document.body.removeEventListener('click', closeUserOptions);
		}
	}, []);

	const userTitleClick = useRef(null);
	const updateTableRef = useRef(null)

	const closeUserOptions = (e) => {
		if (userTitleClick.current && !userTitleClick.current.contains(e.target)) {
			setShowUserOptions(false)
		}

		if (moreOptionsRef.current && !moreOptionsRef.current.contains(e.target) && updateSettingsRef.current && !updateSettingsRef.current.contains(e.target)) {
			setSettingsShow(false)
		}
	}

	const handleChangePassword = () => {
		setChangePasswordModal(true)
	}

	const handleOnHideModal = () => {
		setChangePasswordModal(false)
		setPasswords({
			current: "",
			new: "",
			confirm: ""
		})
		setShowPasswords([])
	}

	const handleInputPassword = (e) => {
		const { id, value } = e.target
		setPasswords(prevState => {
			return {
				...prevState,
				[id]: value
			}
		})
	
	}

	const handleShowPassword = (input) => {
		setShowPasswords(prevState => {
			if (prevState.includes(input)) {
				return prevState.filter(prevInput => prevInput !== input)
			} else {
				return [...prevState, input]
			}
		})
	}

	const handleConfirmPassword = () => {
		loadingAlert()

		Axios.patch(process.env.REACT_APP_UPDATE_LOGIN_USER + user._id, passwords).then(res => {
			if (res.status >= 200 && res.status < 300) {
				customAlert(res.data.message, "success")
				setChangePasswordModal(false)
				setPasswords({
					current: "",
					new: "",
					confirm: ""
				})
				setShowPasswords([])
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

	const handleLogout = () => {
		// add endpoint to delete token from database
		removeCookie("token")
		// window.location.reload()
	}

	const capitalizedName = (name) => {
		return name.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
	}

	const handleProfilePicture = async(e) => {
		const allowedTypes = ["image/jpeg", "image/png"];
		const selectedFile = e.target.files[0];

		if (selectedFile && !allowedTypes.includes(selectedFile?.type)) {
			await Swal.fire({
					icon: "error",
					title: "File not supported",
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
			});
			return;
		}

		const data = new FormData();
		data.append("photo", selectedFile);
		data.append("profileUser", user)

		Axios.patch(process.env.REACT_APP_UPDATE_PROFILE_USER + user._id, data).then(res => {
			if (!res.data.data) {
				customAlert(res.data.message)
				return;
			}
			updateUser(res.data.data)
			
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

	const openConfig = (option) => {
		props.setOpenConfig(option)

		window.scrollTo(0, 0);
	}

	const updateTableForCities = (option) => {
		setTableOptionToUpdate(option)
		setUpdateSettingsShow(false)

		updateTableRef?.current.click()
	}

	const handleTableFile = async (e) => {
		const selectedFile = e.target.files[0];
		const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		if (!selectedFile) return

		if (selectedFile && allowTypes !== selectedFile.type) {
			await Swal.fire({
					icon: "error",
					title: "File not supported",
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
			});
			return;
		}

		loadingAlert()
		
		const data = new FormData()
		data.append("fileType", tableOptionToUpdate)
		data.append("file", selectedFile)

		Axios.post(process.env.REACT_APP_UPDATE_CITIES_INFORMATION, data).then(res => {
			customAlert(res.data.message, "success")

			setTableOptionToUpdate("")
			
			updateTableRef.current.value = ""
		}).catch(err => {
			setTableOptionToUpdate("")
			
			updateTableRef.current.value = ""

			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

	const changePasswordConfirmButton = passwords.current !== "" && passwords.new !== "" && passwords.confirm !== "" && passwords.new === passwords.confirm;
	const samePassword = passwords.new !== passwords.confirm && passwords.confirm !== ""
	
	return (
		<nav className={`navigation ${props.initialAnimation ? "animated-navigation" : ""} d-none d-lg-block`}>
			<div className="logo-settings-wrapper">
				<img className="logo" src={logo} alt="ProdensaAutomation" />
			</div>
			<img className="profile" src={user && user.hasOwnProperty("photo") ? process.env.REACT_APP_BACKEND_URL + user.photo.path : profile} alt="ProdensaAutomation" />
			<div className="userContainer">
				<span ref={userTitleClick} className={`userTitle ${showUserOption ? "active" : ""}`} onClick={() => setShowUserOptions(!showUserOption)}>{user && capitalizedName(user.fullName)}</span>
				<div className={`userOptions ${showUserOption ? "active" : ""}`}>
					<input type="file" ref={fileInputRef} style={{display: "none"}} onChange={handleProfilePicture}/>
					<span className="userChoice" onClick={() => fileInputRef.current && fileInputRef.current.click()}>Change profile picture</span>
					<span className="userChoice" onClick={handleChangePassword}>Change Password</span>
					<span className="userChoice" onClick={handleLogout}>Logout</span>
				</div>
			</div>
			{menuOptions.map(menuOption => {
				if (user?.role === "BD" && menuOption._id === 2) return null

				return <MenuOptions
					key={menuOption._id}
					id={menuOption._id}
					image={menuOption.image}
					title={menuOption.title}
					description={menuOption.description}
					options={menuOption.options ?? ""}
					openDraft={props.openDraft}
					openReportLog={props.openReportLog}
					openClients={props.openClients}
				/>
			}
			)}

			{user?.role !== "BD" && <div className="menuOption settings">
				<input type="file" accept=".xlsx" ref={updateTableRef} style={{display: "none"}} onChange={handleTableFile}/>
				
				<img src={manufacturing} ref={moreOptionsRef} alt="ProdensaAutomation" className="settings-logo" onClick={() => { setSettingsShow(!settingsShow); setUpdateSettingsShow(false) }} />

				<div className={`settings-menu ${settingsShow ? "show" : ""}`}>
					{user?.role === "admin" && 
						<span className="setting-option" onClick={() => openConfig(1)}>
							Users
						</span>
					}
					<span className="setting-option" onClick={() => openConfig(2)}>
						Streetview
					</span>
					<span className="setting-option" onClick={() => openConfig(3)}>
						Border
					</span>

					<div>
						<span className="setting-option" ref={updateSettingsRef} onClick={() => setUpdateSettingsShow(!updateSettingsShow)}>Update database</span>

						<div className={`update-menu ${updateSettingsShow ? "show" : ""}`}>
							{TABLE_SETTING_OPTIONS?.map(opt => {
								return (
									<span key={opt.id} className="setting-option" onClick={() => updateTableForCities(opt.optionKey)}>{opt.title}</span>
								)
							})}
						</div>
					</div>

				</div>
			</div>}

			<Modal className="mail-form-modal change-password" centered show={changePasswordModal} onHide={handleOnHideModal}>  {/*Modal for adding custom mail */}
				<Modal.Header closeButton>
					<Modal.Title>Change password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PasswordInput 
						id={passwordInputs.current}
						value={passwords.current}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.current)}
						handleShowPassword={handleShowPassword}
					/>

					<PasswordInput
						id={passwordInputs.new}
						value={passwords.new}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.new)}
						handleShowPassword={handleShowPassword}
					/>

					<PasswordInput
						id={passwordInputs.confirm}
						value={passwords.confirm}
						onChange={handleInputPassword}
						showPassword={showPasswords.includes(passwordInputs.confirm)}
						handleShowPassword={handleShowPassword}
					/>

					<span className={`password-error ${samePassword ? "show" : ""}`}>Password do not match</span>
				</Modal.Body>
				<Modal.Footer>
					<button className="send-button password-button" onClick={handleConfirmPassword} disabled={!changePasswordConfirmButton}>
						Change password
					</button>
				</Modal.Footer>
			</Modal>
		</nav>
	)
}

export default NavigationMenu
