import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";


const TurnoverRate = ({ details, city_names, filtered, reportID }) => {

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {details ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Indicator</h5>
                            <h5 className="side-text" style={{ height: "90px", paddingTop: "35px" }}>Avg Monthly Turnover Rate</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={index}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cellClass = ""
                                    let cityFind = details.results?.find(cityFind => city === cityFind.city);
                                    if (cityFind) {
                                        if (cityFind.valueResult?.bestOption) {
                                            cellClass = "lowest"
                                        }
                                        if (cityFind.valueResult?.totalCost !== null) {
                                            textToShow = cityFind.valueResult.totalCost;
                                        }
                                    }
                                    return filtered.includes(index) &&
                                        <h5 className="number"><span className={cellClass}>{textToShow}{textToShow === "N/A" ? "" : "%"}</span></h5>
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Indicator</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={index}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Avg Monthly Turnover Rate</td>
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cellClass = ""
                                        let cityFind = details.results.find(cityFind => city === cityFind.city);
                                        if (cityFind) {
                                            if (cityFind.valueResult?.bestOption) {
                                                cellClass = "lowest"
                                            }
                                            if (cityFind.valueResult?.totalCost !== null) {
                                                textToShow = cityFind.valueResult.totalCost;
                                            }
                                        }
                                        return filtered.includes(index) &&
                                            <td><span className={cellClass}>{textToShow}{textToShow === "N/A" ? "" : "%"}</span></td>
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            )}

        </Container>
    )
}

export default TurnoverRate;